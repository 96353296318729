.m-footer {
  .c-section__inner {
    width: 100%;
    position: relative;
    padding: 160px 24px;
    padding-top: 120px;
    max-width: 1600px;
    margin: 0 auto;
    border-top: $border-dark;

    @include media("<=tablet") {
      padding: 200px 24px;
      padding-top: 96px;
    }
  }

  &__socials {
    display: flex;
    @include flex-gap(16px);

    a {
      width: 20px;
      height: 20px;
      @extend %svg-full;
      color: $dark-grey;
      transition: color 0.2s ease;

      &:hover {
        color: $black;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__label {
    @extend %body-14-bold;
    margin-bottom: 16px;
    color: $dark-grey;
  }

  &__emoji {
    @extend %emoji-48;
    margin-bottom: 16px;
  }

  &__month {
    @extend %title-160-serif;
    margin-bottom: 48px;
    filter: blur(2px);

    @include media("<=tablet") {
      filter: blur(1px);
      margin-bottom: 32px;
    }
  }

  &__butterfly {
    position: absolute;

    &--left {
      transform: rotate(9.68deg);
      left: toRem(149);
      bottom: 135px;

      @include media("<=tablet") {
        left: 40px;
        bottom: 39px;
        transform: rotate(-8.05deg);
      }
    }

    &--right {
      transform: rotate(-39.03deg);
      right: toRem(183);
      bottom: 105px;

      @include media("<=tablet") {
        display: none;
      }

      .wing__inner {
        animation-delay: 1s !important;
      }
    }

    &--top {
      transform: rotate(-22.3deg);
      right: toRem(381);
      top: -91px;

      @include media("<=tablet") {
        top: -41px;
        right: 48px;
        transform: rotate(14.85deg);
      }

      .wing__inner {
        animation-delay: 0.5s !important;
      }
    }
  }
}

.c-footer {
  margin-bottom: 24px;
  .c-section__inner {
    padding-top: 32px;
    border-top: $border-dark;
  }

  &__content {
    display: flex;
    // justify-content: center;
    @include flex-gap(16px);
    flex-wrap: wrap;
    width: 100%;
  }

  &__card {
    width: 100%;
    height: 128px;
    border: $border-dark;
    border-radius: 12px;
    padding: 24px;
    display: flex;

    @include media(">tablet") {
      max-width: 315px;
    }

    &__left {
      width: 100%;
    }

    &__right {
      flex-shrink: 0;
      align-self: flex-end;
    }

    &__title {
      @extend %body-14-bold;
      margin-bottom: 8px;
    }

    &__desc {
      color: $dark-grey;
      @extend %body-14;
      max-width: 240px;
    }

    &--link {
      padding-right: 16px;
      padding-bottom: 16px;
    }

    &--copy {
      justify-content: flex-end;
      text-align: right;

      .c-footer__card__desc {
        margin-bottom: 22px;
      }
    }
  }
}
