.c-button {
  display: inline-flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background: $grey;
  color: $black;
  @extend %body-14-bold;

  svg {
    width: 100%;
    height: 100%;
  }

  &--white {
    background: $white;
    color: $black;
    box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625),
      2px 16px 24px rgba(0, 0, 16, 0.0625);
  }

  &--black {
    background: $black;
    color: $white;
  }

  &--transparent {
    background: transparent;
    color: $dark-grey;
    border: $border-dark;
  }

  &--icon-only {
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &.c-button--large {
      width: 48px;
      height: 48px;
    }

    .c-button__icon {
      width: 20px;
      height: 20px;
    }

    @include cursor {
      &:hover {
        .c-button__icon {
          transform: scale(1.05);
        }
      }
    }
  }

  &--icon-text,
  &--text-only {
    padding: 9px 12px;

    &.c-button--large {
      padding: 16px;
    }

    @include cursor {
      &:hover {
        .c-button__text {
          animation: blinkerHover 1s step-start;
        }
      }
    }
  }

  &--icon-text {
    @include flex-gap(8px);
    .c-button__icon {
      width: 16px;
      height: 16px;
    }
  }

  &:disabled {
    pointer-events: none;
  }

  &:not(.custom-disabled) {
    &:disabled {
      background: transparent;
      color: $low-black;
      border: $border-dark;
      pointer-events: none;
    }
  }
}
