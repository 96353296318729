.outro {
  @extend %page;

  &--active {
    @extend %page--active;
  }

  @include pagePadding;

  &__inner {
    position: relative;
    margin-top: 82px;

    @include media(">tablet") {
      display: flex;
      margin-top: 0;
    }
  }

  &__content {
    width: 100vw;
    footer {
      margin-top: auto;
    }
  }

  &__header {
    @include media(">tablet") {
      position: absolute;
      width: 100%;
      max-width: calc(100% - 88px - 24px);
      right: 24;
      z-index: z("1");

      .c-header {
        &__title {
          border-color: $low-white;
        }

        &__title,
        &__scroll {
          color: $medium-white;
        }

        path {
          &:first-of-type {
            fill: $medium-white;
          }

          &:last-of-type {
            fill: $white;
          }
        }
      }
    }
  }

  &__hero {
    width: 100%;
    position: relative;
    margin-bottom: 32px;

    @include media("<=tablet") {
      margin-top: 48px;
      padding: 0 12px;
      margin-bottom: 48px;
    }

    &__video {
      @include ratio(1600, 1440);

      @include media("<=tablet") {
        border-radius: 12px;
        overflow: hidden;
      }

      video {
        z-index: -1;
      }
    }

    &__content {
      @include media(">tablet") {
        position: absolute;
        top: 240px;
        max-width: calc(100% - 88px - 24px);
        right: 24;
        width: 100%;
      }
    }

    &__title {
      max-width: 535px;
      @extend %title-172-pixel;
      color: $white;
      margin: 0 auto;
      text-align: center;

      @include media("<=tablet") {
        max-width: 204px;
        position: absolute;
        top: 48px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__description {
      padding: 0 12px;
      margin-top: 24px;
      @include media(">tablet") {
        max-width: 560px;
        text-align: center;
        color: $white;
        margin: 0 auto;
        padding: 0;
      }
    }
  }

  &__faqs {
    .c-section__inner {
      max-width: 768px;
      margin: 120px auto;

      @include media("<=tablet") {
        margin: 48px auto;
        padding: 0 4px;
      }
    }

    &__title {
      @extend %title-172-pixel;
      margin-bottom: 48px;
      text-align: center;

      @include media("<=tablet") {
        padding: 0 16px;
        margin-bottom: 32px;
        text-align: left;
      }
    }
  }

  &__contact {
    .c-section__inner {
      max-width: 1328px;
      margin: 0 auto;
      position: relative;
      margin-top: 244px;
      margin-bottom: 80px;

      @include media("<=tablet") {
        margin-top: 160px;
        margin-bottom: 64px;
      }
    }

    &__link {
      overflow: revert;
    }

    &__title {
      @extend %title-172-pixel;
      text-align: center;

      span {
        animation: colorChange 1.5s step-start infinite;
      }

      @keyframes colorChange {
        0% {
          color: #ff0000;
        }

        20% {
          color: #ffff00;
        }

        40% {
          color: #00ff00;
        }

        60% {
          color: #00ffff;
        }

        80% {
          color: #0000ff;
        }

        100% {
          color: #ff00ff;
        }
      }
    }

    &__butterfly {
      position: absolute;
      pointer-events: none;
      z-index: z("1");

      &--left {
        transform: rotate(18.6deg);
        left: 63px;
        top: 126px;

        @include media("<=1200px") {
          transform: rotate(19.61deg);
          top: -120px;
          left: 4px;
        }
      }

      &--right {
        transform: rotate(-28.75deg);
        right: 50px;
        top: 190px;

        @include media("<=1200px") {
          display: none;
        }

        .wing__inner {
          animation-delay: 1s !important;
        }
      }
    }

    &__phone {
      position: absolute;
      left: -5px;
      bottom: -226px;
      max-width: 652px;
      transform: rotate(-5deg);
      width: 100%;
      pointer-events: none;
      z-index: z("1");

      &__inner {
        @include ratio(1, 1);
      }

      @include media("<=1200px") {
        display: none;
      }
    }

    &__link {
      @extend %title-64-serif;
      @extend %link;

      @include media("<=tablet") {
        font-size: 32px;
        letter-spacing: calc(-0.025 * 32px);
      }

      &:hover + .outro__contact__phone {
        .outro__contact__phone__inner {
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          transform: translate3d(0, 0, 0);
          backface-visibility: hidden;
          perspective: 1000px;
        }
      }

      &__con {
        padding: 32px;
        padding-top: 24px;
        border-radius: 8px;
        background: $grey;
        margin-right: toRem(216);
        margin-left: auto;
        margin-top: 20px;
        max-width: 582px;
        text-align: center;

        @include media("<=1200px") {
          margin-right: 0;
          margin-top: 32px;
          max-width: unset;
        }

        @include media("<=tablet") {
          padding: 16px;
          padding-bottom: 24px;
        }
      }

      &__top {
        @extend %body-14-bold;
        display: flex;
        justify-content: space-between;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: $border-dark;

        @include media("<=tablet") {
          display: block;
          padding-bottom: 12px;
          margin-bottom: 12px;
        }
      }

      &__label {
        @include media("<=tablet") {
          margin-bottom: 4px;
        }
      }

      &__desc {
        @include media("<=tablet") {
          font-weight: 500;
          line-height: 1.35;
          color: $dark-grey;
        }
      }
    }
  }

  .m-playlist {
    position: relative;
    margin-bottom: 64px;

    @include media("<=tablet") {
      margin-bottom: 96px;
    }

    &__butterfly {
      position: absolute;
      pointer-events: none;
      z-index: z("1");

      &--top {
        transform: rotate(-21.39deg);
        top: -115px;
        right: 130px;

        @include media("<=tablet") {
          transform: rotate(-5.56deg);
          top: -44px;
          left: 84px;
          right: unset;
        }
      }

      &--bottom {
        transform: rotate(19.5deg);
        left: 167px;
        bottom: -150px;

        @include media("<=tablet") {
          transform: rotate(-5.56deg);
          right: 14px;
          bottom: -60px;
          left: unset;
        }

        .wing__inner {
          animation-delay: 1s !important;
        }
      }
    }
  }

  &__dump {
    margin-bottom: 32px;
    position: relative;
    &__label {
      text-align: center;
      @extend %body-14-bold;
      color: $dark-grey;
      margin-bottom: 16px;
    }

    &__butterfly {
      transform: rotate(-35.01deg);
      position: absolute;
      right: 24px;
      top: 0;
      z-index: z("1");
      pointer-events: none;

      @include media("<=tablet") {
        display: none;
      }
    }

    &__emojis {
      @extend %emoji-48;
      text-align: center;
      margin-bottom: 64px;
    }

    &__players {
      @include flex-gap(16px);
      display: flex;
      margin-bottom: 16px;

      @include media("<=1200px") {
        flex-direction: column;
      }
    }

    &__embeds {
      @include flex-gap(16px);
      display: flex;

      @include media("<=1200px") {
        flex-direction: column;
      }
    }

    &__embed {
      display: flex;
      width: 100%;
    }
  }
}
