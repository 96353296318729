.c-gradient {
  &-box {
    width: 40px;
    position: fixed;
    left: 24px;
    bottom: 24px;
    height: 80vh;
    border: 2px solid rgba(255, 255, 255, 0.35);
    border-radius: 40px;
    @extend %ovh;

    &__inner {
      background: linear-gradient(180deg, #00ffff 0%, #ff00ff 100%);
      height: 100%;
      width: 100%;
      background-size: 100% 130%;
      // background-position: 100% 100%;
      // transition: background-position 0.5s ease;
    }
  }
}
