.c-menu {
  z-index: z("menu");
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 240px);
  pointer-events: none;
  overflow: hidden;
  position: fixed;

  @include media("<=tablet") {
    display: none;
  }

  @include media(">tablet") {
    transform: translateY(-100%);
  }

  &__transition {
    position: absolute;
    bottom: 0;
    min-width: 1440px;
    width: 100%;
    overflow: hidden;
    height: 240px;
    left: 0;
    z-index: z("1");
    @include media("<=tablet") {
      display: none;
    }

    img {
      width: 100%;
      height: 240px;
      object-fit: cover;
    }
  }

  &__inner {
    width: 100%;
    max-width: calc(100% - 345px);
    padding-left: 88px;
    padding-top: 48px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    // overflow: hidden;
    transition: opacity 0.8s 0.2s $ease-hero-marquee, transform 0.8s $ease-menu;
    opacity: 0;
    transform: translate3d(0, 75vh, 0);
  }

  &__butterflies {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    max-width: 1440px;
    left: 50%;
    transition: transform 0.8s $ease-hero-marquee;
    transition-delay: 0.2s;
    transform: translateX(-50%) scale(1.2);
  }

  &--active {
    @include media(">tablet") {
      transform: translateY(0);
    }

    @include media("<=tablet") {
      opacity: 1;
    }

    .c-monthcard,
    .c-calendarcard {
      pointer-events: auto;
    }

    .c-menu__inner {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.8s 0s $ease-hero-marquee, transform 0.8s $ease-menu;
    }

    + .c-menu__fixed {
      .c-menu__butterflies {
        transform: translateX(-50%) scale(1);
        transition-delay: 0.3s;
      }
    }
  }

  &__fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.8s $ease-menu;
    transform: translateY(calc(-100% - 240px));
    overflow: hidden;
    @include pagePadding;
    z-index: z("nav");
    pointer-events: none;

    @include media("<=tablet") {
      display: none;
    }

    &.hide {
      transform: translateY(0);
    }

    .c-menu {
      &__header {
        height: 100%;

        .c-section {
          height: 100%;
        }
      }
    }
  }

  &__butterfly {
    position: absolute;

    &--left {
      transform: rotate(9.68deg);
      left: toRem(80);
      bottom: 179px;
    }

    &--right {
      transform: rotate(-25.77deg);
      right: toRem(275);
      bottom: 106px;

      @include media("<=tablet") {
        display: none;
      }

      .wing__inner {
        animation-delay: 1s !important;
      }
    }

    &--top {
      right: toRem(403);
      top: 108px;
      transform: rotate(-14.71deg);

      .wing__inner {
        animation-delay: 0.5s !important;
      }
    }
  }

  &__left {
    padding-top: 28px;
  }

  &__right {
    padding-top: 20px;
    border-left: $border-dark;
    width: 100%;
    max-width: 345px;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    z-index: -1;
    position: relative;

    &__top {
      text-align: right;
      padding-bottom: 24px;
      border-bottom: $border-dark;
    }

    &__bottom {
      padding-top: 32px;
      padding-bottom: 32px;
      display: flex;
      flex-direction: column;
      height: 100%;

      &__con {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 32px;
        border-bottom: $border-dark;
        margin-bottom: 32px;
      }

      &__footer {
        margin-top: auto;

        .m-footer {
          color: $dark-grey;
          @extend %body-14;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__link {
            margin-bottom: 32px;
            @extend %link;
          }

          &__copy {
            margin-bottom: 16px;
            text-align: center;
          }
        }
      }
    }

    &__card {
      text-align: center;
      max-width: 240px;

      &__emoji {
        @extend %emoji-24;
        margin-bottom: 16px;
      }

      &__label {
        @extend %body-16-bold;
        margin-bottom: 8px;
      }

      &__body {
        @extend %body-14;
        margin-bottom: 16px;
        color: $dark-grey;
      }
    }

    &__deco {
      padding-left: 8px;
      padding-right: 8px;
      max-width: 240px;
      margin-bottom: 24px;

      &__inner {
        background: $grey;
        box-shadow: inset 0px 0px 8px rgba(0, 0, 16, 0.125);
        border-radius: 4px;
        height: 48px;
        display: flex;
        align-items: center;
        overflow: hidden;
      }

      &__text {
        @extend %title-32-pixel;
        color: rgba(0, 0, 16, 0.35);
        white-space: nowrap;
        margin-top: 2px;

        &:not(:first-of-type) {
          padding: 0 12px;
        }
      }
    }
  }

  &__details {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
  }

  .month__cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#canvas {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: z("canvas");

  &.show {
    visibility: visible;
  }

  @include media("<=tablet") {
    display: none;
  }
}

.depth__image {
  @include ratio(1, 1);
  // background: #000;

  &__container {
    max-width: 800px;
    margin: 0 auto;
  }
}

.menu__mobile {
  overscroll-behavior: contain;
  overflow-y: scroll;
  z-index: z("menu");
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  opacity: 0;
  pointer-events: none;

  .c-monthcard,
  .c-calendarcard {
    pointer-events: none;
  }

  &.c-menu--active {
    pointer-events: auto;
    opacity: 1;

    .c-monthcard,
    .c-calendarcard {
      pointer-events: auto;
    }
  }

  .c-menu__right__bottom {
    border-top: $border-dark;
  }

  @include media(">tablet") {
    display: none;
  }

  &__month {
    &__name {
      @extend %title-64-pixel;
      text-align: center;
      margin-bottom: 24px;
      transition: opacity 0.3s;
    }
  }

  &__title {
    text-align: center;
  }

  &__inner {
    margin-top: 82px;

    .horizontal-snap {
      display: grid;
      grid-auto-flow: column;
      gap: 16px;
      height: 100%;
      padding: 16px;
      margin: 0 auto;
      max-width: 480px;
      overflow-y: auto;
      overscroll-behavior-x: contain;
      scroll-snap-type: x mandatory;
      padding-bottom: 48px;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      [data-snap-name="Outro"] {
        margin-right: 120px;
      }

      [data-snap-name="Intro"] {
        margin-left: 120px;
      }
    }

    .horizontal-snap > * {
      scroll-snap-align: center;
    }
  }

  &__indicators {
    margin-bottom: 64px;
    width: 100%;
    justify-content: center;
    @include flex-gap(8px);
    display: flex;
    height: 32px;
    align-items: center;
  }

  &__indicator {
    width: 6px;
    height: 16px;
    background: rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    border-radius: 6px;

    &.active {
      background: $dark-grey;
      height: 32px;
      transform-origin: center;
    }
  }
}
