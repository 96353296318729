:root {
  --topColor: #f1f1f2;
  --bottomColor: #f1f1f2;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background: none;
  border: 0;
  padding: 0;
  font-family: inherit;
}

a {
  font-family: inherit;
  text-decoration: none;
}

*, :before, :after {
  box-sizing: border-box;
  -webkit-touch-callout: none;
}

a, button {
  color: inherit;
  text-decoration: none;
}

ol, ul {
  list-style: none;
}

html {
  box-sizing: border-box;
  width: 100%;
  font-size: .694444vw;
}

@media (min-width: 1441px) {
  html {
    font-size: 10px;
  }
}

html, body {
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  height: 100%;
  color: #000010;
  font-feature-settings: "case" on;
  background: #fff;
  font-family: Suisse, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.35;
}

body.no-scroll {
  max-height: 100vh;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.pages {
  width: 100%;
  height: 100%;
}

@media (min-width: 769px) {
  .pages {
    overflow: hidden;
  }
}

.pages.no-scroll {
  max-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  overflow: hidden;
}

@media (min-width: 769px) {
  .pages--hidden .pages__inner {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (max-width: 768px) {
  .pages--hidden {
    max-height: 100vh;
    width: 100%;
    position: fixed;
    overflow: hidden;
  }
}

.dg.ac {
  z-index: 10 !important;
}

.bold {
  font-weight: 700;
}

button {
  cursor: pointer;
}

[data-marquee], [data-auto-marquee] {
  width: fit-content;
  will-change: transform;
  display: flex;
  position: relative;
}

[data-marquee].active, [data-auto-marquee].active {
  animation: marquee 25s linear infinite;
}

[data-marquee] [data-marquee-item], [data-auto-marquee] [data-marquee-item] {
  white-space: nowrap;
}

[data-marquee] [data-marquee-item]:not(:first-of-type), [data-auto-marquee] [data-marquee-item]:not(:first-of-type) {
  margin: 0 12px;
}

@keyframes marquee {
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@media (min-width: 769px) {
  [data-journal] {
    background-image: repeating-linear-gradient(#fff 0 80px, #ececec 81px);
    position: relative;
  }

  [data-journal]:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #ececec;
    position: absolute;
    top: 0;
    left: 0;
  }
}

[data-carousel] {
  z-index: 1;
}

[data-carousel] [data-carousel-item] {
  will-change: transform;
}

[data-carousel].transition [data-carousel-item] {
  transition: transform .5s linear;
}

[data-carousel] [data-carousel-indicator] {
  transition: background .3s ease-out;
}

[data-carousel] [data-carousel-indicator].active {
  background: #fff;
}

[data-carousel] [data-click-left] {
  width: 50%;
  z-index: 2;
  height: 100%;
  cursor: w-resize;
  position: absolute;
  left: 0;
}

[data-carousel] [data-click-right] {
  width: 50%;
  z-index: 2;
  height: 100%;
  cursor: e-resize;
  position: absolute;
  right: 0;
}

[data-carousel] [data-click-left], [data-carousel] [data-click-right] {
  opacity: 0;
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
}

[data-draggable] [data-drag-header] {
  cursor: grab;
}

[data-draggable].is-pointer-down [data-drag-header] {
  cursor: grabbing;
}

.flash {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinkerHover {
  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0)rotate(.5deg);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0)rotate(-.5deg);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0)rotate(.5deg);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0)rotate(-.5deg);
  }
}

@font-face {
  font-family: Suisse;
  src: url("../../SuisseIntl-Book.8593fb66.woff2") format("woff2"), url("../../SuisseIntl-Book.0f3168ab.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Suisse;
  src: url("../../SuisseIntl-SemiBold.ffd20b36.woff2") format("woff2"), url("../../SuisseIntl-SemiBold.6598c9bd.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PP Neue;
  src: url("../../PPNeueBit-Bold.0d00aa84.woff2") format("woff2"), url("../../PPNeueBit-Bold.d90bf4dd.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Fraunces;
  src: url("../../Fraunces-Italic-VariableFont.ec1bad40.woff2") format("woff2"), url("../../Fraunces-Italic-VariableFont.fd6b298b.woff") format("woff"), url("../../Fraunces-Italic-VariableFont.ec1bad40.woff2") format("woff2-variations");
  font-weight: 100 1000;
  font-display: swap;
}

@font-face {
  font-family: Adjusted Arial Fallback;
  src: local(Arial);
  size-adjust: 100%;
  ascent-override: normal;
  descent-override: normal;
  line-gap-override: normal;
}

.outro__contact__link, .month__credits__desc a, .month__journal__card__desc a, .home__credits__desc a, .home .m-hero__paragraph a, .home__hero__description a, .c-menu__right__bottom__footer .m-footer__link, .c-accordion__body a {
  vertical-align: top;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.outro__contact__link:after, .month__credits__desc a:after, .month__journal__card__desc a:after, .home__credits__desc a:after, .home .m-hero__paragraph a:after, .home__hero__description a:after, .c-menu__right__bottom__footer .m-footer__link:after, .c-accordion__body a:after {
  content: "";
  height: 1px;
  width: 100%;
  transform-origin: 0;
  background: currentColor;
  transition: transform .7s cubic-bezier(.19, 1, .22, 1);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(1);
}

.outro__contact__link:hover:after, .month__credits__desc a:hover:after, .month__journal__card__desc a:hover:after, .home__credits__desc a:hover:after, .home .m-hero__paragraph a:hover:after, .home__hero__description a:hover:after, .c-menu__right__bottom__footer .m-footer__link:hover:after, .c-accordion__body a:hover:after {
  transform-origin: 100%;
  transform: scaleX(0);
}

.outro__contact__link, .month__credits__desc a, .month__journal__card__desc a, .home__credits__desc a, .home .m-hero__paragraph a, .home__hero__description a, .c-menu__right__bottom__footer .m-footer__link, .c-accordion__body a {
  display: inline-block;
}

.month__journal__images, .home__final__card__top__video, .home__countries__card__images, .home__collab__intro__image, .home__stats__intro__image, .home .m-hero__reels__video, .home .m-hero .home__hero__reel__video, .home__hero__fixed .home__hero__credits__video, .c-feat-pl__image, .c-feat-pl__top, .c-feat-ly__image, .c-calendarcard__inner, .c-monthcard__image, .c-gradient-box, .c-nav__indicator, [data-carousel] {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.month__journal__images img, .home__final__card__top__video img, .home__countries__card__images img, .home__collab__intro__image img, .home__stats__intro__image img, .home .m-hero__reels__video img, .home .m-hero .home__hero__reel__video img, .home__hero__fixed .home__hero__credits__video img, .c-feat-pl__image img, .c-feat-pl__top img, .c-feat-ly__image img, .c-calendarcard__inner img, .c-monthcard__image img, .c-gradient-box img, .c-nav__indicator img, [data-carousel] img, .month__journal__images video, .home__final__card__top__video video, .home__countries__card__images video, .home__collab__intro__image video, .home__stats__intro__image video, .home .m-hero__reels__video video, .home .m-hero .home__hero__reel__video video, .home__hero__fixed .home__hero__credits__video video, .c-feat-pl__image video, .c-feat-pl__top video, .c-feat-ly__image video, .c-calendarcard__inner video, .c-monthcard__image video, .c-gradient-box video, .c-nav__indicator video, [data-carousel] video {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  overflow: hidden;
}

.month__journal__images, .c-modal__backdrop, .c-page-transition__bottom, .c-page-transition__bars__con, .m-playlist__image__con, .preloader__background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.outro--active, .notfound--active, .month--active, .months--active, .feed--active, .home--active {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  overflow: unset;
  height: auto;
}

.outro__contact__phone__inner img, .outro__contact__phone__inner video, .outro__hero__video img, .outro__hero__video video, .month__journal__card--rectangle .month__journal__images__con img, .month__journal__card--rectangle .month__journal__images__con video, .month__journal__card--rectangle-carousel .month__journal__images__con img, .month__journal__card--rectangle-carousel .month__journal__images__con video, .month__journal__card--square .month__journal__images__con img, .month__journal__card--square .month__journal__images__con video, .month__journal__card--square-carousel .month__journal__images__con img, .month__journal__card--square-carousel .month__journal__images__con video, .home__final__card__top__video img, .home__final__card__top__video video, .home__countries__card__images__con img, .home__countries__card__images__con video, .home__collab__intro__image img, .home__collab__intro__image video, .home__stats__intro__image img, .home__stats__intro__image video, .home .m-hero__reels__video img, .home .m-hero__reels__video video, .home__hero__fixed .home__hero__credits__video video, .home__hero__fixed .home__hero__credits svg, .home__hero__reel__video img, .home__hero__reel__video video, .home__hero__image img, .playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-image img, .playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-image video, .m-playlist__image img, .c-feat-artist__image img, .c-feat-artist__image video, .c-feat-pl__image img, .c-feat-pl__image video, .c-feat-ly__image img, .c-feat-ly__image video, .depth__image img, .depth__image video, .c-collabcard__image__con img, .c-collabcard__image__con video, .c-collabcard__image img, .c-collabcard__image video, .c-calendarcard__inner img, .c-calendarcard__inner video, .c-monthcard__image img, .c-monthcard__image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile-audio__header__icon span svg, .m-audio__card__index__icon svg, .c-feat-artist__icons__inner svg, .c-feat-artist__image__icon svg, .c-accordion__icon span svg, .m-footer__socials a svg {
  width: 100%;
  height: 100%;
  display: inline-block;
}

@media (max-width: 768px) {
  .c-collabcard__info__right, .preloader .c-dialog__desc .desktop, .c-nav__left .desktop {
    display: none;
  }
}

@media (min-width: 769px) {
  .preloader .c-dialog__desc .mobile {
    display: none;
  }
}

.c-stat__value {
  letter-spacing: -6.6px;
  font-size: 120px;
  line-height: 1;
}

@media (max-width: 768px) {
  .c-stat__value {
    letter-spacing: -2.24px;
    font-size: 64px;
    line-height: 1;
  }
}

.outro__dump__emojis, .home__hero__fixed .home__hero__enter__emoji, .m-footer__emoji {
  letter-spacing: 7.2px;
  font-size: 48px;
  line-height: 1;
}

@media (max-width: 768px) {
  .outro__dump__emojis, .home__hero__fixed .home__hero__enter__emoji, .m-footer__emoji {
    letter-spacing: 3.6px;
    font-size: 24px;
    line-height: 1;
  }
}

.notfound__emojis, .c-page-transition__text, .feed-card__body-randomize-button, .d-audio__icon span, .c-menu__right__card__emoji, .c-calendarcard__emoji, .c-monthcard__emoji {
  letter-spacing: 3.6px;
  font-size: 24px;
  line-height: 1;
}

.month__journal__card__title, .home__final__card__title, .home__countries__card .c-country__time, .home__collab__intro__title, .home__stats__intro__top__title, .home__hero__fixed .home__hero__enter__action, .mobile-playlist-header__content-title, .playlist-catalogue-wrapper .playlist-catalogue-body-playlists-intro-title, .feed-card__body-playlist-name, .c-feat-pl__title, .c-collabcard__alias, .c-dialog__body__emoji {
  letter-spacing: -.36px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.15;
}

.c-header__scroll, .c-header__title {
  letter-spacing: .35px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.c-accordion__title, .c-collabcard__track {
  font-size: 16px;
  line-height: 1;
}

.mobile-playlist-title, .playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-name, .playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-name, .feed-card__body-creative-link-name, .d-audio__song__title, .c-feat-artist__name, .c-feat-ly__title, .c-menu__right__card__label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
}

.notfound__desc, .month__credits__desc, .month__journal__card__desc, .feed__content footer .mobile .share-prompt, .feed__content .c-section-welcome-mobile, .home__final__card__desc, .home__credits__desc, .home__collab__intro__desc, .home__stats__intro__top__desc, .c-modal__content__bottom, .mobile-playlist-info-popup__body-creative-description, .mobile-playlist-info-popup__body-text, .playlist-catalogue-wrapper .playlist-catalogue-body-playlists-intro-description, .playlist-catalogue-wrapper .playlist-catalogue-body-welcome-text, .feed-card__body-creative-link-description, .feed-card__body-text, .d-audio__song__artist, .c-menu__right__card__body, .c-menu__right__bottom__footer .m-footer, .c-accordion__body, .c-footer__card__desc, .c-dialog__body__description, .preloader .c-dialog__desc {
  font-size: 14px;
  line-height: 1.35;
}

.home__hero__fixed .home__hero__enter__label, .mobile-playlist-footer-tag, .mobile-playlist-owner, .mobile-playlist-header__content-description, .playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-tag, .playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-tag, .playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-owner, .feed-card__footer-text, .feed-card__body-next-up-title, .mobile-audio__song, .m-audio__track, .m-audio__card__index__number, .m-audio__card__footer__label, .c-feat-pl__details, .c-feat-ly__artist, .preloader .c-dialog__number, .c-nav__smiley .c-toggle__label {
  font-size: 14px;
  line-height: 1;
}

.outro__dump__label, .outro__contact__link__top, .month__journal__card__label, .home__countries__card .c-country__name, .home__countries__card__title, .home .m-hero__alerts__title, .home__hero__reel__header, .mobile-playlist-info-popup__body-creative-name, .feed-card__body-next-up-name, .d-audio__duration, .c-feat-pl__label, .c-feat-ly__label, .c-footer__card__title, .m-footer__label, .c-collabcard__track__label, .c-collabcard__name, .c-stat__label, .c-button {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.month__credits__label, .feed__content footer .curated-by, .home__credits__label, .mobile-playlist-info-popup__body-text-title, .mobile-playlist-info-popup__header-text, .playlist-catalogue-wrapper .playlist-catalogue-footer-text, .playlist-catalogue-wrapper .playlist-catalogue-header-text, .feed-card__body-text-title, .feed-card__header-text, .mobile-audio__header__label, .c-dialog__header, .m-audio__header, .c-feat-artist__header, .c-nav__smiley .c-toggle__item .on, .c-nav__smiley .c-toggle__item .off, .c-nav__smiley .c-toggle__butterfly .c-toggle__label__items {
  letter-spacing: .3px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}

.month__hero__title, .m-footer__month {
  font-variation-settings: "opsz" 144, "SOFT" 100, "WONK" 1;
  font-feature-settings: "case" off;
  letter-spacing: -4px;
  font-family: Fraunces, serif;
  font-size: 160px;
  font-weight: 400;
  line-height: 1;
}

@media (max-width: 768px) {
  .month__hero__title, .m-footer__month {
    letter-spacing: -1.6px;
    font-size: 64px;
    line-height: 1;
  }
}

.outro__contact__link {
  font-variation-settings: "opsz" 144, "SOFT" 100, "WONK" 1;
  font-feature-settings: "case" off;
  letter-spacing: -1.6px;
  font-family: Fraunces, serif;
  font-size: 64px;
  font-weight: 400;
  line-height: 1;
}

.c-calendarcard__index, .c-monthcard__index {
  font-variation-settings: "opsz" 144, "SOFT" 100, "WONK" 1;
  font-feature-settings: "case" off;
  font-family: Fraunces, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.outro__contact__title, .outro__faqs__title, .outro__hero__title, .notfound__title, .month__lyrics__title, .home__final__title, .home__hero__title {
  text-transform: uppercase;
  font-feature-settings: "case" on;
  letter-spacing: -4.3px;
  font-family: PP Neue, Adjusted Arial Fallback, sans-serif;
  font-size: 172px;
  line-height: .7;
}

@media (max-width: 768px) {
  .outro__contact__title, .outro__faqs__title, .outro__hero__title, .notfound__title, .month__lyrics__title, .home__final__title, .home__hero__title {
    letter-spacing: -.96px;
    font-size: 64px;
  }
}

.home .m-hero .home__hero__title, .c-calendarcard__month, .c-monthcard__month {
  text-transform: uppercase;
  font-feature-settings: "case" on;
  letter-spacing: -2.4px;
  font-family: PP Neue, Adjusted Arial Fallback, sans-serif;
  font-size: 96px;
  line-height: .7;
}

.c-modal__title, .menu__mobile__month__name {
  text-transform: uppercase;
  font-feature-settings: "case" on;
  letter-spacing: -.96px;
  font-family: PP Neue, Adjusted Arial Fallback, sans-serif;
  font-size: 64px;
  line-height: .7;
}

.m-audio__deco__text, .c-menu__right__deco__text, .preloader .c-stickynote {
  text-transform: uppercase;
  font-feature-settings: "case" on;
  font-family: PP Neue, Adjusted Arial Fallback, sans-serif;
  font-size: 32px;
  line-height: .7;
}

.c-nav {
  z-index: 9;
  width: 100%;
  height: 58px;
  border-bottom: 1px solid #0000000d;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .c-nav {
    width: 48px;
    height: 100vh;
    border-bottom: 0;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 24px;
    left: 20px;
  }
}

@media (max-width: 768px) {
  .c-nav {
    background: #fff;
  }
}

.c-nav__left {
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .c-nav__left {
    height: 100%;
  }
}

.c-nav__left .c-button--icon-only.c-button--large {
  width: 46px;
  height: 46px;
  border: 1px solid #0000000d;
}

@media (min-width: 769px) {
  .c-nav__left {
    margin-bottom: 14px;
  }
}

.c-nav__left .c-button .close {
  display: none;
}

.c-nav__left .c-button.open .close {
  display: block;
}

.c-nav__left .c-button.open .open {
  display: none;
}

.c-nav__toggle {
  width: 56px;
  height: 100%;
  border-right: 1px solid #0000000d;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 769px) {
  .c-nav__toggle {
    display: none;
  }
}

.c-nav__toggle .close {
  display: none;
}

.c-nav__toggle.open .close {
  display: block;
}

.c-nav__toggle.open .open {
  display: none;
}

.c-nav__toggle svg {
  width: 20px;
  height: 20px;
}

.c-nav__indicator {
  width: 100%;
  height: 32px;
  border: 2px solid #ffffff59;
  border-radius: 40px;
  margin-left: 12px;
  margin-right: 12px;
}

@media (min-width: 769px) {
  .c-nav__indicator {
    height: 100%;
    width: 40px;
    margin: 0 0 12px;
  }
}

@keyframes pulseGradient {
  0% {
    background-position-y: 0;
  }

  50% {
    background-position-y: 100%;
  }

  100% {
    background-position-y: 0;
  }
}

.c-nav__indicator__inner {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, var(--topColor) 0%, var(--bottomColor) 100%);
  background-size: 100% 130%;
}

@media (min-width: 769px) {
  .c-nav__indicator__inner.is-animated {
    background: linear-gradient(180deg, var(--startColor) 0%, var(--endColor) 100%);
    background-size: 100% 200%;
    animation: pulseGradient 4s ease-in-out infinite;
  }
}

@media (max-width: 768px) {
  .c-nav__indicator__inner {
    background: linear-gradient(90deg, var(--topColor) 0%, var(--bottomColor) 100%);
    background-size: 130% 100%;
  }
}

.c-nav__smiley {
  flex-shrink: 0;
  position: relative;
}

@media (max-width: 768px) {
  .c-nav__smiley {
    border-left: 1px solid #0000000d;
    padding: 12px;
  }
}

.c-nav__smiley.active .c-toggle {
  opacity: 1;
  pointer-events: auto;
}

.c-nav__smiley.active .c-nav__smiley__rotate {
  display: none;
}

.c-nav__smiley.active .c-nav__smiley__arrow {
  display: flex;
}

.c-nav__smiley .c-toggle {
  width: 192px;
  opacity: 0;
  pointer-events: none;
  background: #000010;
  border-radius: 12px;
  transition: opacity .2s cubic-bezier(.24, .6, .25, 1);
  position: absolute;
  bottom: 0;
  right: -204px;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

@media (max-width: 768px) {
  .c-nav__smiley .c-toggle {
    bottom: -120px;
    right: 12px;
  }
}

@media (max-width: 768px) {
  .c-nav__smiley .c-toggle__fullscreen {
    display: none;
  }
}

.c-nav__smiley .c-toggle__butterfly .c-toggle__label__items {
  color: #fff;
  text-transform: uppercase;
  background: #00009f;
  border-radius: 4px;
  padding: 4px 6px;
}

.c-nav__smiley .c-toggle__item {
  width: 100%;
  padding: 8px;
}

.c-nav__smiley .c-toggle__item:not(:first-of-type) {
  border-top: 1px solid #ffffff40;
}

.c-nav__smiley .c-toggle__item__inner {
  height: 40px;
  width: 100%;
  padding: 0 8px;
  position: relative;
}

@media (any-pointer: fine) {
  .c-nav__smiley .c-toggle__item__inner:hover .c-toggle__label__text {
    color: #fff;
  }
}

.c-nav__smiley .c-toggle__item .on, .c-nav__smiley .c-toggle__item .off {
  text-transform: uppercase;
  border-radius: 4px;
  padding: 4px 6px;
}

.c-nav__smiley .c-toggle__item .on {
  color: #000010;
  background: #fff;
  display: none;
}

.c-nav__smiley .c-toggle__item .off {
  color: #ffffffbf;
  background: #ffffff40;
}

.c-nav__smiley .c-toggle__item input {
  appearance: none;
  cursor: pointer;
  z-index: 1;
  height: 100%;
  width: 100%;
  outline-color: #74747c;
  margin: 0;
  position: absolute;
  inset: 0;
}

.c-nav__smiley .c-toggle__item input:checked + label .c-toggle__label__items .on {
  display: block;
}

.c-nav__smiley .c-toggle__item input:checked + label .c-toggle__label__items .off {
  display: none;
}

.c-nav__smiley .c-toggle__label {
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.c-nav__smiley .c-toggle__label__text {
  color: #ffffffbf;
  transition: color .2s;
}

.c-nav__smiley__rotate {
  transform-origin: center;
  animation: rotate 15s linear infinite paused;
  display: flex;
}

.c-nav__smiley__rotate.playing {
  animation-play-state: running;
}

.c-nav__smiley__arrow {
  display: none;
}

.c-nav__smiley__inner {
  width: 40px;
  height: 40px;
  transition: transform .1s linear;
  display: grid;
}

@media (any-pointer: fine) {
  .c-nav__smiley__inner:hover {
    transform: scale(1.1);
  }
}

.c-nav__smiley__inner > span {
  grid-area: 1 / 1;
}

.c-nav__smiley__inner svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .c-nav__smiley__inner {
    width: 32px;
    height: 32px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  width: 100%;
  height: 100%;
  z-index: 14;
  background: #f1f1f2;
  position: fixed;
  top: 0;
  left: 0;
}

.preloader__inner {
  opacity: 0;
  display: flex;
}

@media (max-width: 768px) {
  .preloader .preloader__inner {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 72px;
    padding-top: 120px;
    display: inline-flex;
  }

  @supports not (gap: 72px 72px) {
    .preloader .preloader__inner {
      --row-gap: 72px;
      --column-gap: 72px;
      margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
      width: calc(100% + var(--column-gap) );
    }

    .preloader .preloader__inner > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }

  .preloader .c-dialog__header, .preloader .m-audio__header, .preloader .c-feat-artist__header {
    pointer-events: none;
  }
}

.preloader__background {
  background-image: repeating-linear-gradient(#f1f1f2 0 80px, #0000000d 81px);
}

.preloader .c-dialog--loader {
  max-width: 324px;
  width: 100%;
}

@media (min-width: 769px) {
  .preloader .c-dialog--loader {
    position: absolute;
    top: 22vh;
    left: 35.6rem;
  }
}

.preloader .c-dialog--info {
  max-width: 296px;
  width: 100%;
}

@media (min-width: 769px) {
  .preloader .c-dialog--info {
    position: absolute;
    bottom: 29vh;
    right: 39.1rem;
  }
}

.preloader .c-dialog__inner, .preloader .c-feat-artist__card {
  max-width: unset;
}

.preloader .c-dialog__content {
  padding: 16px;
}

.preloader .c-dialog__pads {
  background: #f1f1f2;
  border-radius: 8px;
  gap: 4px;
  margin-bottom: 16px;
  padding: 8px;
  display: inline-flex;
}

@supports not (gap: 4px 4px) {
  .preloader .c-dialog__pads {
    --row-gap: 4px;
    --column-gap: 4px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .preloader .c-dialog__pads > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.preloader .c-dialog__number {
  color: #74747c;
  text-align: right;
}

.preloader .c-dialog__desc {
  color: #74747c;
}

.preloader .c-dialog__desc:not(:last-child) {
  margin-bottom: 8px;
}

.preloader .c-dialog__pad {
  width: 24px;
  height: 24px;
  background: #0000000d;
  border-radius: 4px;
}

.preloader .c-dialog__pad.active {
  background: #74747c;
}

.preloader .c-stickynote {
  width: 192px;
  height: 192px;
  background: #fff;
  padding: 16px;
  position: absolute;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

.preloader .c-stickynote__content {
  color: #74747c;
}

@media (max-width: 768px) {
  .preloader .c-stickynote {
    display: none;
  }
}

.preloader .c-stickynote--left {
  bottom: 9.88vh;
  left: 20.1rem;
  transform: rotate(10.85deg);
}

.preloader .c-stickynote--right {
  top: 10.6vh;
  right: 20.2rem;
  transform: rotate(-5.41deg);
}

.c-gradient-box {
  width: 40px;
  height: 80vh;
  border: 2px solid #ffffff59;
  border-radius: 40px;
  position: fixed;
  bottom: 24px;
  left: 24px;
}

.c-gradient-box__inner {
  height: 100%;
  width: 100%;
  background: linear-gradient(#0ff 0%, #f0f 100%) 0 0 / 100% 130%;
}

.c-butterfly .wing {
  transform-style: preserve-3d;
  perspective: 500px;
}

.c-butterfly {
  max-width: 224px;
  pointer-events: none;
  flex-wrap: nowrap;
  display: flex;
}

@media (max-width: 768px) {
  .c-butterfly {
    max-width: 124px;
  }
}

.c-butterfly .wing {
  transform-origin: center;
  transform-style: preserve-3d;
}

.c-butterfly .wing img {
  width: 100%;
  height: auto;
}

.c-butterfly .wing--left {
  perspective-origin: 100%;
}

.c-butterfly .wing--left .wing__inner {
  transform-origin: 100%;
  animation: rotateLeftWing 2s cubic-bezier(.33, 0, .67, 1) infinite alternate;
}

.c-butterfly .wing--right {
  perspective-origin: 0;
}

.c-butterfly .wing--right .wing__inner {
  transform-origin: 0;
  animation: rotateRightWing 2s cubic-bezier(.33, 0, .67, 1) infinite alternate;
}

@keyframes rotateLeftWing {
  50% {
    transform: rotateY(60deg);
  }
}

@keyframes rotateRightWing {
  50% {
    transform: rotateY(-60deg);
  }
}

.c-button {
  color: #000010;
  background: #f1f1f2;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.c-button svg {
  width: 100%;
  height: 100%;
}

.c-button--white {
  color: #000010;
  background: #fff;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

.c-button--black {
  color: #fff;
  background: #000010;
}

.c-button--transparent {
  color: #74747c;
  background: none;
  border: 1px solid #0000000d;
}

.c-button--icon-only {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center;
}

.c-button--icon-only.c-button--large {
  width: 48px;
  height: 48px;
}

.c-button--icon-only .c-button__icon {
  width: 20px;
  height: 20px;
}

@media (any-pointer: fine) {
  .c-button--icon-only:hover .c-button__icon {
    transform: scale(1.05);
  }
}

.c-button--icon-text, .c-button--text-only {
  padding: 9px 12px;
}

.c-button--icon-text.c-button--large, .c-button--text-only.c-button--large {
  padding: 16px;
}

@media (any-pointer: fine) {
  .c-button--icon-text:hover .c-button__text, .c-button--text-only:hover .c-button__text {
    animation: blinkerHover 1s step-start;
  }
}

.c-button--icon-text {
  gap: 8px;
  display: inline-flex;
}

@supports not (gap: 8px 8px) {
  .c-button--icon-text {
    --row-gap: 8px;
    --column-gap: 8px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .c-button--icon-text > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.c-button--icon-text .c-button__icon {
  width: 16px;
  height: 16px;
}

.c-button:disabled {
  pointer-events: none;
}

.c-button:not(.custom-disabled):disabled {
  color: #00001059;
  pointer-events: none;
  background: none;
  border: 1px solid #0000000d;
}

.c-header {
  width: 100%;
  color: #74747c;
  position: relative;
}

@media (min-width: 769px) {
  .c-header {
    padding-top: 20px;
    padding-bottom: 24px;
  }
}

.c-header__inner {
  height: 40px;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .c-header__inner {
    height: unset;
    flex-direction: column-reverse;
    align-items: center;
    margin: 0;
  }
}

.c-header__title {
  color: #74747c;
  border: 1px solid #0000000d;
  border-radius: 8px;
  padding: 9px 12px;
  display: inline-block;
}

.c-header__title--light {
  color: #fff;
  border-color: #ffffff40;
}

.c-header__scroll {
  position: absolute;
  bottom: 11px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .c-header__scroll {
    display: none;
  }
}

.c-header__right {
  display: flex;
}

@media (max-width: 768px) {
  .c-header__right {
    width: 100%;
    border-bottom: 1px solid #0000000d;
    justify-content: center;
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
}

.c-dialog__inner, .c-feat-artist__card {
  max-width: 296px;
  width: 100%;
  color: #74747c;
  background: #fff;
  border: 1px solid #0000000d;
  border-radius: 12px;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

.c-dialog__header, .m-audio__header, .c-feat-artist__header {
  text-transform: uppercase;
  color: #74747c;
  border-bottom: 1px solid #0000000d;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 8px;
  display: flex;
}

.c-dialog__header__dots, .m-audio__header__dots, .c-feat-artist__header__dots {
  gap: 4px;
  display: inline-flex;
}

@supports not (gap: 4px 4px) {
  .c-dialog__header__dots, .m-audio__header__dots, .c-feat-artist__header__dots {
    --row-gap: 4px;
    --column-gap: 4px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .c-dialog__header__dots > *, .m-audio__header__dots > *, .c-feat-artist__header__dots > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.c-dialog__header__dot, .m-audio__header__dot, .c-feat-artist__header__dot {
  width: 12px;
  height: 12px;
  background: #f1f1f2;
  border-radius: 50%;
}

.c-dialog__body {
  border-bottom: 1px solid #0000000d;
  align-items: center;
  gap: 12px;
  padding: 8px;
  display: inline-flex;
}

@supports not (gap: 12px 12px) {
  .c-dialog__body {
    --row-gap: 12px;
    --column-gap: 12px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .c-dialog__body > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.c-dialog__body__emoji {
  grid-area: 1 / 1;
}

.c-dialog__body__emoji__con {
  width: 48px;
  height: 48px;
  background: #f1f1f2;
  border-radius: 4px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: grid;
  position: relative;
  overflow: hidden;
}

.c-dialog__footer {
  padding: 8px;
}

.c-dialog__footer .c-button {
  width: 100%;
}

.c-dialog--disabled .c-button {
  color: #00001059;
  pointer-events: none;
  background: none;
  border: 1px solid #0000000d;
}

.c-stat {
  width: 100%;
  border-top: 1px solid #0000000d;
  padding: 12px 4px 0;
}

@media (min-width: 769px) {
  .c-stat {
    background: #f1f1f2;
    border: none;
    border-radius: 8px;
    padding: 16px 16px 0 14px;
  }
}

@media (min-width: 769px) {
  .c-stat--small {
    max-width: 216px;
  }
}

@media (min-width: 769px) {
  .c-stat--large {
    max-width: 320px;
  }
}

.c-stat__label {
  color: #74747c;
  margin-bottom: 16px;
  margin-left: 2px;
}

.c-stat__body {
  align-items: flex-end;
  display: flex;
}

@media (min-width: 769px) {
  .c-stat__body {
    justify-content: space-between;
  }
}

@media (min-width: 769px) {
  .c-stat__value {
    margin-bottom: -1px;
  }
}

.c-monthcard {
  max-width: 512px;
  width: 100%;
  background: #fff;
  border: 1px solid #0000000d;
  border-radius: 12px;
  margin-bottom: 64px;
  padding: 16px 16px 12px;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

@media (any-pointer: fine) {
  .c-monthcard:hover .c-monthcard__month {
    animation: blinkerHover 1s step-start;
  }
}

@media (max-width: 1200px) {
  .c-monthcard {
    max-width: 450px;
  }
}

@media (max-width: 768px) {
  .c-monthcard {
    width: 304px;
    margin-bottom: 0;
    padding: 12px;
  }
}

.c-monthcard__image {
  border-radius: 4px;
  margin-bottom: 12px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .c-monthcard__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.c-monthcard__image img, .c-monthcard__image video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .c-monthcard__image {
    margin-bottom: 8px;
  }
}

.c-monthcard__gradient {
  height: 16px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .c-monthcard__gradient {
    margin-bottom: 8px;
  }
}

.c-monthcard__body {
  justify-content: space-between;
  display: flex;
}

@media (max-width: 768px) {
  .c-monthcard__month {
    display: none;
  }
}

@media (max-width: 768px) {
  .c-monthcard__right {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }
}

.c-monthcard__index {
  margin-top: 4px;
  font-weight: 600;
}

@media (min-width: 769px) {
  .c-monthcard__index {
    text-align: right;
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.c-calendarcard {
  max-width: 512px;
  width: 100%;
  color: #fff;
  background: #fff;
  border: 1px solid #0000000d;
  border-radius: 12px;
  margin-bottom: 64px;
  padding: 16px;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

@media (any-pointer: fine) {
  .c-calendarcard:hover .c-calendarcard__month {
    animation: blinkerHover 1s step-start;
  }
}

@media (max-width: 1200px) {
  .c-calendarcard {
    max-width: 450px;
  }
}

@media (max-width: 768px) {
  .c-calendarcard {
    width: 304px;
    margin-bottom: 0;
    padding: 12px;
  }
}

.c-calendarcard__inner {
  font-size: 0;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 8px #00001040;
}

@supports (aspect-ratio: calc(480 / 587)) {
  .c-calendarcard__inner:after {
    content: "";
    width: 100%;
    padding-top: 122.292%;
    display: inline-block;
  }
}

.c-calendarcard__inner img, .c-calendarcard__inner video {
  position: absolute;
  top: 0;
  left: 0;
}

.c-calendarcard__index {
  margin-bottom: 16px;
  font-weight: 600;
}

.c-calendarcard__image {
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .c-calendarcard__month {
    display: none;
  }
}

.c-calendarcard__body {
  width: 100%;
  height: 100%;
  padding: 32px;
  position: absolute;
  top: 0;
}

@media (max-width: 768px) {
  .c-calendarcard__body {
    padding: 24px;
  }
}

.c-collabcard {
  max-width: 384px;
  width: 100%;
  --progress: 0;
}

@media (max-width: 768px) {
  .c-collabcard {
    max-width: unset;
    border-top: 1px solid #0000000d;
    padding-top: 12px;
  }
}

@media (min-width: 769px) {
  .c-collabcard__top, .c-collabcard__bottom {
    background: #f1f1f2;
    border-radius: 8px;
  }
}

.c-collabcard__progress {
  width: 100%;
  height: 100%;
  transform-origin: 0;
  transform: scaleX(var(--progress) );
  pointer-events: none;
  background: #0000000d;
  transition: transform .2s linear;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .c-collabcard__progress {
    display: none;
  }
}

.c-collabcard__top {
  position: relative;
  overflow: hidden;
}

@media (min-width: 769px) {
  .c-collabcard__top {
    margin-bottom: 8px;
  }
}

.c-collabcard__image {
  width: 100%;
  max-width: 280px;
  width: 100%;
  font-size: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

@supports (aspect-ratio: calc(1 / 1)) {
  .c-collabcard__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.c-collabcard__image img, .c-collabcard__image video {
  position: absolute;
  top: 0;
  left: 0;
}

.c-collabcard__image__con {
  margin: 0 12px 12px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .c-collabcard__image__con:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.c-collabcard__image__con img, .c-collabcard__image__con video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .c-collabcard__image__con {
    max-width: 240px;
    margin: 32px auto;
  }
}

.c-collabcard__info {
  justify-content: space-between;
  display: flex;
}

@media (min-width: 769px) {
  .c-collabcard__info {
    padding: 16px;
  }
}

@media (max-width: 768px) {
  .c-collabcard__info__left {
    text-align: center;
    width: 100%;
  }
}

.c-collabcard__alias {
  margin-bottom: 8px;
}

.c-collabcard__name {
  color: #74747c;
}

.c-collabcard__bottom {
  width: 100%;
  height: 64px;
  align-items: center;
  padding: 0 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .c-collabcard__bottom {
    border-top: 1px solid #0000000d;
    padding: 0 8px;
  }
}

.c-collabcard__bottom__right {
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.c-collabcard__bottom__folio {
  height: 100%;
  border-left: 1px solid #0000000d;
  align-items: center;
  padding-left: 16px;
  display: flex;
}

@media (min-width: 769px) {
  .c-collabcard__bottom__folio {
    display: none;
  }
}

.c-collabcard__play {
  flex-shrink: 0;
  margin-right: 16px;
}

.c-collabcard__track__current {
  max-width: 300px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .c-collabcard__track__current {
    max-width: 240px;
  }
}

.c-collabcard__track__label {
  color: #74747c;
  margin-bottom: 8px;
}

.c-collabcard.playing .c-pause {
  display: block;
}

.c-collabcard.playing .c-play, .c-collabcard .c-pause {
  display: none;
}

.c-collabcard:not(.active) .c-collabcard__progress {
  opacity: 0;
}

.m-footer .c-section__inner {
  width: 100%;
  max-width: 1600px;
  border-top: 1px solid #0000000d;
  margin: 0 auto;
  padding: 120px 24px 160px;
  position: relative;
}

@media (max-width: 768px) {
  .m-footer .c-section__inner {
    padding: 96px 24px 200px;
  }
}

.m-footer__socials {
  gap: 16px;
  display: inline-flex;
}

@supports not (gap: 16px 16px) {
  .m-footer__socials {
    --row-gap: 16px;
    --column-gap: 16px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .m-footer__socials > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.m-footer__socials a {
  width: 20px;
  height: 20px;
  color: #74747c;
  transition: color .2s;
}

.m-footer__socials a:hover {
  color: #000010;
}

.m-footer__content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.m-footer__label {
  color: #74747c;
  margin-bottom: 16px;
}

.m-footer__emoji {
  margin-bottom: 16px;
}

.m-footer__month {
  filter: blur(2px);
  margin-bottom: 48px;
}

@media (max-width: 768px) {
  .m-footer__month {
    filter: blur(1px);
    margin-bottom: 32px;
  }
}

.m-footer__butterfly {
  position: absolute;
}

.m-footer__butterfly--left {
  bottom: 135px;
  left: 14.9rem;
  transform: rotate(9.68deg);
}

@media (max-width: 768px) {
  .m-footer__butterfly--left {
    bottom: 39px;
    left: 40px;
    transform: rotate(-8.05deg);
  }
}

.m-footer__butterfly--right {
  bottom: 105px;
  right: 18.3rem;
  transform: rotate(-39.03deg);
}

@media (max-width: 768px) {
  .m-footer__butterfly--right {
    display: none;
  }
}

.m-footer__butterfly--right .wing__inner {
  animation-delay: 1s !important;
}

.m-footer__butterfly--top {
  top: -91px;
  right: 38.1rem;
  transform: rotate(-22.3deg);
}

@media (max-width: 768px) {
  .m-footer__butterfly--top {
    top: -41px;
    right: 48px;
    transform: rotate(14.85deg);
  }
}

.m-footer__butterfly--top .wing__inner {
  animation-delay: .5s !important;
}

.c-footer {
  margin-bottom: 24px;
}

.c-footer .c-section__inner {
  border-top: 1px solid #0000000d;
  padding-top: 32px;
}

.c-footer__content {
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  display: inline-flex;
}

@supports not (gap: 16px 16px) {
  .c-footer__content {
    --row-gap: 16px;
    --column-gap: 16px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .c-footer__content > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.c-footer__card {
  width: 100%;
  height: 128px;
  border: 1px solid #0000000d;
  border-radius: 12px;
  padding: 24px;
  display: flex;
}

@media (min-width: 769px) {
  .c-footer__card {
    max-width: 315px;
  }
}

.c-footer__card__left {
  width: 100%;
}

.c-footer__card__right {
  flex-shrink: 0;
  align-self: flex-end;
}

.c-footer__card__title {
  margin-bottom: 8px;
}

.c-footer__card__desc {
  color: #74747c;
  max-width: 240px;
}

.c-footer__card--link {
  padding-bottom: 16px;
  padding-right: 16px;
}

.c-footer__card--copy {
  text-align: right;
  justify-content: flex-end;
}

.c-footer__card--copy .c-footer__card__desc {
  margin-bottom: 22px;
}

.c-accordion {
  border-top: 1px solid #0000000d;
  padding-top: 8px;
  padding-bottom: 8px;
}

.c-accordion:last-child {
  border-bottom: 1px solid #0000000d;
}

.c-accordion__header {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  display: flex;
}

.c-accordion__header::-webkit-details-marker {
  display: none;
}

.c-accordion__title {
  margin-right: 16px;
}

.c-accordion__icon {
  display: grid;
}

.c-accordion__icon span {
  width: 16px;
  height: 16px;
  grid-area: 1 / 1;
}

.c-accordion__icon__minus {
  display: none;
}

.c-accordion__body {
  color: #74747c;
  padding: 16px;
}

.c-accordion__body p:first-of-type {
  margin: 0;
}

.c-accordion__body p:not(:first-of-type) {
  margin-top: revert;
}

.c-accordion__body p:last-of-type {
  margin-bottom: 0;
}

.c-accordion[open] .c-accordion__icon__plus {
  display: none;
}

.c-accordion[open] .c-accordion__icon__minus {
  display: inline-block;
}

.c-menu {
  z-index: 7;
  width: 100%;
  height: calc(100% + 240px);
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (max-width: 768px) {
  .c-menu {
    display: none;
  }
}

@media (min-width: 769px) {
  .c-menu {
    transform: translateY(-100%);
  }
}

.c-menu__transition {
  min-width: 1440px;
  width: 100%;
  height: 240px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

@media (max-width: 768px) {
  .c-menu__transition {
    display: none;
  }
}

.c-menu__transition img {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.c-menu__inner {
  width: 100%;
  max-width: calc(100% - 345px);
  z-index: -1;
  opacity: 0;
  padding-top: 48px;
  padding-left: 88px;
  transition: opacity .8s cubic-bezier(.24, .6, .25, 1) .2s, transform .8s cubic-bezier(.63, 0, .4, .99);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 75vh, 0);
}

.c-menu__butterflies {
  pointer-events: none;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  transition: transform .8s cubic-bezier(.24, .6, .25, 1) .2s;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)scale(1.2);
}

@media (min-width: 769px) {
  .c-menu--active {
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .c-menu--active {
    opacity: 1;
  }
}

.c-menu--active .c-monthcard, .c-menu--active .c-calendarcard {
  pointer-events: auto;
}

.c-menu--active .c-menu__inner {
  opacity: 1;
  transition: opacity .8s cubic-bezier(.24, .6, .25, 1), transform .8s cubic-bezier(.63, 0, .4, .99);
  transform: translate3d(0, 0, 0);
}

.c-menu--active + .c-menu__fixed .c-menu__butterflies {
  transition-delay: .3s;
  transform: translateX(-50%)scale(1);
}

.c-menu__fixed {
  width: 100%;
  height: 100%;
  z-index: 9;
  pointer-events: none;
  transition: transform .8s cubic-bezier(.63, 0, .4, .99);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateY(calc(-100% - 240px));
}

.c-menu__fixed .c-section {
  width: 100%;
}

.c-menu__fixed .c-section:not(.full-section) {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 769px) {
  .c-menu__fixed .c-section:not(.full-section) {
    max-width: calc(100% - 88px);
    margin-left: auto;
    padding-left: 0;
    padding-right: 24px;
  }
}

.c-menu__fixed .c-section:not(.full-section) .c-section__inner {
  width: 100%;
}

@media (max-width: 768px) {
  .c-menu__fixed {
    display: none;
  }
}

.c-menu__fixed.hide {
  transform: translateY(0);
}

.c-menu__fixed .c-menu__header, .c-menu__fixed .c-menu__header .c-section {
  height: 100%;
}

.c-menu__butterfly {
  position: absolute;
}

.c-menu__butterfly--left {
  bottom: 179px;
  left: 8rem;
  transform: rotate(9.68deg);
}

.c-menu__butterfly--right {
  bottom: 106px;
  right: 27.5rem;
  transform: rotate(-25.77deg);
}

@media (max-width: 768px) {
  .c-menu__butterfly--right {
    display: none;
  }
}

.c-menu__butterfly--right .wing__inner {
  animation-delay: 1s !important;
}

.c-menu__butterfly--top {
  top: 108px;
  right: 40.3rem;
  transform: rotate(-14.71deg);
}

.c-menu__butterfly--top .wing__inner {
  animation-delay: .5s !important;
}

.c-menu__left {
  padding-top: 28px;
}

.c-menu__right {
  width: 100%;
  max-width: 345px;
  pointer-events: auto;
  z-index: -1;
  border-left: 1px solid #0000000d;
  flex-direction: column;
  padding-top: 20px;
  display: flex;
  position: relative;
}

.c-menu__right__top {
  text-align: right;
  border-bottom: 1px solid #0000000d;
  padding-bottom: 24px;
}

.c-menu__right__bottom {
  height: 100%;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
}

.c-menu__right__bottom__con {
  width: 100%;
  border-bottom: 1px solid #0000000d;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  padding-bottom: 32px;
  display: flex;
}

.c-menu__right__bottom__footer {
  margin-top: auto;
}

.c-menu__right__bottom__footer .m-footer {
  color: #74747c;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.c-menu__right__bottom__footer .m-footer__link {
  margin-bottom: 32px;
}

.c-menu__right__bottom__footer .m-footer__copy {
  text-align: center;
  margin-bottom: 16px;
}

.c-menu__right__card {
  text-align: center;
  max-width: 240px;
}

.c-menu__right__card__emoji {
  margin-bottom: 16px;
}

.c-menu__right__card__label {
  margin-bottom: 8px;
}

.c-menu__right__card__body {
  color: #74747c;
  margin-bottom: 16px;
}

.c-menu__right__deco {
  max-width: 240px;
  margin-bottom: 24px;
  padding-left: 8px;
  padding-right: 8px;
}

.c-menu__right__deco__inner {
  height: 48px;
  background: #f1f1f2;
  border-radius: 4px;
  align-items: center;
  display: flex;
  overflow: hidden;
  box-shadow: inset 0 0 8px #00001020;
}

.c-menu__right__deco__text {
  color: #00001059;
  white-space: nowrap;
  margin-top: 2px;
}

.c-menu__right__deco__text:not(:first-of-type) {
  padding: 0 12px;
}

.c-menu__details {
  height: 100%;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.c-menu .month__cards {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#canvas {
  pointer-events: none;
  visibility: hidden;
  z-index: 8;
  position: fixed;
  inset: 0;
}

#canvas.show {
  visibility: visible;
}

@media (max-width: 768px) {
  #canvas {
    display: none;
  }
}

.depth__image {
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .depth__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.depth__image img, .depth__image video {
  position: absolute;
  top: 0;
  left: 0;
}

.depth__image__container {
  max-width: 800px;
  margin: 0 auto;
}

.menu__mobile {
  overscroll-behavior: contain;
  z-index: 7;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.menu__mobile .c-monthcard, .menu__mobile .c-calendarcard {
  pointer-events: none;
}

.menu__mobile.c-menu--active {
  pointer-events: auto;
  opacity: 1;
}

.menu__mobile.c-menu--active .c-monthcard, .menu__mobile.c-menu--active .c-calendarcard {
  pointer-events: auto;
}

.menu__mobile .c-menu__right__bottom {
  border-top: 1px solid #0000000d;
}

@media (min-width: 769px) {
  .menu__mobile {
    display: none;
  }
}

.menu__mobile__month__name {
  text-align: center;
  margin-bottom: 24px;
  transition: opacity .3s;
}

.menu__mobile__title {
  text-align: center;
}

.menu__mobile__inner {
  margin-top: 82px;
}

.menu__mobile__inner .horizontal-snap {
  height: 100%;
  max-width: 480px;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  grid-auto-flow: column;
  gap: 16px;
  margin: 0 auto;
  padding: 16px 16px 48px;
  display: grid;
  overflow-y: auto;
}

.menu__mobile__inner .horizontal-snap::-webkit-scrollbar {
  display: none;
}

.menu__mobile__inner .horizontal-snap [data-snap-name="Outro"] {
  margin-right: 120px;
}

.menu__mobile__inner .horizontal-snap [data-snap-name="Intro"] {
  margin-left: 120px;
}

.menu__mobile__inner .horizontal-snap > * {
  scroll-snap-align: center;
}

.menu__mobile__indicators {
  width: 100%;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 64px;
  display: flex;
}

@supports not (gap: 8px 8px) {
  .menu__mobile__indicators {
    --row-gap: 8px;
    --column-gap: 8px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .menu__mobile__indicators > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.menu__mobile__indicator {
  width: 6px;
  height: 16px;
  background: #0000000d;
  border-radius: 6px;
  transition: all .2s;
}

.menu__mobile__indicator.active {
  height: 32px;
  transform-origin: center;
  background: #74747c;
}

.c-feat-ly {
  height: 88px;
  width: 400px;
  background: #f1f1f2;
  border-radius: 8px;
  justify-content: space-between;
  padding: 12px;
  display: flex;
}

@media (max-width: 768px) {
  .c-feat-ly {
    width: 100%;
  }
}

.c-feat-ly__left {
  width: 100%;
  align-items: center;
  display: flex;
}

.c-feat-ly__right {
  flex-shrink: 0;
}

.c-feat-ly__label {
  color: #74747c;
  margin-bottom: 8px;
}

.c-feat-ly__info {
  width: 100%;
}

.c-feat-ly__image {
  border-radius: 4px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .c-feat-ly__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.c-feat-ly__image img, .c-feat-ly__image video {
  position: absolute;
  top: 0;
  left: 0;
}

.c-feat-ly__image__con {
  max-width: 64px;
  width: 100%;
  flex-shrink: 0;
  margin-right: 16px;
}

.c-feat-ly__title {
  width: calc(100% - 40px);
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
  overflow: hidden;
}

.c-feat-ly__artist {
  color: #74747c;
}

.c-feat-ly__bottom .c-button {
  width: 100%;
  justify-content: space-between;
}

.c-feat-pl {
  max-width: 384px;
  width: 100%;
}

@media (max-width: 768px) {
  .c-feat-pl {
    max-width: 264px;
  }
}

.c-feat-pl__top {
  border-radius: 8px;
  margin-bottom: 4px;
}

.c-feat-pl__top__inner {
  background: #f1f1f2;
  padding-top: 16px;
  padding-bottom: 12px;
}

.c-feat-pl__top__info {
  background: #f1f1f2;
  padding: 16px 0;
}

.c-feat-pl__label {
  color: #74747c;
  padding: 0 16px;
}

.c-feat-pl__image {
  border-radius: 4px;
  font-size: 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 4px #f1f1f2;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .c-feat-pl__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.c-feat-pl__image img, .c-feat-pl__image video {
  position: absolute;
  top: 0;
  left: 0;
}

.c-feat-pl__image__con {
  border-left: 12px solid #f1f1f2;
  border-right: 12px solid #f1f1f2;
}

.c-feat-pl__title {
  margin-bottom: 8px;
  padding: 0 16px;
}

.c-feat-pl__details {
  color: #74747c;
  padding: 0 16px;
}

.c-feat-pl__bottom .c-button {
  width: 100%;
  justify-content: space-between;
}

.c-feat-artist {
  max-width: 256px;
  width: 100%;
  z-index: 2;
  --progress: 0;
  position: fixed;
  top: 185px;
  left: 188px;
}

.c-feat-artist__progress {
  height: 100%;
  width: 100%;
  transform-origin: 0;
  transform: scaleX(var(--progress) );
  background: #0000000d;
  transition: transform .2s linear;
  position: absolute;
  top: 0;
  left: 0;
}

.c-feat-artist__body {
  padding-top: 8px;
  position: relative;
}

.c-feat-artist:not(.active) .c-feat-artist__progress {
  opacity: 0;
}

.c-feat-artist__card {
  overflow: hidden;
}

@media (max-width: 767px) {
  .c-feat-artist {
    display: none;
  }
}

.c-feat-artist .pause {
  display: none;
}

.c-feat-artist.playing .pause {
  display: block;
}

.c-feat-artist.playing .play, .c-feat-artist__hidden {
  display: none;
}

.c-feat-artist__image {
  border-radius: 4px;
  font-size: 0;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 8px #00001020;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .c-feat-artist__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.c-feat-artist__image img, .c-feat-artist__image video {
  position: absolute;
  top: 0;
  left: 0;
}

.c-feat-artist__image__icon {
  width: 21px;
  height: 21px;
  color: #fff;
  z-index: 1;
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.c-feat-artist__image__con {
  padding-left: 8px;
  padding-right: 8px;
}

.c-feat-artist__name {
  color: #000010;
  padding: 12px 16px;
  position: relative;
}

.c-feat-artist__bottom {
  border-top: 1px solid #0000000d;
  align-items: center;
  display: flex;
}

.c-feat-artist__icons {
  border-right: 1px solid #0000000d;
  flex-shrink: 0;
  padding: 11px;
}

@media (any-pointer: fine) {
  .c-feat-artist__icons:hover {
    transform: scale(1.05);
  }
}

.c-feat-artist__icons__inner {
  width: 20px;
  height: 20px;
  display: grid;
}

.c-feat-artist__icons__inner > span {
  width: 20px;
  height: 20px;
  grid-area: 1 / 1;
}

.c-feat-artist__song__wrapper {
  width: 100%;
  max-width: 183px;
  margin-left: 10px;
  margin-right: 12px;
  padding-left: 4px;
  display: grid;
  position: relative;
  overflow: hidden;
}

.c-feat-artist__song__wrapper > div {
  grid-area: 1 / 1;
}

.c-feat-artist__song__wrapper:before, .c-feat-artist__song__wrapper:after {
  content: "";
  width: 4px;
  height: 100%;
  z-index: 1;
  background: linear-gradient(270deg, #fff 0%, #fff0 100%);
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.c-feat-artist__song__wrapper:after {
  left: auto;
  right: 0;
  transform: rotate(0deg);
}

.m-playlist {
  height: 800px;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  display: flex;
  position: relative;
}

@media (min-width: 769px) {
  .m-playlist {
    height: 100vh;
    padding-left: 88px;
    padding-right: 24px;
  }
}

.m-playlist__image {
  width: 100%;
  height: 1016px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .m-playlist__image {
    height: calc(100vh + 150px);
  }
}

.m-playlist__image__con {
  height: 100%;
  overflow: hidden;
}

.m-playlist__inner {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.m-audio {
  max-width: 296px;
  width: 100%;
  z-index: 2;
  --progress: 0;
  position: fixed;
  top: 222px;
  right: 62px;
}

@media (max-width: 768px) {
  .m-audio {
    display: none;
  }
}

.m-audio__card {
  width: 100%;
  background: #fff;
  border: 1px solid #0000000d;
  border-radius: 12px;
  margin-bottom: 4px;
  box-shadow: 1px 4px 8px #00000010, 2px 16px 24px #00000010;
}

.m-audio__card__footer {
  color: #74747c;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  transition: color .2s;
  display: flex;
}

.m-audio__card__footer:hover {
  color: #000010;
}

.m-audio__card__index {
  align-items: center;
  display: flex;
}

.m-audio__card__index__number {
  margin-right: 4px;
}

.m-audio__card__index__icon {
  width: 16px;
  height: 16px;
  transition: transform .2s ease-out;
}

.m-audio__dropdown {
  overscroll-behavior: contain;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #f1f1f2bf;
  border-radius: 12px;
  overflow: scroll;
}

.m-audio__dropdown.desktop {
  max-height: 0;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
}

.m-audio__dropdown::-webkit-scrollbar {
  display: none;
}

.m-audio.open .m-audio__dropdown {
  max-height: 320px;
  border: 1px solid #ffffff40;
}

.m-audio.open .m-audio__card__index__icon {
  transform: rotate(180deg);
}

.m-audio__deco {
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.m-audio__deco__inner {
  height: 48px;
  background: #f1f1f2;
  border-radius: 4px;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 8px #00001020;
}

.m-audio__deco__progress {
  height: 100%;
  width: 100%;
  transform-origin: 0;
  transform: scaleX(var(--progress) );
  background: #0000000d;
  transition: transform .2s linear;
  position: absolute;
  top: 0;
  left: 0;
}

.m-audio__deco__text {
  color: #00001059;
  white-space: nowrap;
  margin-top: 2px;
}

.m-audio__deco__text:not(:first-of-type) {
  padding: 0 12px;
}

.m-audio__current {
  border-bottom: 1px solid #0000000d;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  display: flex;
}

.m-audio__current .c-button {
  flex-shrink: 0;
}

.m-audio__song {
  white-space: nowrap;
}

.m-audio__song__wrapper {
  width: 100%;
  max-width: 224px;
  padding: 0 4px;
  position: relative;
  overflow: hidden;
}

.m-audio__song__wrapper__con {
  display: grid;
}

.m-audio__song__wrapper__con > div {
  grid-area: 1 / 1;
}

.m-audio__song__wrapper:before, .m-audio__song__wrapper:after {
  content: "";
  width: 4px;
  height: 100%;
  z-index: 1;
  background: linear-gradient(270deg, #fff 0%, #fff0 100%);
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.m-audio__song__wrapper:after {
  left: auto;
  right: 0;
  transform: rotate(0deg);
}

.m-audio .c-pause, .m-audio.playing .c-play {
  display: none;
}

.m-audio.playing .c-pause {
  display: block;
}

.m-audio:not(.active) .m-audio__deco__progress {
  opacity: 0;
}

.m-audio__track {
  text-align: left;
  width: 100%;
  color: #000010a6;
  border-radius: 4px;
  padding: 8px;
  transition: background .2s ease-out;
  display: flex;
}

@media (any-pointer: fine) {
  .m-audio__track:hover {
    background: #0000000d;
  }
}

.m-audio__track__left {
  width: 24px;
  flex-shrink: 0;
  margin-right: 8px;
}

.m-audio__track__left__rainbow {
  display: none;
}

.m-audio__track__title {
  margin-bottom: 6px;
  font-weight: 700;
}

.m-audio__track__wrapper {
  padding: 8px;
}

.m-audio__track__wrapper:not(:first-of-type) {
  border-top: 1px solid #0000000d;
}

.m-audio__track__wrapper.active.m-audio__track, .m-audio__track__wrapper.active .m-audio__track, .m-audio__track__wrapper.first.m-audio__track, .m-audio__track__wrapper.first .m-audio__track {
  background: #fff;
}

.m-audio__track__wrapper.active.m-audio__track__left__rainbow, .m-audio__track__wrapper.active .m-audio__track__left__rainbow, .m-audio__track__wrapper.first.m-audio__track__left__rainbow, .m-audio__track__wrapper.first .m-audio__track__left__rainbow {
  display: block;
}

.m-audio__track__wrapper.active.m-audio__track__left__index, .m-audio__track__wrapper.active .m-audio__track__left__index, .m-audio__track__wrapper.first.m-audio__track__left__index, .m-audio__track__wrapper.first .m-audio__track__left__index {
  display: none;
}

.m-audio__track:not([data-preview-url-inner]) {
  color: #00001059;
  cursor: not-allowed;
}

.m-audio__track:not([data-preview-url-inner]):hover {
  background: none;
}

.mobile-audio {
  width: 100%;
  z-index: 2;
  height: 100%;
  pointer-events: none;
  --progress: 0;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  .mobile-audio {
    display: none;
  }
}

.mobile-audio__backdrop {
  width: 100%;
  height: 100%;
  opacity: 0;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  pointer-events: none;
  background: #ffffff59;
  transition: opacity .35s ease-in-out;
}

.mobile-audio__header {
  height: 65px;
  color: #fff;
  background: #00f;
  border: 1px solid #ffffff40;
  align-items: center;
  padding-right: 16px;
  display: flex;
  position: relative;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

.mobile-audio__header__circle {
  width: 32px;
  height: 32px;
  position: absolute;
  transform: rotate(266deg);
}

.mobile-audio__header__circle circle {
  stroke-dasharray: 98;
  stroke-dashoffset: calc(98px - 98px * var(--progress) );
}

.mobile-audio__header__circle svg {
  width: 100%;
  height: 100%;
}

.mobile-audio__header__button {
  max-width: 64px;
  width: 100%;
  height: 100%;
  border-right: 1px solid #ffffff59;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  display: flex;
  position: relative;
}

.mobile-audio__header__button .c-button__icon {
  width: 20px;
  height: 20px;
}

.mobile-audio__header__right {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.mobile-audio__header__label {
  color: #ffffffbf;
  margin-bottom: 6px;
}

.mobile-audio__header__icon {
  width: 32px;
  height: 32px;
  background: #ffffff59;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mobile-audio__header__icon span {
  transform-origin: center;
  width: 20px;
  height: 20px;
  transition: transform .2s ease-out;
  display: inline-block;
  transform: rotate(180deg);
}

.mobile-audio__song {
  white-space: nowrap;
}

.mobile-audio__song:not(:first-of-type) {
  padding: 0 12px;
}

.mobile-audio__song__wrapper {
  width: 100%;
  max-width: 224px;
  position: relative;
  overflow: hidden;
}

.mobile-audio__song__wrapper__con {
  display: grid;
}

.mobile-audio__song__wrapper__con > div {
  grid-area: 1 / 1;
}

.mobile-audio__content {
  width: 100%;
  z-index: 3;
  pointer-events: auto;
  border-top: 1px solid #00001059;
  transition: transform .35s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(calc(100% - 66px));
}

.mobile-audio .m-audio__dropdown {
  max-height: 360px;
  background: #f1f1f2;
}

.mobile-audio.open {
  pointer-events: auto;
}

.mobile-audio.open .mobile-audio__backdrop {
  opacity: 1;
  pointer-events: auto;
}

.mobile-audio.open .mobile-audio__content {
  transform: translateY(0);
}

.mobile-audio.open .mobile-audio__header__icon span {
  transform: rotate(0deg);
}

.mobile-audio .c-pause, .mobile-audio.playing .c-play {
  display: none;
}

.mobile-audio.playing .c-pause {
  display: block;
}

.d-audio {
  --progress: 0;
  width: 100%;
}

.d-audio__top {
  background: #f1f1f2;
  border-radius: 8px;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.d-audio__top__left {
  width: 100%;
  align-items: center;
  display: flex;
}

.d-audio__top__right {
  flex-shrink: 0;
}

.d-audio__bottom {
  height: 48px;
  width: 100%;
  background: #f1f1f2;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.d-audio__play {
  width: 20px;
  height: 20px;
  color: #000010;
}

.d-audio__play svg {
  width: 100%;
  height: 100%;
}

.d-audio__progress {
  width: 100%;
  height: 100%;
  transform-origin: 0;
  transform: scaleX(var(--progress) );
  pointer-events: none;
  background: #0000000d;
  transition: transform .2s linear;
  position: absolute;
  top: 0;
  left: 0;
}

.d-audio__icon {
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  display: flex;
}

.d-audio__icon span {
  width: 24px;
  height: 24px;
}

.d-audio__song {
  width: 100%;
}

.d-audio__song__title {
  width: calc(100% - 24px);
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
  overflow: hidden;
}

.d-audio__song__artist {
  color: #74747c;
}

.d-audio.playing .c-pause {
  display: block;
}

.d-audio.playing .c-play, .d-audio .c-pause {
  display: none;
}

.d-audio:not(.active) .d-audio__progress {
  opacity: 0;
}

.feed-card {
  background: #fff;
  border: 1px solid #0000000d;
  border-radius: 12px;
  position: fixed;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

@media (max-width: 768px) {
  .feed-card {
    visibility: hidden;
    display: none;
  }
}

.feed-card.is-hidden {
  display: none;
}

.feed-card--playlist {
  width: 496px;
}

.feed-card--playlist[data-card-id="playlist-info"] {
  top: 49vh;
  left: 34.4vw;
}

.feed-card--playlist[data-card-id="spotify-embed"] {
  top: 240px;
  left: 112px;
}

.feed-card--playlist-selector {
  width: 296px;
  top: 296px;
  right: 120px;
}

.feed-card--work-window {
  top: 11.5vh;
  left: 24.5vw;
}

.feed-card__header {
  height: 32px;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

.feed-card__header-text {
  color: #74747c;
  text-transform: uppercase;
}

.feed-card__header-buttons {
  display: flex;
}

.feed-card__header-button {
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-color: #0000000d;
  border: none;
  border-radius: 50%;
}

.feed-card__header-button + .feed-card__header-button {
  margin-left: 4px;
}

.feed-card__body {
  border-top: 1px solid #0000000d;
  border-bottom: 1px solid #0000000d;
  padding: 8px;
}

.feed-card__body--no-bottom-border {
  border-bottom: none;
}

.feed-card__body--playlist-info {
  padding: 12px 16px 16px;
}

.feed-card__body--playlist-selector {
  padding: 32px 16px 16px;
}

.feed-card__body--embed {
  height: 368px;
}

.feed-card__body-text {
  color: #74747c;
  font-weight: 400;
}

.feed-card__body-text--center {
  text-align: center;
  line-height: 1;
}

.feed-card__body-text-title {
  color: #000010;
  text-transform: uppercase;
}

.feed-card__body-text-title:after {
  content: " ";
}

.feed-card__body-creative-link {
  border: 1px solid #0000000d;
  border-radius: 12px;
  align-items: center;
  margin: 16px 0;
  padding: 12px;
  display: flex;
}

.feed-card__body-creative-link-image {
  width: 64px;
  height: 64px;
  object-fit: cover;
  margin-right: 16px;
}

.feed-card__body-creative-link-name {
  color: #000010;
  margin-bottom: 4px;
}

.feed-card__body-creative-link-description {
  color: #74747c;
}

.feed-card__body-creative-link-external-link {
  width: 32px;
  height: 32px;
  color: #74747c;
  border: 1px solid #0000000d;
  border-radius: 50%;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.feed-card__body-playlist-name {
  text-align: center;
  margin: 8px 0 4px;
}

.feed-card__body-selector-controls {
  align-items: center;
  margin-top: 32px;
  display: flex;
}

.feed-card__body-next-up {
  height: 56px;
  color: #74747c;
  text-align: left;
  border: 1px solid #0000000d;
  border-radius: 12px;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  padding: 12px 16px;
  display: flex;
}

.feed-card__body-next-up:hover {
  background-color: #0000000d;
}

.feed-card__body-next-up-title {
  color: #00001059;
  margin-bottom: 4px;
}

.feed-card__body-next-up:hover .feed-card__body-next-up-title {
  color: #74747c;
}

.feed-card__body-next-up-name {
  color: #74747c;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 136px;
  overflow: hidden;
}

.feed-card__body-next-up:hover .feed-card__body-next-up-name {
  color: #000010;
}

.feed-card__body-randomize-button {
  height: 56px;
  width: 56px;
  background-color: #0000;
  border: 1px solid #0000000d;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.feed-card__body-randomize-button:hover {
  background-color: #0000000d;
}

.feed-card__body-media-wrapper {
  color: #fff;
  cursor: pointer;
  position: relative;
}

.feed-card__body-media-wrapper-control-icon {
  width: 160px;
  height: 160px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.feed-card__body-media-wrapper.is-active[data-play-state="paused"]:hover .play-icon, .feed-card__body-media-wrapper.is-active[data-play-state="playing"]:hover .pause-icon {
  display: block;
}

.feed-card__body-media {
  border-radius: 4px;
  display: none;
}

.is-active .feed-card__body-media {
  display: block;
}

.feed-card__footer {
  height: 42px;
  width: 100%;
  color: #74747c;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.feed-card__footer--is-hidden {
  display: none;
}

.feed-card__footer--tall {
  height: 48px;
  padding: 8px;
}

.feed-card__footer .c-button {
  width: 100%;
}

.feed-card__footer:hover {
  color: #000010;
}

.playlist-catalogue-wrapper {
  width: 1126px;
  z-index: 15;
  height: 88.5%;
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .playlist-catalogue-wrapper {
    visibility: hidden;
    display: none;
  }
}

.playlist-catalogue-wrapper.is-active {
  pointer-events: auto;
}

.playlist-catalogue-wrapper-butterfly {
  opacity: 0;
  transition-timing-function: cubic-bezier(.24, .6, .25, 1);
  position: absolute;
  transform: scale(0);
}

.playlist-catalogue-wrapper-butterfly--left {
  position: absolute;
  top: 105px;
  left: -109px;
  transform: rotate(15deg);
}

.playlist-catalogue-wrapper-butterfly--right {
  width: 224px;
  top: 60%;
  left: 93%;
  transform: rotate(-29.04deg);
}

.playlist-catalogue-wrapper-butterfly--right .wing__inner {
  animation-delay: 1s !important;
}

.playlist-catalogue-wrapper .c-button {
  opacity: 0;
  border-radius: 48px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playlist-catalogue-wrapper .playlist-catalogue {
  backdrop-filter: blur(32px);
  opacity: 0;
  height: 64px;
  background: #ffffffbf;
  border: 1px solid #ffffff40;
  border-radius: 12px;
  flex-direction: column;
  display: flex;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playlist-catalogue-wrapper .playlist-catalogue-header {
  height: 32px;
  border-bottom: 1px solid #0000000d;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

.playlist-catalogue-wrapper .playlist-catalogue-header-text {
  color: #74747c;
  text-transform: uppercase;
}

.playlist-catalogue-wrapper .playlist-catalogue-header-buttons {
  gap: 0 4px;
  display: inline-flex;
}

@supports not (gap: 0 4px) {
  .playlist-catalogue-wrapper .playlist-catalogue-header-buttons {
    --row-gap: 0;
    --column-gap: 4px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .playlist-catalogue-wrapper .playlist-catalogue-header-buttons > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.playlist-catalogue-wrapper .playlist-catalogue-header-button {
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-color: #0000000d;
  border: none;
  border-radius: 50%;
}

.playlist-catalogue-wrapper .playlist-catalogue-body {
  flex-grow: 1;
  padding: 0 32px;
  overflow: auto;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-welcome {
  flex-shrink: 0;
  padding: 48px 0;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-welcome-text {
  text-align: center;
  color: #000010a6;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-playlists-intro {
  text-align: center;
  width: 100%;
  border: 1px solid #0000000d;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 40px 0;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-playlists-intro--creative {
  opacity: 0;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-playlists-intro-title {
  color: #000010;
  margin-bottom: 8px;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-playlists-intro-description {
  color: #000010a6;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-playlists-grid {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px 12px;
  margin-bottom: 16px;
  display: grid;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-playlists-grid--large-margin {
  margin-bottom: 32px;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist {
  cursor: pointer;
  background-color: #ffffff59;
  border-radius: 8px;
  padding: 8px 8px 16px;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist:hover {
  background-color: #ffffffbf;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-image-wrapper {
  position: relative;
}

@keyframes bitmojiHover {
  0% {
    transform: translate(-50%, 16px);
  }

  50% {
    transform: translate(-50%, 64px);
  }

  100% {
    transform: translate(-50%, 16px);
  }
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-bitmoji {
  width: 160px;
  height: 160px;
  opacity: 0;
  z-index: 1;
  transition-property: opacity, transform;
  transition-duration: .1s, 0s;
  transition-timing-function: linear, ease-in-out;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: .6s;
  animation-fill-mode: forwards;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 80px);
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-bitmoji.is-animated {
  animation-name: bitmojiHover;
  transform: translate(-50%, 16px);
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-bitmoji.is-visible {
  opacity: 1;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-bitmoji.is-animated.is-visible {
  transition-duration: .1s, .6s;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 14px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-image img, .playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-image video {
  position: absolute;
  top: 0;
  left: 0;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-content {
  padding: 0 8px;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-name {
  color: #000010;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-bottom: 4px;
  overflow: hidden;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-owner {
  color: #000010a6;
  margin-bottom: 12px;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-footer {
  align-items: center;
  display: flex;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-tags {
  gap: 0 4px;
  display: inline-flex;
}

@supports not (gap: 0 4px) {
  .playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-tags {
    --row-gap: 0;
    --column-gap: 4px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-tags > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-tag {
  color: #000010a6;
  background-color: #ffffff59;
  border-radius: 4px;
  padding: 8px;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-creative-playlist-spotify-link {
  width: 32px;
  height: 32px;
  color: #74747c;
  border: 1px solid #0000000d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist {
  cursor: pointer;
  background-color: #ffffff59;
  border-radius: 8px;
  padding: 16px;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist:hover {
  background-color: #ffffffbf;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-top {
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 14px;
  display: flex;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-image {
  width: 64px;
  height: 64px;
  border-radius: 4px;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-spotify-link {
  width: 32px;
  height: 32px;
  color: #74747c;
  border: 1px solid #0000000d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-name {
  color: #000010;
  margin-bottom: 8px;
}

.playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-tags {
  gap: 0 4px;
  display: inline-flex;
}

@supports not (gap: 0 4px) {
  .playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-tags {
    --row-gap: 0;
    --column-gap: 4px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-tags > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.playlist-catalogue-wrapper .playlist-catalogue-body-null-playlist-tag {
  color: #000010a6;
  background-color: #ffffff59;
  border-radius: 4px;
  padding: 8px;
}

.playlist-catalogue-wrapper .playlist-catalogue-footer {
  height: 32px;
  border-top: 1px solid #0000000d;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

.playlist-catalogue-wrapper .playlist-catalogue-footer-text {
  color: #00001059;
  text-transform: uppercase;
}

.mobile-playlist-header {
  margin: 64px 0 48px;
  padding: 0 20px;
  position: relative;
}

.mobile-playlist-header-butterfly {
  width: 124px;
  position: absolute;
}

.mobile-playlist-header-butterfly-creative-left {
  top: -20px;
  left: 30px;
  transform: rotate(13.09deg);
}

.mobile-playlist-header-butterfly-creative-right {
  bottom: -20px;
  right: 0;
  transform: rotate(-11.32deg);
}

.mobile-playlist-header-butterfly-null-top {
  top: -30px;
  right: 80px;
  transform: rotate(-11.9deg);
}

.mobile-playlist-header-butterfly-null-bottom {
  bottom: -24px;
  left: 78px;
  transform: rotate(12.34deg);
}

.mobile-playlist-header__content {
  height: 240px;
  width: 100%;
  border: 1px solid #0000000d;
  border-radius: 12px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 769px) {
  .mobile-playlist-header__content {
    visibility: hidden;
    display: none;
  }
}

.mobile-playlist-header__content-title {
  color: #000010;
  margin-bottom: 8px;
}

.mobile-playlist-header__content-description {
  color: #000010a6;
}

.mobile-playlists {
  border-bottom: 1px solid #0000000d;
}

@media (min-width: 769px) {
  .mobile-playlists {
    visibility: hidden;
    display: none;
  }
}

.mobile-playlist {
  width: 100%;
  scroll-snap-margin-top: 100px;
  border-top: 1px solid #0000000d;
  padding: 24px 16px 22px 20px;
  scroll-margin-top: 100px;
}

.mobile-playlist.is-expanded {
  background: #f1f1f2;
  border: none;
}

.mobile-playlist-main-content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.mobile-playlist-title {
  color: #000010;
  margin-bottom: 4px;
}

.mobile-playlist-title--large-margin {
  margin-bottom: 8px;
}

.mobile-playlist-owner {
  color: #000010a6;
  margin-bottom: 12px;
}

.mobile-playlist-footer {
  color: #000010a6;
  align-items: center;
  display: flex;
}

.mobile-playlist-footer-tag {
  color: #000010a6;
  background-color: #f1f1f2;
  border-radius: 4px;
  padding: 8px;
}

.mobile-playlist-footer-tag:first-of-type {
  margin-right: 4px;
}

.is-expanded .mobile-playlist-footer-tag {
  background: #ffffff59;
}

.mobile-playlist-footer .c-button {
  margin-left: 8px;
}

.is-expanded .mobile-playlist-footer .c-button {
  display: none;
}

.mobile-playlist-memoji {
  width: 88px;
  height: 88px;
}

.mobile-playlist-image {
  width: 64px;
  height: 64px;
}

.mobile-playlist-embed {
  margin-top: 20px;
  display: none;
}

.is-expanded .mobile-playlist-embed {
  display: block;
}

.mobile-playlist-controls {
  width: 100%;
  gap: 0 12px;
  margin-top: 16px;
  display: none;
}

@supports not (gap: 0 12px) {
  .mobile-playlist-controls {
    --row-gap: 0;
    --column-gap: 12px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .mobile-playlist-controls > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.mobile-playlist-controls .c-button {
  box-shadow: none;
}

.mobile-playlist-controls [data-share-playlist] {
  flex-shrink: 0;
}

.mobile-playlist-controls-about {
  flex-grow: 1;
  justify-content: space-between;
}

.is-expanded .mobile-playlist-controls {
  display: flex;
}

.mobile-playlist-collapse {
  width: 100%;
  color: #000010;
  justify-content: space-between;
  margin-top: 12px;
  display: none;
}

.mobile-playlist-collapse--without-margin {
  margin-top: 0;
}

.is-expanded .mobile-playlist-collapse {
  display: flex;
}

.mobile-playlist-info {
  height: 100vh;
  width: 100vw;
  z-index: 13;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .mobile-playlist-info {
    visibility: hidden;
    display: none;
  }
}

.mobile-playlist-info.is-visible {
  display: flex;
}

.mobile-playlist-info__overlay {
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(8px);
  background: #000010a6;
  position: absolute;
}

.mobile-playlist-info-popup {
  background: #fff;
  border: 1px solid #0000000d;
  border-radius: 12px;
  margin-bottom: 16px;
  position: relative;
  overflow: auto;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

.mobile-playlist-info-popup__header {
  height: 32px;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  display: flex;
  position: sticky;
  top: 0;
}

.mobile-playlist-info-popup__header-text {
  color: #74747c;
  text-transform: uppercase;
}

.mobile-playlist-info-popup__header-buttons {
  display: flex;
}

.mobile-playlist-info-popup__header-button {
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-color: #0000000d;
  border: none;
  border-radius: 50%;
}

.mobile-playlist-info-popup__header-button + .mobile-playlist-info-popup__header-button {
  margin-left: 4px;
}

.mobile-playlist-info-popup__body {
  border-top: 1px solid #0000000d;
  padding: 12px 16px 20px;
}

.mobile-playlist-info-popup__body-text {
  color: #74747c;
  font-weight: 400;
}

.mobile-playlist-info-popup__body-text-title {
  color: #000010;
  text-transform: uppercase;
}

.mobile-playlist-info-popup__body-text-title:after {
  content: " ";
}

.mobile-playlist-info-popup__body-creative {
  border: 1px solid #0000000d;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  padding: 16px;
  display: flex;
}

.mobile-playlist-info-popup__body-creative-name {
  color: #000010;
  margin-bottom: 4px;
}

.mobile-playlist-info-popup__body-creative-description {
  color: #000010a6;
}

.mobile-playlist-info-popup__body-creative-external-link {
  width: 32px;
  height: 32px;
  color: #000010a6;
  border: 1px solid #0000000d;
  border-radius: 50%;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  margin-left: auto;
  display: flex;
}

.mobile-playlist-info-popup__body-media-wrapper {
  color: #fff;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
}

.mobile-playlist-info-popup__body-media-wrapper-control-icon {
  width: 160px;
  height: 160px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-playlist-info-popup__body-media-wrapper.is-active[data-play-state="paused"]:hover .play-icon, .mobile-playlist-info-popup__body-media-wrapper.is-active[data-play-state="playing"]:hover .pause-icon {
  display: block;
}

.mobile-playlist-info-popup__body-media {
  max-width: 100%;
  border-radius: 8px;
  margin: 0 auto;
  display: none;
}

.is-active .mobile-playlist-info-popup__body-media {
  display: block;
}

.mobile-playlist-info-close {
  box-shadow: none;
  border-radius: 48px;
  position: relative;
}

.c-page-transition {
  z-index: 12;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.c-page-transition.active {
  display: block;
}

.c-page-transition__bars {
  grid-template-columns: repeat(7, 1fr);
  display: grid;
}

.c-page-transition__bars__con {
  height: calc(100vh - 160px);
  overflow: hidden;
}

@media (max-width: 768px) {
  .c-page-transition__bars__con {
    height: calc(100vh - 64px);
  }
}

.c-page-transition__bars--top {
  height: calc(100% - 120px);
}

@media (max-width: 768px) {
  .c-page-transition__bars--top {
    height: calc(100% - 48px);
  }
}

.c-page-transition__bars--bottom {
  height: 120px;
}

@media (max-width: 768px) {
  .c-page-transition__bars--bottom {
    height: 48px;
  }
}

.c-page-transition__bottom {
  height: 160px;
  top: unset;
  background: #000010;
  justify-content: space-between;
  align-items: center;
  padding: 0 64px;
  display: flex;
  bottom: 0;
  overflow: hidden;
}

@media (max-width: 768px) {
  .c-page-transition__bottom {
    height: 64px;
    justify-content: center;
  }
}

.c-page-transition__text {
  color: #fff;
  text-transform: uppercase;
  animation: blinker .5s step-start infinite;
}

@media (max-width: 768px) {
  .c-page-transition__text:not(:first-of-type) {
    display: none;
  }
}

.idle-feed-state {
  width: 100vw;
  height: 100vh;
  z-index: 20;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .idle-feed-state {
    visibility: hidden;
    display: none;
  }
}

.idle-feed-state-image {
  opacity: 0;
  transition-timing-function: cubic-bezier(0, 0, .12, 1.6);
  position: absolute;
  top: 50%;
  left: 32.77%;
  transform: scale(.5)translateY(-50%);
}

.idle-feed-state-butterfly {
  opacity: 0;
  transition-timing-function: cubic-bezier(.24, .6, .25, 1);
  position: absolute;
  transform: scale(0);
}

.idle-feed-state-dancing-butterfly {
  opacity: 0;
  position: absolute;
  transform: scale(0);
}

.idle-feed-state-top-left-butterfly {
  top: 20.12%;
  left: 19.06%;
  transform: rotate(26.07deg);
}

.idle-feed-state-bottom-left-butterfly {
  top: 68%;
  left: 38%;
  transform: rotate(14.03deg);
}

.idle-feed-state-bottom-left-butterfly .wing__inner {
  animation-delay: .5s !important;
}

.idle-feed-state-top-right-butterfly {
  top: 20.66%;
  left: 62.01%;
  transform: rotate(-18.62deg);
}

.idle-feed-state-top-right-butterfly .wing__inner {
  animation-delay: 1.5s !important;
}

.idle-feed-state-bottom-right-butterfly {
  top: 47.21%;
  left: 76.04%;
  transform: rotate(5.02deg);
}

.idle-feed-state-bottom-right-butterfly .wing__inner {
  animation-delay: 1s !important;
}

.idle-feed-state-svg {
  opacity: 0;
  position: absolute;
  top: 119px;
  left: 252px;
}

.feed-bg-image {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0;
  transition-duration: .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.feed-bg-image.is-active {
  opacity: 1;
}

@media (max-width: 768px) {
  .feed-bg-image {
    visibility: hidden;
    display: none;
  }
}

.c-modal {
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  place-items: center;
  padding: 20px;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
}

.c-modal__backdrop {
  backdrop-filter: blur(8px);
  opacity: 0;
  pointer-events: none;
  background: #000010a6;
}

.c-modal__content {
  z-index: 11;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.c-modal__content__body {
  overscroll-behavior: contain;
  background: #fff;
  border-radius: 8px;
  box-shadow: 1px 4px 8px #00001010, 2px 16px 24px #00001010;
}

@media (max-width: 768px) {
  .c-modal__content__body {
    max-height: 480px;
    overflow: scroll;
  }
}

.c-modal__content__footer {
  text-align: center;
  margin-top: 16px;
}

.c-modal__content__footer .c-button {
  border-radius: 48px;
}

@media (min-width: 769px) {
  .c-modal__content__footer {
    display: none;
  }
}

.c-modal__content__top {
  border-bottom: 1px solid #0000000d;
  justify-content: space-between;
  padding: 24px 24px 20px 32px;
  display: flex;
}

@media (max-width: 768px) {
  .c-modal__content__top {
    padding: 16px;
  }

  .c-modal__content__top .c-button {
    display: none;
  }
}

.c-modal__content__bottom {
  color: #74747c;
  padding: 32px;
}

@media (max-width: 768px) {
  .c-modal__content__bottom {
    padding: 16px;
  }
}

.c-modal.active {
  pointer-events: auto;
  opacity: 1;
}

.c-modal.active .c-modal__backdrop {
  opacity: 1;
  pointer-events: auto;
}

.home .c-section {
  width: 100%;
}

.home .c-section:not(.full-section) {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 769px) {
  .home .c-section:not(.full-section) {
    max-width: calc(100% - 88px);
    margin-left: auto;
    padding-left: 0;
    padding-right: 24px;
  }
}

.home .c-section:not(.full-section) .c-section__inner {
  width: 100%;
}

.home__inner {
  margin-top: 82px;
  position: relative;
}

@media (min-width: 769px) {
  .home__inner {
    margin-top: 0;
    display: flex;
  }
}

.home__inner h2 {
  position: sticky;
  top: 0;
}

.home__content {
  width: 100vw;
}

.home__content footer {
  margin-top: auto;
}

.home .c-header {
  z-index: 2;
}

@media (min-width: 769px) {
  .home .c-header {
    background: #fff;
  }
}

.home__hero {
  --height: 1324px;
  margin-bottom: 32px;
  position: relative;
}

@media (max-width: 768px) {
  .home__hero {
    display: none;
  }
}

.home__hero__dialog {
  z-index: 2;
  position: fixed;
}

.home__hero__dialog--left {
  top: 100px;
  left: 104px;
}

.home__hero__dialog--bottom {
  bottom: 120px;
  left: 104px;
}

.home__hero__dialog--right {
  top: 149px;
  right: 64px;
}

.home__hero__outer {
  width: 100%;
  height: var(--height);
  transition: all 1.6s cubic-bezier(.24, .6, .25, 1);
  position: relative;
  overflow: hidden;
}

.home__hero.masked .home__hero__outer {
  transform: translateY(15%);
}

.home__hero.masked .home__hero__inner {
  width: 76%;
  height: 182px;
  will-change: height;
  margin: 0 auto;
}

.home__hero__inner {
  width: 100%;
  height: var(--height);
  border-radius: 12px;
  margin: 0 auto;
  transition: all 1s;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 8px #00001040;
}

.home__hero__butterflies {
  height: 182px;
  width: 100%;
  width: 76%;
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)translateY(109%);
}

.home__hero__butterfly {
  position: absolute;
}

.home__hero__butterfly:first-of-type {
  top: -90px;
  right: 28.7rem;
}

.home__hero__butterfly:first-of-type .c-butterfly {
  transform: rotate(-15.57deg);
}

.home__hero__butterfly:nth-of-type(2) {
  bottom: -120px;
  right: -136px;
}

.home__hero__butterfly:nth-of-type(2) .c-butterfly {
  transform: rotate(-23.17deg);
}

.home__hero__butterfly:nth-of-type(2) .wing__inner {
  animation-delay: 1s !important;
}

.home__hero__butterfly:last-of-type {
  bottom: -80px;
  left: -100px;
}

.home__hero__butterfly:last-of-type .c-butterfly {
  transform: rotate(35deg);
}

.home__hero__butterfly:last-of-type .wing__inner {
  animation-delay: .5s !important;
}

.home__hero__image {
  height: var(--height);
}

.home__hero__content {
  height: var(--height);
  width: 100%;
  padding: 0 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.home__hero__marquee {
  margin-top: 40px;
}

@media (min-width: 769px) {
  .home__hero__title {
    color: #fff;
  }
}

.home__hero__title:not(:first-of-type) {
  margin: 0 32px;
}

@media (max-width: 768px) {
  .home__hero__title:not(:first-of-type) {
    margin: 0 16px;
  }
}

.home__hero__description {
  max-width: 560px;
  text-align: center;
  color: #fff;
  margin: 160px auto 0;
}

.home__hero__description p:first-of-type {
  margin: 0;
}

.home__hero__description p:not(:first-of-type) {
  margin-top: revert;
}

.home__hero__description p:last-of-type {
  margin-bottom: 0;
}

.home__hero__reel {
  max-width: 664px;
  background: #f1f1f2;
  border-radius: 8px;
  margin: 120px auto 0;
}

.home__hero__reel__header {
  color: #74747c;
  padding: 16px;
}

.home__hero__reel__video {
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(640 / 480)) {
  .home__hero__reel__video:after {
    content: "";
    width: 100%;
    padding-top: 75%;
    display: inline-block;
  }
}

.home__hero__reel__video img, .home__hero__reel__video video {
  position: absolute;
  top: 0;
  left: 0;
}

.home__hero__reel__video__con {
  padding: 0 12px 12px;
}

.home__hero__fixed {
  width: calc(100% - 24px);
  height: calc(100vh - 80px);
  pointer-events: none;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
}

.home__hero__fixed .home__hero__text__top {
  margin-right: 22rem;
  position: absolute;
  top: 9vh;
}

.home__hero__fixed .home__hero__bottom {
  width: 76%;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  position: absolute;
  bottom: 13vh;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-height: 780px) {
  .home__hero__fixed .home__hero__bottom {
    bottom: 24px;
  }
}

.home__hero__fixed .home__hero__credits {
  position: relative;
}

.home__hero__fixed .home__hero__credits__text {
  display: flex;
}

.home__hero__fixed .home__hero__credits__video {
  width: 88px;
  height: 88px;
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  left: 41px;
}

@media (max-width: 1200px) {
  .home__hero__fixed .home__hero__credits__video {
    display: none;
  }
}

.home__hero__fixed .home__hero__enter {
  width: 360px;
  height: 200px;
  pointer-events: auto;
  background: #f1f1f2;
  border-radius: 12px;
  flex-direction: column;
  flex-shrink: 0;
  margin-left: 40px;
  padding: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home__hero__fixed .home__hero__enter__progress {
  width: 100%;
  height: 100%;
  transform-origin: 0;
  pointer-events: none;
  background: #0000000d;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
}

.home__hero__fixed .home__hero__enter__emoji {
  z-index: 1;
  position: relative;
}

.home__hero__fixed .home__hero__enter__bottom {
  width: 100%;
  text-align: left;
  z-index: 1;
  justify-content: space-between;
  margin-top: auto;
  display: flex;
  position: relative;
}

.home__hero__fixed .home__hero__enter__label {
  color: #74747c;
  margin-bottom: 8px;
}

.home .m-hero {
  margin-top: 48px;
  margin-bottom: 64px;
  overflow: hidden;
}

@media (min-width: 769px) {
  .home .m-hero {
    margin-top: 0;
    display: none;
  }
}

.home .m-hero .home__hero__reel {
  background: none;
  border-top: 1px solid #0000000d;
  margin: 0 20px;
  padding: 12px 0 0;
}

.home .m-hero .home__hero__reel__header {
  margin-bottom: 16px;
  padding: 0 0 0 4px;
}

.home .m-hero .home__hero__reel__video {
  border-radius: 4px;
}

.home .m-hero .home__hero__reel__video__con {
  padding: 0;
}

.home .m-hero__paragraph {
  border-top: 1px solid #0000000d;
  margin: 32px 24px 64px;
  padding-top: 24px;
}

.home .m-hero__paragraph p:first-of-type {
  margin: 0;
}

.home .m-hero__paragraph p:not(:first-of-type) {
  margin-top: revert;
}

.home .m-hero__paragraph p:last-of-type {
  margin-bottom: 0;
}

.home .m-hero__text__top {
  text-align: center;
  border-bottom: 1px solid #0000000d;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.home .m-hero__text__bottom {
  text-align: center;
}

.home .m-hero__marquee {
  margin-top: 0;
  margin-bottom: 16px;
}

.home .m-hero__reels {
  border-bottom: 1px solid #0000000d;
  justify-content: center;
  gap: 12px;
  margin-bottom: 24px;
  padding-bottom: 32px;
  display: flex;
}

@supports not (gap: 12px 12px) {
  .home .m-hero__reels {
    --row-gap: 12px;
    --column-gap: 12px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .home .m-hero__reels > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.home .m-hero__reels__video {
  max-width: 96px;
  width: 100%;
  border-radius: 4px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .home .m-hero__reels__video:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.home .m-hero__reels__video img, .home .m-hero__reels__video video {
  position: absolute;
  top: 0;
  left: 0;
}

.home .m-hero__alerts {
  background: #ff0;
  border-radius: 12px;
  margin-top: 48px;
  padding-top: 24px;
  padding-bottom: 48px;
  overflow: scroll;
  box-shadow: inset 0 0 8px #00001040;
}

.home .m-hero__alerts__con {
  padding: 0 12px;
}

.home .m-hero__alerts__title {
  text-align: center;
  color: #000010a6;
  margin-bottom: 32px;
}

.home .m-hero__alerts__cards {
  overscroll-behavior-x: contain;
  grid-auto-flow: column;
  gap: 16px;
  padding-left: 24px;
  padding-right: 24px;
  display: grid;
  overflow-y: auto;
}

.home .m-hero__alerts__cards .c-dialog {
  width: 296px;
  flex-shrink: 0;
}

.home__entries {
  margin-bottom: 64px;
}

@media (min-width: 769px) {
  .home__entries__inner__inner {
    min-height: 7858px;
    padding-top: 120px;
  }
}

.home__stats {
  max-width: 1328px;
  --starting-margin: 27.5rem;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .home__stats {
    --starting-margin: 13rem;
  }
}

@media (max-width: 768px) {
  .home__stats {
    margin-bottom: 64px;
  }
}

.home__stats__intro {
  max-width: 264px;
  border-radius: 8px;
  margin-bottom: 32px;
}

@media (min-width: 769px) {
  .home__stats__intro {
    margin-left: var(--starting-margin);
    background: #f1f1f2;
    margin-bottom: 16px;
  }
}

.home__stats__intro__top {
  margin-bottom: 16px;
  padding-left: 4px;
}

@media (min-width: 769px) {
  .home__stats__intro__top {
    margin-bottom: 0;
    padding: 16px;
  }
}

.home__stats__intro__top__title {
  margin-bottom: 12px;
}

.home__stats__intro__top__desc {
  color: #74747c;
}

.home__stats__intro__image {
  border-radius: 4px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .home__stats__intro__image:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.home__stats__intro__image img, .home__stats__intro__image video {
  position: absolute;
  top: 0;
  left: 0;
}

.home__stats__intro__image__con {
  max-width: 240px;
}

@media (min-width: 769px) {
  .home__stats__intro__image__con {
    max-width: unset;
    padding: 12px;
  }
}

@media (min-width: 769px) {
  .home__stats .c-stat:first-of-type {
    margin-left: calc(var(--starting-margin)  + 48px);
    margin-bottom: 16px;
  }

  .home__stats .c-stat:nth-of-type(2) {
    margin-left: calc(var(--starting-margin)  + 264px);
    margin-bottom: 16px;
  }

  .home__stats .c-stat:nth-of-type(3) {
    margin-left: calc(var(--starting-margin)  + 264px);
    margin-bottom: 120px;
  }

  .home__stats .c-stat:nth-of-type(4) {
    margin-left: calc(var(--starting-margin)  + 264px);
    margin-bottom: 16px;
  }

  .home__stats .c-stat:nth-of-type(5) {
    margin-left: calc(var(--starting-margin)  + 368px);
    margin-bottom: 120px;
  }

  .home__stats .c-stat:nth-of-type(6) {
    margin-bottom: 16px;
  }

  .home__stats .c-stat:nth-of-type(6), .home__stats .c-stat:nth-of-type(7) {
    margin-left: auto;
    margin-right: 27rem;
  }
}

@media (max-width: 768px) {
  .home__stats .c-stat:not(:last-of-type) {
    margin-bottom: 32px;
  }
}

.home__collab__intro {
  max-width: 344px;
  background-color: #f1f1f2;
  border-radius: 8px;
  margin: 0 auto 120px;
}

@media (max-width: 768px) {
  .home__collab__intro {
    max-width: unset;
    background: none;
    border-top: 1px solid #0000000d;
    margin-bottom: 64px;
    padding-top: 12px;
  }
}

.home__collab__intro__top {
  margin-bottom: 16px;
}

@media (min-width: 769px) {
  .home__collab__intro__top {
    margin-bottom: 0;
    padding: 16px;
  }
}

.home__collab__intro__title {
  margin-bottom: 12px;
}

.home__collab__intro__desc {
  color: #74747c;
}

.home__collab__intro__image {
  border-radius: 4px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(320 / 240)) {
  .home__collab__intro__image:after {
    content: "";
    width: 100%;
    padding-top: 75%;
    display: inline-block;
  }
}

.home__collab__intro__image img, .home__collab__intro__image video {
  position: absolute;
  top: 0;
  left: 0;
}

.home__collab__intro__image__con {
  padding-right: 16px;
}

@media (min-width: 769px) {
  .home__collab__intro__image__con {
    padding: 0 12px 12px;
  }
}

.home__countries__card {
  max-width: 344px;
  background-color: #f1f1f2;
  border-radius: 8px;
  margin: 0 auto 120px;
  padding: 12px;
}

@media (max-width: 768px) {
  .home__countries__card {
    max-width: unset;
    background: none;
    border-top: 1px solid #0000000d;
    margin-bottom: 64px;
    padding-top: 12px;
  }
}

.home__countries__card__title {
  color: #74747c;
  margin-bottom: 16px;
  padding: 0 4px;
}

.home__countries__card__images {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  position: absolute;
}

.home__countries__card__images__con {
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(320 / 240)) {
  .home__countries__card__images__con:after {
    content: "";
    width: 100%;
    padding-top: 75%;
    display: inline-block;
  }
}

.home__countries__card__images__con img, .home__countries__card__images__con video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 769px) {
  .home__countries__card__images__con {
    padding-top: 0;
  }
}

.home__countries__card__dots {
  z-index: 2;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

@supports not (gap: 4px 4px) {
  .home__countries__card__dots {
    --row-gap: 4px;
    --column-gap: 4px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .home__countries__card__dots > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.home__countries__card__dots .c-dot {
  width: 8px;
  height: 8px;
  background: #ffffff59;
  border-radius: 50%;
}

.home__countries__card__dots .c-dot--active {
  background: #fff;
}

.home__countries__card .c-country {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home__countries__card .c-country__timearea {
  z-index: 1;
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.home__countries__card .c-country__time, .home__countries__card .c-country__name {
  color: #fff;
}

.home__countries__card .c-country__gradient {
  height: 160px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(#74747c00 0%, #00001080 100%);
  position: absolute;
  bottom: 0;
}

.home__collaborators {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.home__collaborators__con {
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .home__collaborators__con {
    margin-bottom: 64px;
  }
}

.home__collaborators__featured {
  width: 100%;
  justify-content: center;
  gap: 9.6rem;
  display: inline-flex;
}

@supports not (gap: 9.6rem 9.6rem) {
  .home__collaborators__featured {
    --row-gap: 9.6rem;
    --column-gap: 9.6rem;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .home__collaborators__featured > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (max-width: 768px) {
  .home__collaborators__featured {
    flex-direction: column;
    gap: 48px;
    display: inline-flex;
  }

  @supports not (gap: 48px 48px) {
    .home__collaborators__featured {
      --row-gap: 48px;
      --column-gap: 48px;
      margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
      width: calc(100% + var(--column-gap) );
    }

    .home__collaborators__featured > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

.home__collaborators:not(:last-of-type) {
  margin-bottom: 96px;
}

@media (max-width: 768px) {
  .home__collaborators:not(:last-of-type) {
    margin-bottom: 48px;
  }
}

.home__collaborators .c-collabcard:not(:last-of-type) {
  margin-bottom: 96px;
}

@media (max-width: 768px) {
  .home__collaborators .c-collabcard:not(:last-of-type) {
    margin-bottom: 48px;
  }
}

@media (min-width: 769px) {
  .home__collaborators .c-collabcard:nth-child(2n) {
    margin-left: 200px;
  }

  .home__collaborators .c-collabcard:nth-child(3n) {
    margin-right: 200px;
  }
}

.home__credits {
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .home__credits {
    margin-bottom: 64px;
  }
}

.home__credits__inner {
  margin-top: 48px;
}

@media (min-width: 769px) {
  .home__credits__inner {
    text-align: center;
    max-width: 560px;
    margin: 0 auto;
  }
}

.home__credits__title {
  margin-bottom: 64px;
}

@media (max-width: 768px) {
  .home__credits__title {
    margin-bottom: 48px;
  }
}

.home__credits__label {
  color: #74747c;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.home__credits__desc p:first-of-type {
  margin: 0;
}

.home__credits__desc p:not(:first-of-type) {
  margin-top: revert;
}

.home__credits__desc p:last-of-type {
  margin-bottom: 0;
}

.home__final {
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .home__final {
    margin-bottom: 64px;
  }
}

.home__final__title {
  margin-bottom: 48px;
}

@media (min-width: 769px) {
  .home__final__title {
    text-align: center;
    max-width: 761px;
    margin: 0 auto 64px;
  }
}

.home__final__cards {
  flex-direction: column-reverse;
  gap: 48px;
  display: inline-flex;
}

@supports not (gap: 48px 48px) {
  .home__final__cards {
    --row-gap: 48px;
    --column-gap: 48px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .home__final__cards > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (min-width: 769px) {
  .home__final__cards {
    width: 100%;
    flex-flow: wrap;
    justify-content: center;
    gap: 24px;
    display: inline-flex;
  }

  @supports not (gap: 24px 24px) {
    .home__final__cards {
      --row-gap: 24px;
      --column-gap: 24px;
      margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
      width: calc(100% + var(--column-gap) );
    }

    .home__final__cards > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

.home__final__card {
  text-align: left;
}

@media (min-width: 769px) {
  .home__final__card {
    max-width: 516px;
    width: 100%;
    background: #f1f1f2;
    border-radius: 12px;
    padding: 32px 16px 16px;
  }
}

.home__final__card__desc {
  color: #74747c;
}

.home__final__card__title {
  margin-bottom: 8px;
}

@media (min-width: 769px) {
  .home__final__card .c-button {
    width: 100%;
    border: 1px solid #0000000d;
    justify-content: space-between;
  }
}

.home__final__card__top {
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 0 16px;
  display: flex;
}

@media (max-width: 768px) {
  .home__final__card__top {
    flex-direction: column-reverse;
    margin-bottom: 16px;
    padding: 0;
  }
}

@media (min-width: 769px) {
  .home__final__card__top__left {
    max-width: 264px;
  }
}

.home__final__card__top__video {
  max-width: 120px;
  width: 100%;
  border-radius: 4px;
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .home__final__card__top__video:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.home__final__card__top__video img, .home__final__card__top__video video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .home__final__card__top__video {
    margin-bottom: 16px;
  }
}

.feed {
  height: 100vh;
}

.feed .c-section {
  width: 100%;
}

.feed .c-section:not(.full-section) {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 769px) {
  .feed .c-section:not(.full-section) {
    max-width: calc(100% - 88px);
    margin-left: auto;
    padding-left: 0;
    padding-right: 24px;
  }
}

.feed .c-section:not(.full-section) .c-section__inner {
  width: 100%;
}

.feed__inner {
  margin-top: 82px;
  position: relative;
}

@media (min-width: 769px) {
  .feed__inner {
    margin-top: 0;
    display: flex;
  }
}

.feed__inner h2 {
  position: sticky;
  top: 0;
}

.feed__content {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  padding: 24px 0;
  display: flex;
}

@media (min-width: 769px) {
  .feed__content {
    padding: 0;
  }
}

.feed__content .c-header__scroll {
  color: #fff;
}

@media (max-width: 768px) {
  .feed__content .c-section .c-header.desktop {
    visibility: hidden;
    display: none;
  }
}

@media (min-width: 769px) {
  .feed__content .c-section .c-header.mobile {
    visibility: hidden;
    display: none;
  }
}

.feed__content .c-section-welcome-mobile {
  color: #000010a6;
  text-align: center;
  margin: 48px auto 0;
  padding: 0 48px;
}

@media (min-width: 769px) {
  .feed__content .c-section-welcome-mobile {
    visibility: hidden;
    display: none;
  }
}

.feed__content footer {
  margin-top: 64px;
}

@media (min-width: 769px) {
  .feed__content footer {
    margin-top: auto;
  }
}

.feed__content footer .desktop {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  display: flex;
}

@media (max-width: 768px) {
  .feed__content footer .desktop {
    visibility: hidden;
    display: none;
  }
}

.feed__content footer .desktop .year-in-review {
  color: #fff;
  border-color: #ffffff40;
}

.feed__content footer .desktop .share-page {
  color: #fff;
  width: max-content;
  height: fit-content;
  border-color: #ffffff40;
}

.feed__content footer .mobile {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 769px) {
  .feed__content footer .mobile {
    visibility: hidden;
    display: none;
  }
}

.feed__content footer .mobile .share-prompt {
  color: #000010a6;
  text-align: center;
  margin-bottom: 24px;
}

.feed__content footer .mobile .share-page {
  margin-bottom: 64px;
}

@media (min-width: 769px) {
  .feed__content footer .curated-by-wrapper .c-button {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .feed__content footer .curated-by-wrapper {
    width: 100%;
    padding: 0 52px;
  }
}

.feed__content footer .curated-by {
  text-align: center;
  color: #ffffffbf;
  border: 1px solid #ffffff40;
  border-radius: 12px;
  margin-bottom: 4px;
  padding: 16px;
}

@media (max-width: 768px) {
  .feed__content footer .curated-by {
    color: #000010a6;
    width: 100%;
    border-color: #0000000d;
    margin-bottom: 64px;
  }
}

.feed__content footer .curated-by-icon {
  width: 128px;
  height: 48px;
  margin: 8px 0;
}

.feed__content footer .back-to-top {
  width: 100%;
  border-top: 1px solid #0000000d;
  justify-content: center;
  padding: 24px 0;
  display: flex;
}

.month .c-section {
  width: 100%;
}

.month .c-section:not(.full-section) {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 769px) {
  .month .c-section:not(.full-section) {
    max-width: calc(100% - 88px);
    margin-left: auto;
    padding-left: 0;
    padding-right: 24px;
  }
}

.month .c-section:not(.full-section) .c-section__inner {
  width: 100%;
}

.month__inner {
  margin-top: 82px;
  position: relative;
}

@media (min-width: 769px) {
  .month__inner {
    margin-top: 0;
    display: flex;
  }
}

.month__content {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.month__content footer {
  margin-top: auto;
}

.month__hero__inner {
  width: 100%;
  justify-content: center;
  margin-top: 180px;
  margin-bottom: 240px;
  display: flex;
}

@media (max-width: 768px) {
  .month__hero__inner {
    margin-top: 48px;
    margin-bottom: 64px;
  }
}

.month__hero__title {
  filter: blur(2px);
}

@media (max-width: 768px) {
  .month__hero__title {
    filter: blur(1px);
  }
}

@media (min-width: 769px) {
  .month__journal__inner {
    min-height: var(--height);
  }
}

.month__journal .c-section__inner {
  max-width: 1328px;
  margin-left: auto;
  margin-right: auto;
}

.month__journal__images {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.month__journal__dots {
  z-index: 2;
  gap: 4px;
  display: inline-flex;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
}

@supports not (gap: 4px 4px) {
  .month__journal__dots {
    --row-gap: 4px;
    --column-gap: 4px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .month__journal__dots > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

.month__journal__dots .c-dot {
  width: 8px;
  height: 8px;
  background: #ffffff59;
  border-radius: 50%;
}

.month__journal__dots .c-dot--active {
  background: #fff;
}

.month__journal__media {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.month__journal__media__gradient {
  height: 72px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(#74747c00 0%, #74747c80 100%);
  position: absolute;
  bottom: 0;
}

.month__journal__card {
  width: 100%;
  padding: 12px;
}

@media (max-width: 1050px) {
  .month__journal__card {
    border-top: 1px solid #0000000d;
    padding: 12px 0 0;
  }

  .month__journal__card:not(:last-of-type) {
    margin-bottom: 48px;
  }
}

.month__journal__card--square .month__journal__images__con, .month__journal__card--square-carousel .month__journal__images__con {
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .month__journal__card--square .month__journal__images__con:after, .month__journal__card--square-carousel .month__journal__images__con:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.month__journal__card--square .month__journal__images__con img, .month__journal__card--square .month__journal__images__con video, .month__journal__card--square-carousel .month__journal__images__con img, .month__journal__card--square-carousel .month__journal__images__con video {
  position: absolute;
  top: 0;
  left: 0;
}

.month__journal__card--rectangle .month__journal__images__con, .month__journal__card--rectangle-carousel .month__journal__images__con {
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(480 / 360)) {
  .month__journal__card--rectangle .month__journal__images__con:after, .month__journal__card--rectangle-carousel .month__journal__images__con:after {
    content: "";
    width: 100%;
    padding-top: 75%;
    display: inline-block;
  }
}

.month__journal__card--rectangle .month__journal__images__con img, .month__journal__card--rectangle .month__journal__images__con video, .month__journal__card--rectangle-carousel .month__journal__images__con img, .month__journal__card--rectangle-carousel .month__journal__images__con video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 1051px) {
  .month__journal__card {
    top: var(--top-spacing);
    background: #f1f1f2;
    border-radius: 8px;
    position: absolute;
  }

  .month__journal__card--left {
    left: var(--horizontal-spacing);
  }

  .month__journal__card--right {
    right: var(--horizontal-spacing);
  }

  .month__journal__card--center {
    left: 50%;
    transform: translateX(-50%);
  }

  .month__journal__card--square, .month__journal__card--square-carousel {
    max-width: 384px;
  }

  .month__journal__card--rectangle, .month__journal__card--rectangle-carousel {
    max-width: 504px;
  }
}

@media (max-width: 1250px) {
  .month__journal__card--left {
    left: 24px;
  }

  .month__journal__card--right {
    right: 24px;
  }
}

.month__journal__card__top {
  margin-bottom: 16px;
  padding: 0 4px;
}

.month__journal__card__label {
  color: #74747c;
  margin-bottom: 12px;
}

.month__journal__card__title {
  margin-bottom: 12px;
}

.month__journal__card__desc {
  color: #74747c;
}

.month__journal__card__desc p:first-of-type {
  margin: 0;
}

.month__journal__card__desc p:not(:first-of-type) {
  margin-top: revert;
}

.month__journal__card__desc p:last-of-type {
  margin-bottom: 0;
}

.month__credits {
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .month__credits {
    margin-bottom: 48px;
  }
}

.month__credits__inner {
  margin-top: 48px;
}

@media (min-width: 769px) {
  .month__credits__inner {
    text-align: center;
    max-width: 560px;
    margin: 64px auto 0;
  }
}

.month__credits__label {
  color: #74747c;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.month__credits__desc p:first-of-type {
  margin: 0;
}

.month__credits__desc p:not(:first-of-type) {
  margin-top: revert;
}

.month__credits__desc p:last-of-type {
  margin-bottom: 0;
}

.month__lyrics {
  margin-bottom: 240px;
}

@media (max-width: 768px) {
  .month__lyrics {
    margin-bottom: 64px;
  }
}

.month__lyrics__inner {
  max-width: 1184px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .month__lyrics__inner {
    border-top: 1px solid #0000000d;
    flex-direction: column-reverse;
    gap: 16px;
    margin: 0;
    padding-top: 48px;
    display: inline-flex;
  }

  @supports not (gap: 16px 16px) {
    .month__lyrics__inner {
      --row-gap: 16px;
      --column-gap: 16px;
      margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
      width: calc(100% + var(--column-gap) );
    }

    .month__lyrics__inner > * {
      margin: var(--row-gap) 0 0 var(--column-gap);
    }
  }
}

@media (min-width: 769px) {
  .month__lyrics__card {
    float: left;
    margin-top: 7px;
    margin-right: 24px;
  }
}

.notfound .c-section {
  width: 100%;
}

.notfound .c-section:not(.full-section) {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 769px) {
  .notfound .c-section:not(.full-section) {
    max-width: calc(100% - 88px);
    margin-left: auto;
    padding-left: 0;
    padding-right: 24px;
  }
}

.notfound .c-section:not(.full-section) .c-section__inner {
  width: 100%;
}

.notfound__inner {
  margin-top: 82px;
  position: relative;
}

@media (min-width: 769px) {
  .notfound__inner {
    margin-top: 0;
    display: flex;
  }
}

.notfound__content {
  width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.notfound__content footer {
  margin-top: auto;
}

.notfound__body {
  margin-top: 116px;
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .notfound__body {
    margin-top: 48px;
  }
}

.notfound__body__inner {
  max-width: 240px;
  text-align: center;
  margin: 0 auto;
}

.notfound__emojis {
  margin-bottom: 16px;
}

.notfound__desc {
  margin-bottom: 24px;
}

.notfound__title {
  margin-bottom: 16px;
}

.outro .c-section {
  width: 100%;
}

.outro .c-section:not(.full-section) {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 769px) {
  .outro .c-section:not(.full-section) {
    max-width: calc(100% - 88px);
    margin-left: auto;
    padding-left: 0;
    padding-right: 24px;
  }
}

.outro .c-section:not(.full-section) .c-section__inner {
  width: 100%;
}

.outro__inner {
  margin-top: 82px;
  position: relative;
}

@media (min-width: 769px) {
  .outro__inner {
    margin-top: 0;
    display: flex;
  }
}

.outro__content {
  width: 100vw;
}

.outro__content footer {
  margin-top: auto;
}

@media (min-width: 769px) {
  .outro__header {
    width: 100%;
    max-width: calc(100% - 112px);
    z-index: 1;
    position: absolute;
    right: 24px;
  }

  .outro__header .c-header__title {
    border-color: #ffffff59;
  }

  .outro__header .c-header__title, .outro__header .c-header__scroll {
    color: #ffffffbf;
  }

  .outro__header .c-header path:first-of-type {
    fill: #ffffffbf;
  }

  .outro__header .c-header path:last-of-type {
    fill: #fff;
  }
}

.outro__hero {
  width: 100%;
  margin-bottom: 32px;
  position: relative;
}

@media (max-width: 768px) {
  .outro__hero {
    margin-top: 48px;
    margin-bottom: 48px;
    padding: 0 12px;
  }
}

.outro__hero__video {
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1440 / 1600)) {
  .outro__hero__video:after {
    content: "";
    width: 100%;
    padding-top: 111.111%;
    display: inline-block;
  }
}

.outro__hero__video img, .outro__hero__video video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .outro__hero__video {
    border-radius: 12px;
    overflow: hidden;
  }
}

.outro__hero__video video {
  z-index: -1;
}

@media (min-width: 769px) {
  .outro__hero__content {
    max-width: calc(100% - 112px);
    width: 100%;
    position: absolute;
    top: 240px;
    right: 24px;
  }
}

.outro__hero__title {
  max-width: 535px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .outro__hero__title {
    max-width: 204px;
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.outro__hero__description {
  margin-top: 24px;
  padding: 0 12px;
}

@media (min-width: 769px) {
  .outro__hero__description {
    max-width: 560px;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    padding: 0;
  }
}

.outro__faqs .c-section__inner {
  max-width: 768px;
  margin: 120px auto;
}

@media (max-width: 768px) {
  .outro__faqs .c-section__inner {
    margin: 48px auto;
    padding: 0 4px;
  }
}

.outro__faqs__title {
  text-align: center;
  margin-bottom: 48px;
}

@media (max-width: 768px) {
  .outro__faqs__title {
    text-align: left;
    margin-bottom: 32px;
    padding: 0 16px;
  }
}

.outro__contact .c-section__inner {
  max-width: 1328px;
  margin: 244px auto 80px;
  position: relative;
}

@media (max-width: 768px) {
  .outro__contact .c-section__inner {
    margin-top: 160px;
    margin-bottom: 64px;
  }
}

.outro__contact__link {
  overflow: revert;
}

.outro__contact__title {
  text-align: center;
}

.outro__contact__title span {
  animation: colorChange 1.5s step-start infinite;
}

@keyframes colorChange {
  0% {
    color: red;
  }

  20% {
    color: #ff0;
  }

  40% {
    color: #0f0;
  }

  60% {
    color: #0ff;
  }

  80% {
    color: #00f;
  }

  100% {
    color: #f0f;
  }
}

.outro__contact__butterfly {
  pointer-events: none;
  z-index: 1;
  position: absolute;
}

.outro__contact__butterfly--left {
  top: 126px;
  left: 63px;
  transform: rotate(18.6deg);
}

@media (max-width: 1200px) {
  .outro__contact__butterfly--left {
    top: -120px;
    left: 4px;
    transform: rotate(19.61deg);
  }
}

.outro__contact__butterfly--right {
  top: 190px;
  right: 50px;
  transform: rotate(-28.75deg);
}

@media (max-width: 1200px) {
  .outro__contact__butterfly--right {
    display: none;
  }
}

.outro__contact__butterfly--right .wing__inner {
  animation-delay: 1s !important;
}

.outro__contact__phone {
  max-width: 652px;
  width: 100%;
  pointer-events: none;
  z-index: 1;
  position: absolute;
  bottom: -226px;
  left: -5px;
  transform: rotate(-5deg);
}

.outro__contact__phone__inner {
  font-size: 0;
  position: relative;
  overflow: hidden;
}

@supports (aspect-ratio: calc(1 / 1)) {
  .outro__contact__phone__inner:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: inline-block;
  }
}

.outro__contact__phone__inner img, .outro__contact__phone__inner video {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1200px) {
  .outro__contact__phone {
    display: none;
  }
}

@media (max-width: 768px) {
  .outro__contact__link {
    letter-spacing: -.8px;
    font-size: 32px;
  }
}

.outro__contact__link:hover + .outro__contact__phone .outro__contact__phone__inner {
  backface-visibility: hidden;
  perspective: 1000px;
  animation: shake .82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
}

.outro__contact__link__con {
  max-width: 582px;
  text-align: center;
  background: #f1f1f2;
  border-radius: 8px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: 21.6rem;
  padding: 24px 32px 32px;
}

@media (max-width: 1200px) {
  .outro__contact__link__con {
    max-width: unset;
    margin-top: 32px;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .outro__contact__link__con {
    padding: 16px 16px 24px;
  }
}

.outro__contact__link__top {
  border-bottom: 1px solid #0000000d;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-bottom: 24px;
  display: flex;
}

@media (max-width: 768px) {
  .outro__contact__link__top {
    margin-bottom: 12px;
    padding-bottom: 12px;
    display: block;
  }
}

@media (max-width: 768px) {
  .outro__contact__link__label {
    margin-bottom: 4px;
  }
}

@media (max-width: 768px) {
  .outro__contact__link__desc {
    color: #74747c;
    font-weight: 500;
    line-height: 1.35;
  }
}

.outro .m-playlist {
  margin-bottom: 64px;
  position: relative;
}

@media (max-width: 768px) {
  .outro .m-playlist {
    margin-bottom: 96px;
  }
}

.outro .m-playlist__butterfly {
  pointer-events: none;
  z-index: 1;
  position: absolute;
}

.outro .m-playlist__butterfly--top {
  top: -115px;
  right: 130px;
  transform: rotate(-21.39deg);
}

@media (max-width: 768px) {
  .outro .m-playlist__butterfly--top {
    top: -44px;
    left: 84px;
    right: unset;
    transform: rotate(-5.56deg);
  }
}

.outro .m-playlist__butterfly--bottom {
  bottom: -150px;
  left: 167px;
  transform: rotate(19.5deg);
}

@media (max-width: 768px) {
  .outro .m-playlist__butterfly--bottom {
    bottom: -60px;
    right: 14px;
    left: unset;
    transform: rotate(-5.56deg);
  }
}

.outro .m-playlist__butterfly--bottom .wing__inner {
  animation-delay: 1s !important;
}

.outro__dump {
  margin-bottom: 32px;
  position: relative;
}

.outro__dump__label {
  text-align: center;
  color: #74747c;
  margin-bottom: 16px;
}

.outro__dump__butterfly {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 24px;
  transform: rotate(-35.01deg);
}

@media (max-width: 768px) {
  .outro__dump__butterfly {
    display: none;
  }
}

.outro__dump__emojis {
  text-align: center;
  margin-bottom: 64px;
}

.outro__dump__players {
  gap: 16px;
  margin-bottom: 16px;
  display: flex;
}

@supports not (gap: 16px 16px) {
  .outro__dump__players {
    --row-gap: 16px;
    --column-gap: 16px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .outro__dump__players > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (max-width: 1200px) {
  .outro__dump__players {
    flex-direction: column;
  }
}

.outro__dump__embeds {
  gap: 16px;
  display: flex;
}

@supports not (gap: 16px 16px) {
  .outro__dump__embeds {
    --row-gap: 16px;
    --column-gap: 16px;
    margin: calc(-1 * var(--row-gap) ) 0 0 calc(-1 * var(--column-gap) );
    width: calc(100% + var(--column-gap) );
  }

  .outro__dump__embeds > * {
    margin: var(--row-gap) 0 0 var(--column-gap);
  }
}

@media (max-width: 1200px) {
  .outro__dump__embeds {
    flex-direction: column;
  }
}

.outro__dump__embed {
  width: 100%;
  display: flex;
}

/*# sourceMappingURL=index.50a3cd5c.css.map */
