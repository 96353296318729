.feed-card{
  background: $white;
  border: 1px solid $dark-stroke;
  box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625), 2px 16px 24px rgba(0, 0, 16, 0.0625);
  border-radius: 12px;
  position: fixed;

  @include media("<=tablet") {
    display: none;
    visibility: hidden;
  }

  &.is-hidden{
    display: none;
  }

  &--playlist{
    width: 496px;

    &[data-card-id="playlist-info"]{
      top: 49vh;
      left: 34.4vw;
    }

    &[data-card-id="spotify-embed"]{
      left: 112px;
      top: 240px;
    }
  }

  &--playlist-selector{
    width: 296px;
    right: 120px;
    top: 296px;
  }

  &--work-window{
    top: 11.5vh;
    left: 24.5vw;
  }

  &__header{
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    &-text{
      @extend %captions-12;
      color: $dark-grey;
      text-transform: uppercase;
    }

    &-buttons{
      display: flex;
    }

    &-button{
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $dark-stroke;
      border: none;
      cursor: pointer;

      & + &{
        margin-left: 4px;
      }
    }
  }

  &__body{
    padding: 8px;
    border-top: 1px solid $dark-stroke;
    border-bottom: 1px solid $dark-stroke;

    //Variants
    &--no-bottom-border{
      border-bottom: none;
    }

    &--playlist-info{
      padding: 12px 16px 16px;
    }

    &--playlist-selector{
      padding: 32px 16px 16px;
    }

    &--embed{
      height: 368px;
    }

    //General components
    &-text{
      @extend %body-14;
      font-weight: 400;
      color: $dark-grey;

      &--center{
        text-align: center;
        line-height: 1;
      }

      &-title{
        color: $black;
        text-transform: uppercase;
        @extend %captions-12;

        &::after{
          content: " "
        }
      }
    }

    //Playlist info
    &-creative-link{
      padding: 12px;
      border-radius: 12px;
      border: 1px solid $dark-stroke;
      display: flex;
      align-items: center;
      margin: 16px 0;

      &-image{
        width: 64px;
        height: 64px;
        object-fit: cover;
        margin-right: 16px;
      }

      &-name{
        @extend %body-16-bold;
        color: $black;
        margin-bottom: 4px;
      }

      &-description{
        @extend %body-14;
        color: $dark-grey;
      }

      &-external-link{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid $dark-stroke;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        color: $dark-grey;
        margin-left: auto;
      }
    }

    //Playlist selector
    &-playlist-name{
      margin: 8px 0 4px;
      @extend %title-24;
      text-align: center;
    }

    &-selector-controls{
      display: flex;
      align-items: center;
      margin-top: 32px;
    }

    &-next-up{
      margin-right: 8px;
      height: 56px;
      padding: 12px 16px;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      border: 1px solid $dark-stroke;
      color: $dark-grey;
      text-align: left;

      $parent: &;

      &:hover{
        background-color: $dark-stroke;
      }

      &-title{
        @extend %body-14-lh-1;
        margin-bottom: 4px;
        color: $low-black;

        #{$parent}:hover &{
          color: $dark-grey;
        }
      }

      &-name{
        @extend %body-14-bold;
        color: $dark-grey;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 136px;

        #{$parent}:hover &{
          color: $black;
        }
      }
    }

    &-randomize-button{
      border: 1px solid $dark-stroke;
      background-color: transparent;
      height: 56px;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      @extend %emoji-24;

      &:hover{
        background-color: $dark-stroke;
      }
    }

    //work-window
    &-media-wrapper{
      position: relative;
      color: $white;
      cursor: pointer;

      &-control-icon{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        width: 160px;
        height: 160px;
      }

      &.is-active[data-play-state="paused"]:hover .play-icon{
        display: block;
      }

      &.is-active[data-play-state="playing"]:hover .pause-icon{
        display: block;
      }
    }

    &-media{
      border-radius: 4px;
      display: none;

      .is-active &{
        display: block;
      }
    }
  }

  &__footer{
    height: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    color: $dark-grey;
    cursor: pointer;

    &--is-hidden{
      display: none;
    }

    &--tall{
      height: 48px;
      padding: 8px;
    }

    &-text{
      @extend %body-14-lh-1;
    }

    .c-button{
      width: 100%;
    }

    &:hover{
      color: $black;
    }
  }
}