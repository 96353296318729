html {
  box-sizing: border-box;
  font-size: calc(100vw / 1440 * 10);
  width: 100%;

  @include media(">desktop") {
    font-size: 10px;
  }
}

html,
body {
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  height: 100%;
  font-family: "Suisse", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.35;
  background: $white;
  color: $black;
  font-feature-settings: "case" on;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
    position: fixed;
    width: 100%;
  }
}
.pages {
  width: 100%;
  height: 100%;

  @include media(">tablet") {
    // transition: transform 1s ease-out;
    overflow: hidden;
  }

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
    position: fixed;
    width: 100%;
    top: 0;
  }

  &--hidden {
    @include media(">tablet") {
      .pages__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    @include media("<=tablet") {
      overflow: hidden;
      max-height: 100vh;
      position: fixed;
      width: 100%;
    }
  }
}

.dg.ac {
  z-index: 10 !important;
}

.bold {
  font-weight: 700;
}

button {
  cursor: pointer;
}

[data-marquee],
[data-auto-marquee] {
  width: fit-content;
  position: relative;
  will-change: transform;
  display: flex;

  &.active {
    animation: marquee 25s linear infinite;
    animation-play-state: running;
  }

  [data-marquee-item] {
    white-space: nowrap;

    &:not(:first-of-type) {
      margin: 0 12px;
    }
  }

  @keyframes marquee {
    100% {
      transform: translate3d(-50%, 0, 0);
    }
  }
}

[data-journal] {
  @include media(">tablet") {
    background-image: repeating-linear-gradient(
      white 0px,
      white 80px,
      #ececec 81px
    );
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #ececec;
    }
  }
}

[data-carousel] {
  @extend %ovh;
  z-index: z("1");

  [data-carousel-item] {
    will-change: transform;
  }

  &.transition {
    [data-carousel-item] {
      transition: transform 0.5s linear;
    }
  }

  [data-carousel-indicator] {
    transition: background 0.3s ease-out;
    &.active {
      background: $white;
    }
  }

  [data-click-left] {
    position: absolute;
    left: 0;
    width: 50%;
    z-index: z("2");
    height: 100%;
    cursor: w-resize;
  }

  [data-click-right] {
    position: absolute;
    right: 0;
    width: 50%;
    z-index: z("2");
    height: 100%;
    cursor: e-resize;
  }

  [data-click-left],
  [data-click-right] {
    opacity: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }
}

[data-draggable] {
  [data-drag-header] {
    cursor: grab;
  }

  &.is-pointer-down {
    [data-drag-header] {
      cursor: grabbing;
    }
  }
}

.flash {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinkerHover {
  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0) rotate(0.5deg);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0) rotate(-0.5deg);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0) rotate(0.5deg);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0) rotate(-0.5deg);
  }
}
