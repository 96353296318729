.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z("preloader");
  background: $grey;

  &__inner {
    opacity: 0;
    display: flex;
  }

  @include media("<=tablet") {
    .preloader__inner {
      padding-top: 120px;
      align-items: center;
      justify-content: center;
      @include flex-gap(72px);
      flex-direction: column;
      width: 100%;
    }

    .c-dialog__header {
      pointer-events: none;
    }
  }

  &__background {
    @extend %element-cover;

    background-image: repeating-linear-gradient(
      $grey 0px,
      $grey 80px,
      $dark-stroke 81px
    );
  }

  .c-dialog {
    &--loader {
      max-width: 324px;
      width: 100%;

      @include media(">tablet") {
        position: absolute;
        top: 22vh;
        left: toRem(356);
      }
    }

    &--info {
      max-width: 296px;
      width: 100%;
      @include media(">tablet") {
        position: absolute;
        bottom: 29vh;
        right: toRem(391);
      }
    }

    &__inner {
      max-width: unset;
    }

    &__content {
      padding: 16px;
    }

    &__pads {
      border-radius: 8px;
      background: $grey;
      padding: 8px;
      display: flex;
      @include flex-gap(4px);
      margin-bottom: 16px;
    }

    &__number {
      @extend %body-14-lh-1;
      color: $dark-grey;
      text-align: right;
    }

    &__desc {
      @extend %body-14;
      color: $dark-grey;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .desktop {
        @extend %desktop-tab;
      }

      .mobile {
        @extend %tablet;
      }
    }

    &__pad {
      background: $dark-stroke;
      width: 24px;
      height: 24px;
      border-radius: 4px;

      &.active {
        background: $dark-grey;
      }
    }
  }

  .c-stickynote {
    width: 192px;
    height: 192px;
    padding: 16px;
    box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625),
      2px 16px 24px rgba(0, 0, 16, 0.0625);
    background: $white;
    position: absolute;
    @extend %title-32-pixel;

    &__content {
      color: $dark-grey;
    }

    @include media("<=tablet") {
      display: none;
    }

    &--left {
      transform: rotate(10.85deg);
      left: toRem(201);
      bottom: 9.88vh;
    }

    &--right {
      transform: rotate(-5.41deg);
      right: toRem(202);
      top: 10.6vh;
    }
  }
}
