@font-face {
  font-family: "Suisse";
  src: url("../fonts/SuisseIntl-Book.woff2") format("woff2"),
    url("../fonts/SuisseIntl-Book.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Suisse";
  src: url("../fonts/SuisseIntl-SemiBold.woff2") format("woff2"),
    url("../fonts/SuisseIntl-SemiBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PP Neue";
  src: url("../fonts/PPNeueBit-Bold.woff2") format("woff2"),
    url("../fonts/PPNeueBit-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fraunces";
  src: url("../fonts/Fraunces-Italic-VariableFont.woff2") format("woff2"),
    url("../fonts/Fraunces-Italic-VariableFont.woff") format("woff"),
    url("../fonts/Fraunces-Italic-VariableFont.woff2")
      format("woff2-variations");
  font-weight: 100 1000;
  font-display: swap;
}

@font-face {
  font-family: "Adjusted Arial Fallback";
  src: local(Arial);
  size-adjust: 100%;
  ascent-override: normal;
  descent-override: normal;
  line-gap-override: normal;
}
