// featured lyrics

.c-feat {
  &-ly {
    border-radius: 8px;
    height: 88px;
    background: $grey;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    width: 400px;

    @include media("<=tablet") {
      width: 100%;
    }

    &__left {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__right {
      flex-shrink: 0;
    }

    &__label {
      margin-bottom: 8px;
      @extend %body-14-bold;
      color: $dark-grey;
    }

    &__info {
      width: 100%;
    }

    &__image {
      border-radius: 4px;
      @include ratio(1, 1);
      @extend %ovh;

      &__con {
        max-width: 64px;
        width: 100%;
        margin-right: 16px;
        flex-shrink: 0;
      }
    }

    &__title {
      margin-bottom: 4px;
      @extend %body-16-bold;
      width: calc(100% - 40px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__artist {
      @extend %body-14-lh-1;
      color: $dark-grey;
    }

    &__bottom {
      .c-button {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &-pl {
    max-width: 384px;
    width: 100%;

    @include media("<=tablet") {
      max-width: 264px;
    }

    &__top {
      border-radius: 8px;
      margin-bottom: 4px;
      @extend %ovh;

      &__inner {
        padding-top: 16px;
        padding-bottom: 12px;
        background: $grey;
      }

      &__info {
        background: $grey;
        padding: 16px 0;
      }
    }

    &__label {
      padding: 0 16px;
      @extend %body-14-bold;
      color: $dark-grey;
    }

    &__image {
      border-radius: 4px;
      @extend %ovh;
      @include ratio(1, 1);
      box-shadow: 0 0 0 4px $grey;

      &__con {
        border-left: 12px solid $grey;
        border-right: 12px solid $grey;
      }
    }

    &__title {
      margin-bottom: 8px;
      @extend %title-24;
      padding: 0 16px;
    }

    &__details {
      @extend %body-14-lh-1;
      color: $dark-grey;
      padding: 0 16px;
    }

    &__bottom {
      .c-button {
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &-artist {
    max-width: 256px;
    width: 100%;
    position: fixed;
    top: 185px;
    left: 188px;
    z-index: z("2");
    --progress: 0;

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: $dark-stroke;
      width: 100%;
      transform-origin: left;
      transform: scaleX(var(--progress));
      transition: transform 0.2s linear;
    }

    &__body {
      position: relative;
      padding-top: 8px;
    }

    &:not(.active) {
      .c-feat-artist__progress {
        opacity: 0;
      }
    }

    &__card {
      @extend .c-dialog__inner;
      overflow: hidden;
    }

    @include media("<tablet") {
      display: none;
    }

    .pause {
      display: none;
    }

    &.playing {
      .pause {
        display: block;
      }

      .play {
        display: none;
      }
    }

    &__hidden {
      display: none;
    }

    &__header {
      @extend .c-dialog__header;

      &__dots {
        @extend .c-dialog__header__dots;
      }

      &__dot {
        @extend .c-dialog__header__dot;
      }
    }

    &__image {
      @include ratio(1, 1);
      border-radius: 4px;
      box-shadow: inset 0px 0px 8px rgba(0, 0, 16, 0.125);
      position: relative;

      &__icon {
        position: absolute;
        right: 12px;
        bottom: 12px;
        width: 21px;
        height: 21px;
        color: $white;
        z-index: z("1");
        @extend %svg-full;
      }

      &__con {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &__name {
      @extend %body-16-bold;
      padding: 12px 16px;
      color: $black;
      position: relative;
    }

    &__bottom {
      border-top: $border-dark;
      display: flex;
      align-items: center;
    }

    &__icons {
      padding: 11px;
      border-right: $border-dark;
      flex-shrink: 0;

      @include cursor {
        &:hover {
          transform: scale(1.05);
        }
      }

      &__inner {
        display: grid;
        width: 20px;
        height: 20px;
        @extend %svg-full;

        > span {
          grid-area: 1/1;
          width: 20px;
          height: 20px;
        }
      }
    }

    &__song {
      &__wrapper {
        width: 100%;
        overflow: hidden;
        max-width: 183px;
        margin-left: 10px;
        margin-right: 12px;
        padding-left: 4px;
        position: relative;
        display: grid;

        > div {
          grid-area: 1/1;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 100%;
          background: linear-gradient(
            270deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
          transform: rotate(180deg);
          z-index: z("1");
        }

        &::after {
          left: auto;
          right: 0;
          transform: rotate(0deg);
        }
      }
    }
  }
}

.m-playlist {
  position: relative;
  height: 800px;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media(">tablet") {
    height: 100vh;
    padding-left: 88px;
    padding-right: 24px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1016px;

    @include media(">tablet") {
      height: calc(100vh + 150px);
    }

    &__con {
      height: 100%;
      @extend %element-cover;
      overflow: hidden;
    }

    img {
      @extend %img;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  }
}
