.playlist-catalogue-wrapper{
  position: fixed;
  width: 1126px;
  top: 50%;
  left: 50%;
  z-index: 15;
  transform: translate(-50%, -50%);
  height: 88.5%;
  pointer-events: none;

  @include media("<=tablet") {
    display: none;
    visibility: hidden;
  }

  &.is-active{
    pointer-events: auto;
  }

  &-butterfly{
    position: absolute;
    opacity: 0;
    transform: scale(0);
    transition-timing-function: cubic-bezier(0.24, 0.6, 0.25, 1);

    &--left{
      position: absolute;
      left: -109px;
      top: 105px;
      transform: rotate(15deg);
    }

    &--right{
      width: 224px;
      left: 93%;
      top: 60%;
      transform: rotate(-29.04deg);

      .wing__inner {
        animation-delay: 1s !important;
      }
    }
  }


  .c-button{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 48px;
    opacity: 0;
  }

  .playlist-catalogue{
    background: $medium-white;
    border: 1px solid rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(32px);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    height: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;

    &-header{
      border-bottom: 1px solid $dark-stroke;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      flex-shrink: 0;

      &-text{
        @extend %captions-12;
        color: $dark-grey;
        text-transform: uppercase;
      }

      &-buttons{
        display: flex;
        @include flex-gap(0, 4px)
      }

      &-button{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $dark-stroke;
        border: none;
        cursor: pointer;
      }
    }

    &-body{
      padding: 0 32px;
      flex-grow: 1;
      overflow: auto;

      &-welcome{
        padding: 48px 0;
        flex-shrink: 0;

        &-text{
          @extend %body-14;
          text-align: center;
          color: $medium-black;
        }
      }

      &-playlists-intro{
        border: 1px solid $dark-stroke;
        border-radius: 12px;
        padding: 40px 0;
        text-align: center;
        width: 100%;
        margin-bottom: 16px;

        &--creative{
          opacity: 0;
        }

        &-title{
          @extend %title-24;
          color: $black;
          margin-bottom: 8px;
        }

        &-description{
          @extend %body-14;
          color: $medium-black;
        }
      }

      &-playlists-grid{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 16px 12px;
        margin-bottom: 16px;

        &--large-margin{
          margin-bottom: 32px;
        }
      }

      &-creative-playlist{
        padding: 8px 8px 16px;
        background-color: $low-white;
        border-radius: 8px;
        cursor: pointer;

        &:hover{
          background-color: $medium-white;
        }

        &-image-wrapper{
          position: relative;
        }

        @keyframes bitmojiHover {
          0%{
            transform: translate(-50%, 16px);
          }

          50%{
            transform: translate(-50%, 64px);
          }

          100%{
            transform: translate(-50%, 16px);
          }
        }

        &-bitmoji{
          position: absolute;
          left: 50%;
          transform: translate(-50%, 80px);
          width: 160px;
          height: 160px;
          opacity: 0;
          z-index: 1;

          transition-property: opacity, transform;
          transition-duration: 100ms, 0ms;
          transition-timing-function: linear, ease-in-out;

          animation-delay: 600ms;
          animation-duration: 2s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;

          &.is-animated{
            animation-name: bitmojiHover;
            transform: translate(-50%, 16px);
          }

          &.is-visible{
            opacity: 1;
          }

          &.is-animated.is-visible{
            transition-duration: 100ms, 600ms;
          }
        }

        &-image{
          width: 100%;
          @include ratio(1, 1);
          aspect-ratio: 1;
          object-fit: cover;
          border-radius: 4px;
          margin-bottom: 14px;
        }

        &-content{
          padding: 0 8px;
        }

        &-name{
          @extend %body-16-bold;
          margin-bottom: 4px;
          color: $black;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }

        &-owner{
          @extend %body-14-lh-1;
          color: $medium-black;
          margin-bottom: 12px;
        }

        &-footer{
          display: flex;
          align-items: center;
        }

        &-tags{
          display: flex;
          @include flex-gap(0, 4px)
        }

        &-tag{
          background-color: $low-white;
          padding: 8px;
          border-radius: 4px;
          color: $medium-black;
          @extend %body-14-lh-1;
        }

        &-spotify-link{
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $dark-stroke;
          border-radius: 50%;
          color: $dark-grey;
          margin-left: auto;
        }
      }

      &-null-playlist{
        padding: 16px;
        background-color: $low-white;
        border-radius: 8px;
        cursor: pointer;

        &:hover{
          background-color: $medium-white;
        }

        &-top{
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 14px;
        }

        &-image{
          width: 64px;
          height: 64px;
          border-radius: 4px;
        }

        &-spotify-link{
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $dark-stroke;
          border-radius: 50%;
          color: $dark-grey;
          margin-left: auto;
        }

        &-name{
          @extend %body-16-bold;
          color: $black;
          margin-bottom: 8px;
        }

        &-tags{
          display: flex;
          @include flex-gap(0, 4px)
        }

        &-tag{
          background-color: $low-white;
          padding: 8px;
          border-radius: 4px;
          color: $medium-black;
          @extend %body-14-lh-1;
        }
      }
    }

    &-footer{
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      flex-shrink: 0;
      border-top: 1px solid $dark-stroke;

      &-text{
        @extend %captions-12;
        color: $low-black;
        text-transform: uppercase;
      }
    }
  }
}
