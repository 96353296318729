.feed-bg-image{
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0;
  transition-duration: 500ms;

  &.is-active{
    opacity: 1;
  }

  @include media("<=tablet") {
    display: none;
    visibility: hidden;
  }
}