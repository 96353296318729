.c-page-transition {
  position: fixed;
  z-index: z("transition");
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;

  &.active {
    display: block;
  }

  &__bars {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    &__con {
      @extend %element-cover;
      height: calc(100vh - 160px);
      overflow: hidden;

      @include media("<=tablet") {
        height: calc(100vh - 64px);
      }
    }

    &--top {
      height: calc(100% - 120px);

      @include media("<=tablet") {
        height: calc(100% - 48px);
      }
    }

    &--bottom {
      height: 120px;

      @include media("<=tablet") {
        height: 48px;
      }
    }
  }

  &__bottom {
    @extend %element-cover;
    background: $black;
    overflow: hidden;
    height: 160px;
    top: unset;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 64px;

    @include media("<=tablet") {
      height: 64px;
      justify-content: center;
    }
  }

  &__text {
    color: $white;
    text-transform: uppercase;
    @extend %emoji-24;
    animation: blinker 0.5s step-start infinite;

    @include media("<=tablet") {
      &:not(:first-of-type) {
        display: none;
      }
    }
  }
}
