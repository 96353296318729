.c-nav {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: z("nav");
  width: 100%;
  height: 58px;
  border-bottom: $border-dark;
  left: 0;

  @include media(">tablet") {
    padding-bottom: 24px;
    padding-top: 20px;
    width: 48px;
    height: 100vh;
    flex-direction: column;
    border-bottom: 0;
    left: 20px;
  }

  @include media("<=tablet") {
    background: $white;
  }

  &__left {
    flex-shrink: 0;

    @include media("<=tablet") {
      height: 100%;
    }

    .c-button--icon-only.c-button--large {
      border: $border-dark;
      width: 46px;
      height: 46px;
    }

    @include media(">tablet") {
      margin-bottom: 14px;
    }

    .desktop {
      @extend %desktop-tab;
    }

    .c-button {
      .close {
        display: none;
      }

      &.open {
        .close {
          display: block;
        }

        .open {
          display: none;
        }
      }
    }
  }

  &__toggle {
    width: 56px;
    border-right: $border-dark;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media(">tablet") {
      display: none;
    }

    .close {
      display: none;
    }

    &.open {
      .close {
        display: block;
      }

      .open {
        display: none;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__indicator {
    border: 2px solid rgba(255, 255, 255, 0.35);
    border-radius: 40px;
    @extend %ovh;
    width: 100%;
    height: 32px;
    margin-left: 12px;
    margin-right: 12px;

    @include media(">tablet") {
      height: 100%;
      margin: 0;
      margin-bottom: 12px;
      width: 40px;
    }

    @keyframes pulseGradient {
      0% {
        background-position-y: 0;
      }
      50% {
        background-position-y: 100%;
      }
      100% {
        background-position-y: 0;
      }
    }

    &__inner {
      height: 100%;
      width: 100%;
      background: linear-gradient(
        180deg,
        var(--topColor) 0%,
        var(--bottomColor) 100%
      );
      background-size: 100% 130%;

      @include media(">tablet") {
        &.is-animated {
          animation: pulseGradient 4s ease-in-out infinite;
          background: linear-gradient(
            180deg,
            var(--startColor) 0%,
            var(--endColor) 100%
          );
          background-size: 100% 200%;
        }
      }

      @include media("<=tablet") {
        background: linear-gradient(
          90deg,
          var(--topColor) 0%,
          var(--bottomColor) 100%
        );
        background-size: 130% 100%;
      }
    }
  }

  &__smiley {
    flex-shrink: 0;
    position: relative;

    @include media("<=tablet") {
      border-left: $border-dark;
      padding: 12px;
    }

    &.active {
      .c-toggle {
        opacity: 1;
        pointer-events: auto;
      }

      .c-nav__smiley {
        &__rotate {
          display: none;
        }

        &__arrow {
          display: flex;
        }
      }
    }

    .c-toggle {
      position: absolute;
      bottom: 0;
      right: -204px;
      width: 192px;
      background: $black;
      box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625),
        2px 16px 24px rgba(0, 0, 16, 0.0625);
      border-radius: 12px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s $ease-hero-marquee;

      @include media("<=tablet") {
        right: 12px;
        bottom: -120px;
      }

      &__fullscreen {
        @include media("<=tablet") {
          display: none;
        }
      }

      &__butterfly {
        .c-toggle__label__items {
          color: $white;
          border-radius: 4px;
          padding: 4px 6px;
          @extend %captions-12;
          text-transform: uppercase;
          background: #00009f;
        }
      }

      &__item {
        width: 100%;
        padding: 8px;

        &:not(:first-of-type) {
          border-top: 1px solid $white-strokes;
        }

        &__inner {
          height: 40px;
          position: relative;
          padding: 0 8px;
          width: 100%;

          @include cursor {
            &:hover {
              .c-toggle__label__text {
                color: $white;
              }
            }
          }
        }

        .on,
        .off {
          padding: 4px 6px;
          border-radius: 4px;
          text-transform: uppercase;
          @extend %captions-12;
        }

        .on {
          display: none;
          background: $white;
          color: $black;
        }

        .off {
          background: $white-strokes;
          color: $medium-white;
        }

        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          cursor: pointer;
          position: absolute;
          // outline: 0;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          outline-color: $dark-grey;
          // opacity: 0;
          z-index: z("1");
          height: 100%;
          width: 100%;
          margin: 0;

          &:checked + label {
            .c-toggle__label__items {
              .on {
                display: block;
              }

              .off {
                display: none;
              }
            }
          }
        }
      }

      &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @extend %body-14-lh-1;
        height: 100%;
        width: 100%;

        &__text {
          color: $medium-white;
          transition: color 0.2s ease;
        }

        &__items {
        }
      }
    }

    &__rotate {
      animation: rotate 15s linear infinite;
      transform-origin: center;
      animation-play-state: paused;
      display: flex;

      &.playing {
        animation-play-state: running;
      }
    }

    &__arrow {
      display: none;
    }

    &__inner {
      width: 40px;
      height: 40px;
      display: grid;
      transition: transform 100ms linear;

      @include cursor {
        &:hover {
          transform: scale(1.1);
        }
      }

      > span {
        grid-area: 1/1;
      }

      svg {
        width: 100%;
        height: 100%;
      }

      @include media("<=tablet") {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
