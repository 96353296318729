.idle-feed-state{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  pointer-events: none;

  @include media("<=tablet") {
    display: none;
    visibility: hidden;
  }

  &-image{
    position: absolute;
    top: 50%;
    left: 32.77%;
    opacity: 0;
    transform: scale(0.5) translateY(-50%);
    transition-timing-function: cubic-bezier(0, 0, 0.12, 1.6);
  }

  &-butterfly{
    position: absolute;
    transform: scale(0);
    opacity: 0;
    transition-timing-function: cubic-bezier(0.24, 0.6, 0.25, 1);
  }

  &-dancing-butterfly{
    position: absolute;
    transform: scale(0);
    opacity: 0;
  }

  &-top-left-butterfly{
    left: 19.06%;
    top: 20.12%;
    transform: rotate(26.07deg);
  }

  &-bottom-left-butterfly{
    left: 38%;
    top: 68%;
    transform: rotate(14.03deg);

    .wing__inner {
      animation-delay: 0.5s !important;
    }
  }

  &-top-right-butterfly{
    left: 62.01%;
    top: 20.66%;
    transform: rotate(-18.62deg);

    .wing__inner {
      animation-delay: 1.5s !important;
    }
  }

  &-bottom-right-butterfly{
    left: 76.04%;
    top: 47.21% ;
    transform: rotate(5.02deg);

    .wing__inner {
      animation-delay: 1s !important;
    }
  }

  &-svg{
    position: absolute;
    left: 252px;
    top: 119px;
    opacity: 0;
  }
}