.c-dialog {
  &__inner {
    background: $white;
    border: $border-dark;
    box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625),
      2px 16px 24px rgba(0, 0, 16, 0.0625);
    border-radius: 12px;
    max-width: 296px;
    width: 100%;
    color: $dark-grey;
  }

  &__header {
    padding: 12px;
    padding-bottom: 8px;
    border-bottom: $border-dark;
    @extend %captions-12;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark-grey;

    &__dots {
      display: flex;
      @include flex-gap(4px);
    }

    &__dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $grey;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    padding: 8px;
    @include flex-gap(12px);
    border-bottom: $border-dark;

    &__emoji {
      @extend %title-24;
      grid-area: 1/1;

      &__con {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: $grey;
        overflow: hidden;
        flex-shrink: 0;
        position: relative;
        display: grid;
      }
    }

    &__description {
      @extend %body-14;
    }
  }

  &__footer {
    padding: 8px;

    .c-button {
      width: 100%;
    }
  }

  &--disabled {
    .c-button {
      background: transparent;
      color: $low-black;
      border: $border-dark;
      pointer-events: none;
    }
  }
}
