.c-accordion {
  border-top: $border-dark;
  padding-bottom: 8px;
  padding-top: 8px;

  &:last-child {
    border-bottom: $border-dark;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 16px;

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__title {
    margin-right: 16px;
    @extend %body-16-lh-1;
  }

  &__icon {
    display: grid;

    span {
      grid-area: 1/1;
      width: 16px;
      height: 16px;
      @extend %svg-full;
    }

    &__minus {
      display: none;
    }
  }

  &__body {
    padding: 16px;
    @extend %body-14;
    color: $dark-grey;
    @include multiParagraphs;
  }

  &[open] {
    .c-accordion__icon {
      &__plus {
        display: none;
      }

      &__minus {
        display: inline-block;
      }
    }
  }
}
