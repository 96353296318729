.mobile-playlist-header{
  padding: 0 20px;
  position: relative;
  margin: 64px 0 48px;

  &-butterfly{
    position: absolute;
    width: 124px;

    &-creative-left{
      left: 30px;
      top: -20px;
      transform: rotate(13.09deg);
    }

    &-creative-right{
      transform: rotate(-11.32deg);
      right: 0;
      bottom: -20px;
    }

    &-null-top{
      right: 80px;
      top: -30px;
      transform: rotate(-11.9deg);
    }

    &-null-bottom{
      transform: rotate(12.34deg);
      left: 78px;
      bottom: -24px;
    }
  }

  &__content{
    height: 240px;
    width: 100%;
    border: 1px solid $dark-stroke;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @include media(">tablet") {
      display: none;
      visibility: hidden;
    }

    &-title{
      @extend %title-24;
      color: $black;
      margin-bottom: 8px;
    }

    &-description{
      @extend %body-14-lh-1;
      color: $medium-black;
    }
  }
}
