%perspective {
  transform-style: preserve-3d;
  perspective: 500px;
}

.c-butterfly {
  display: inline-block;
  // @extend %perspective;
  display: flex;
  flex-wrap: nowrap;
  max-width: 224px;
  pointer-events: none;

  @include media("<=tablet") {
    max-width: 124px;
  }

  .wing {
    @extend %perspective;
    transform-origin: center center;
    transform-style: preserve-3d;

    img {
      width: 100%;
      height: auto;
    }

    &--left {
      perspective-origin: right;

      .wing__inner {
        animation: rotateLeftWing 2s infinite $ease-custom alternate;
        transform-origin: right;
      }
    }

    &--right {
      perspective-origin: left;

      .wing__inner {
        transform-origin: left;
        animation: rotateRightWing 2s infinite $ease-custom alternate;
      }
    }
  }
}

@keyframes rotateLeftWing {
  50% {
    transform: rotateY(60deg);
  }
}

@keyframes rotateRightWing {
  50% {
    transform: rotateY(-60deg);
  }
}
