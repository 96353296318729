.home {
  @extend %page;

  &--active {
    @extend %page--active;
  }

  @include pagePadding;

  &__inner {
    position: relative;
    margin-top: 82px;

    @include media(">tablet") {
      display: flex;
      margin-top: 0;
    }

    h2 {
      position: sticky;
      top: 0;
    }
  }

  &__content {
    width: 100vw;
    footer {
      margin-top: auto;
    }
  }

  .c-header {
    z-index: z("2");

    @include media(">tablet") {
      background: $white;
    }
  }

  &__hero {
    --height: 1324px;
    position: relative;
    margin-bottom: 32px;

    @include media("<=tablet") {
      display: none;
    }

    &__dialog {
      position: fixed;
      z-index: z("2");

      &--left {
        top: 100px;
        left: 104px;
      }

      &--bottom {
        bottom: 120px;
        left: 104px;
      }

      &--right {
        top: 149px;
        right: 64px;
      }
    }

    &__outer {
      position: relative;
      width: 100%;
      height: var(--height);
      overflow: hidden;
      transition: all 1.6s $ease-hero-marquee;
    }

    &.masked {
      .home__hero__outer {
        transform: translateY(15%);
      }

      .home__hero__inner {
        width: 76%;
        height: 182px;
        margin: 0 auto;
        will-change: height;
      }
    }

    &__inner {
      box-shadow: inset 0px 0px 8px rgba(0, 0, 16, 0.25);
      border-radius: 12px;
      width: 100%;
      height: var(--height);
      overflow: hidden;
      transition: all 1s ease;
      margin: 0 auto;
      position: relative;
    }

    &__butterflies {
      height: 182px;
      position: absolute;
      top: 0;
      width: 100%;
      width: 76%;
      left: 50%;
      transform: translateX(-50%) translateY(109%);
      z-index: z("1");
      pointer-events: none;
    }

    &__butterfly {
      position: absolute;

      &:first-of-type {
        right: toRem(287);
        top: -90px;

        .c-butterfly {
          transform: rotate(-15.57deg);
        }
      }

      &:nth-of-type(2) {
        right: -136px;
        bottom: -120px;
        .c-butterfly {
          transform: rotate(-23.17deg);
        }

        .wing__inner {
          animation-delay: 1s !important;
        }
      }

      &:last-of-type {
        left: -100px;
        bottom: -80px;

        .c-butterfly {
          transform: rotate(35deg);
        }

        .wing__inner {
          animation-delay: 0.5s !important;
        }
      }
    }

    &__image {
      height: var(--height);
      img {
        @extend %img;
      }
    }

    &__content {
      height: var(--height);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0 24px;
    }

    &__marquee {
      margin-top: 40px;
    }

    &__title {
      @extend %title-172-pixel;

      @include media(">tablet") {
        color: $white;
      }

      &:not(:first-of-type) {
        margin: 0 32px;

        @include media("<=tablet") {
          margin: 0 16px;
        }
      }
    }

    &__description {
      max-width: 560px;
      margin: 0 auto;
      margin-top: 160px;
      text-align: center;
      color: $white;
      @include multiParagraphs;
    }

    &__reel {
      background: $grey;
      border-radius: 8px;
      max-width: 664px;
      margin: 0 auto;
      margin-top: 120px;

      &__header {
        padding: 16px;
        @extend %body-14-bold;
        color: $dark-grey;
      }

      &__video {
        @include ratio(480, 640);

        &__con {
          padding: 12px;
          padding-top: 0;
        }
      }
    }

    &__fixed {
      position: absolute;
      top: 0;
      width: calc(100% - 24px);
      height: calc(100vh - 80px);
      pointer-events: none;
      display: flex;
      justify-content: center;
      // overflow: hidden;

      .home__hero {
        &__text {
          &__top {
            position: absolute;
            top: 9vh;
            margin-right: 22rem;
          }
        }

        &__bottom {
          position: absolute;
          bottom: 13vh;
          width: 76%;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          @media screen and (max-height: 780px) {
            bottom: 24px;
          }
        }

        &__credits {
          position: relative;

          svg {
            @extend %img;
          }

          &__text {
            display: flex;
          }

          &__video {
            position: absolute;
            width: 88px;
            height: 88px;
            bottom: 0;
            left: 41px;
            border-radius: 4px;
            @extend %ovh;

            @include media("<=1200px") {
              display: none;
            }

            video {
              @extend %img;
            }
          }
        }

        &__enter {
          width: 360px;
          height: 200px;
          background: $grey;
          margin-left: 40px;
          border-radius: 12px;
          flex-shrink: 0;
          pointer-events: auto;
          padding: 24px;
          display: flex;
          flex-direction: column;
          position: relative;
          overflow: hidden;

          &__progress {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $dark-stroke;
            transform-origin: left;
            transform: scaleX(0);
            pointer-events: none;
          }

          &__emoji {
            @extend %emoji-48;
            position: relative;
            z-index: z("1");
          }

          &__bottom {
            margin-top: auto;
            display: flex;
            width: 100%;
            justify-content: space-between;
            text-align: left;
            position: relative;
            z-index: z("1");
          }

          &__label {
            @extend %body-14-lh-1;
            margin-bottom: 8px;
            color: $dark-grey;
          }

          &__action {
            @extend %title-24;
          }
        }
      }
    }
  }

  .m-hero {
    overflow: hidden;
    margin-bottom: 64px;
    margin-top: 48px;

    @include media(">tablet") {
      display: none;
      margin-top: 0;
    }

    .home__hero {
      &__title {
        @extend %title-96-pixel;
      }

      &__reel {
        padding: 0;
        background: transparent;
        padding-top: 12px;
        margin: 0 20px;
        border-top: $border-dark;

        &__header {
          padding: 0;
          padding-left: 4px;
          margin-bottom: 16px;
        }

        &__video {
          border-radius: 4px;
          @extend %ovh;
          &__con {
            padding: 0;
          }
        }
      }
    }

    &__paragraph {
      @include multiParagraphs;
      margin: 0 24px;
      padding-top: 24px;
      margin-top: 32px;
      border-top: $border-dark;
      margin-bottom: 64px;
    }

    &__text {
      &__top {
        text-align: center;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: $border-dark;
      }

      &__bottom {
        text-align: center;
      }
    }

    &__marquee {
      margin-top: 0;
      margin-bottom: 16px;
    }

    &__reels {
      @include flex-gap(12px);
      display: flex;
      justify-content: center;
      padding-bottom: 32px;
      border-bottom: $border-dark;
      margin-bottom: 24px;

      &__video {
        max-width: 96px;
        width: 100%;
        @include ratio(1, 1);
        border-radius: 4px;
        @extend %ovh;
      }
    }

    &__alerts {
      background: $yellow;
      box-shadow: inset 0px 0px 8px rgba(0, 0, 16, 0.25);
      border-radius: 12px;
      padding-top: 24px;
      padding-bottom: 48px;
      margin-top: 48px;
      overflow: scroll;

      &__con {
        padding: 0 12px;
      }

      &__title {
        @extend %body-14-bold;
        margin-bottom: 32px;
        text-align: center;
        color: $medium-black;
      }

      &__cards {
        display: grid;
        grid-auto-flow: column;
        gap: 16px;
        // width: fit-content;
        overflow-y: auto;
        overscroll-behavior-x: contain;
        padding-left: 24px;
        padding-right: 24px;

        .c-dialog {
          flex-shrink: 0;
          width: 296px;
        }
      }
    }
  }

  &__entries {
    margin-bottom: 64px;
    &__inner {
      &__inner {
        @include media(">tablet") {
          padding-top: 120px;
          min-height: 7858px;
        }
      }
    }
  }

  &__stats {
    max-width: 1328px;
    margin-left: auto;
    margin-right: auto;
    --starting-margin: 27.5rem;
    overflow: hidden;
    margin-bottom: 120px;

    @include media("<=1200px") {
      --starting-margin: 13rem;
    }

    @include media("<=tablet") {
      margin-bottom: 64px;
    }

    &__intro {
      max-width: 264px;
      border-radius: 8px;
      margin-bottom: 32px;

      @include media(">tablet") {
        background: $grey;
        margin-left: var(--starting-margin);
        margin-bottom: 16px;
      }

      &__top {
        padding-left: 4px;
        margin-bottom: 16px;

        @include media(">tablet") {
          padding: 16px;
          margin-bottom: 0;
        }

        &__title {
          @extend %title-24;
          margin-bottom: 12px;
        }

        &__desc {
          color: $dark-grey;
          @extend %body-14;
        }
      }

      &__image {
        @include ratio(1, 1);
        border-radius: 4px;
        @extend %ovh;

        &__con {
          max-width: 240px;
          @include media(">tablet") {
            padding: 12px;
            max-width: unset;
          }
        }
      }
    }

    .c-stat {
      @include media(">tablet") {
        &:first-of-type {
          margin-left: calc(var(--starting-margin) + 48px);
          margin-bottom: 16px;
        }
        &:nth-of-type(2) {
          margin-left: calc(var(--starting-margin) + 264px);
          margin-bottom: 16px;
        }
        &:nth-of-type(3) {
          margin-left: calc(var(--starting-margin) + 264px);
          margin-bottom: 120px;
        }
        &:nth-of-type(4) {
          margin-left: calc(var(--starting-margin) + 264px);
          margin-bottom: 16px;
        }
        &:nth-of-type(5) {
          margin-left: calc(var(--starting-margin) + 368px);
          margin-bottom: 120px;
        }
        &:nth-of-type(6) {
          margin-bottom: 16px;
        }
        &:nth-of-type(6),
        &:nth-of-type(7) {
          margin-left: auto;
          margin-right: 27rem;
        }
      }

      @include media("<=tablet") {
        &:not(:last-of-type) {
          margin-bottom: 32px;
        }
      }
    }
  }

  &__collab {
    &__intro {
      background-color: $grey;
      border-radius: 8px;
      max-width: 344px;
      margin: 0 auto;
      margin-bottom: 120px;

      @include media("<=tablet") {
        margin-bottom: 64px;
        padding-top: 12px;
        border-top: $border-dark;
        max-width: unset;
        background: none;
      }

      &__top {
        margin-bottom: 16px;

        @include media(">tablet") {
          padding: 16px;
          margin-bottom: 0;
        }
      }

      &__title {
        @extend %title-24;
        margin-bottom: 12px;
      }

      &__desc {
        @extend %body-14;
        color: $dark-grey;
      }

      &__image {
        @include ratio(240, 320);
        position: relative;
        border-radius: 4px;
        @extend %ovh;

        &__con {
          padding-right: 16px;

          @include media(">tablet") {
            padding: 12px;
            padding-top: 0;
          }
        }
      }
    }
  }

  &__countries {
    &__card {
      background-color: $grey;
      border-radius: 8px;
      max-width: 344px;
      margin: 0 auto;
      margin-bottom: 120px;
      padding: 12px;

      @include media("<=tablet") {
        margin-bottom: 64px;
        padding-top: 12px;
        border-top: $border-dark;
        max-width: unset;
        background: none;
      }

      &__title {
        @extend %body-14-bold;
        color: $dark-grey;
        margin-bottom: 16px;
        padding: 0 4px;
      }

      &__images {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 4px;
        @extend %ovh;

        &__con {
          @include ratio(240, 320);

          @include media(">tablet") {
            padding-top: 0;
          }
        }
      }

      &__dots {
        position: absolute;
        bottom: 16px;
        right: 16px;
        z-index: z("2");
        display: flex;
        @include flex-gap(4px);

        .c-dot {
          width: 8px;
          height: 8px;
          background: $low-white;
          border-radius: 50%;

          &--active {
            background: $white;
          }
        }
      }

      .c-country {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        &__timearea {
          position: absolute;
          left: 16px;
          bottom: 16px;
          z-index: z("1");
        }

        &__time {
          @extend %title-24;
          color: $white;
        }

        &__name {
          @extend %body-14-bold;
          color: $white;
        }

        &__gradient {
          position: absolute;
          bottom: 0;
          height: 160px;
          width: 100%;
          z-index: z("1");
          background: linear-gradient(
            180deg,
            rgba(116, 116, 124, 0) 0%,
            rgba(0, 0, 16, 0.5) 100%
          );
        }
      }
    }
  }

  &__collaborators {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__con {
      margin-bottom: 120px;

      @include media("<=tablet") {
        margin-bottom: 64px;
      }
    }

    &__featured {
      display: flex;
      justify-content: center;
      width: 100%;
      @include flex-gap(9.6rem);

      @include media("<=tablet") {
        flex-direction: column;
        @include flex-gap(48px);
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 96px;

      @include media("<=tablet") {
        margin-bottom: 48px;
      }
    }

    .c-collabcard {
      &:not(:last-of-type) {
        margin-bottom: 96px;

        @include media("<=tablet") {
          margin-bottom: 48px;
        }
      }

      @include media(">tablet") {
        &:nth-child(2n) {
          margin-left: 200px;
        }

        &:nth-child(3n) {
          margin-right: 200px;
        }
      }
    }
  }

  &__credits {
    margin-bottom: 120px;

    @include media("<=tablet") {
      margin-bottom: 64px;
    }

    &__inner {
      margin-top: 48px;

      @include media(">tablet") {
        margin-top: 64px;
        text-align: center;
        max-width: 560px;
        margin: 0 auto;
      }
    }

    &__title {
      margin-bottom: 64px;

      @include media("<=tablet") {
        margin-bottom: 48px;
      }
    }

    &__label {
      @extend %captions-12;
      color: $dark-grey;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    &__desc {
      @extend %body-14;
      @include multiParagraphs;

      a {
        @extend %link;
      }
    }
  }

  &__final {
    margin-bottom: 120px;

    @include media("<=tablet") {
      margin-bottom: 64px;
    }

    &__title {
      @extend %title-172-pixel;
      margin-bottom: 48px;

      @include media(">tablet") {
        text-align: center;
        max-width: 761px;
        margin: 0 auto;
        margin-bottom: 64px;
      }
    }

    &__cards {
      display: flex;
      @include flex-gap(48px);
      flex-direction: column-reverse;

      @include media(">tablet") {
        @include flex-gap(24px);
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
      }
    }

    &__card {
      @include media(">tablet") {
        padding: 16px;
        padding-top: 32px;
        max-width: 516px;
        width: 100%;
        background: $grey;
        border-radius: 12px;
      }
      text-align: left;

      &__desc {
        @extend %body-14;
        color: $dark-grey;
      }

      &__title {
        @extend %title-24;
        margin-bottom: 8px;
      }

      .c-button {
        @include media(">tablet") {
          width: 100%;
          justify-content: space-between;
          border: $border-dark;
        }
      }

      &__top {
        display: flex;
        margin-bottom: 32px;
        justify-content: space-between;
        padding: 0 16px;

        @include media("<=tablet") {
          flex-direction: column-reverse;
          margin-bottom: 16px;
          padding: 0;
        }

        &__left {
          @include media(">tablet") {
            max-width: 264px;
          }
        }

        &__video {
          max-width: 120px;
          width: 100%;
          @include ratio(1, 1);
          border-radius: 4px;
          @extend %ovh;

          @include media("<=tablet") {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
