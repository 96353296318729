.c-stat {
  width: 100%;
  padding: 0 4px;
  padding-top: 12px;
  border-top: $border-dark;

  @include media(">tablet") {
    padding: 16px;
    border-radius: 8px;
    padding-left: 14px;
    padding-bottom: 0;
    background: $grey;
    border: none;
  }

  &--small {
    @include media(">tablet") {
      max-width: 216px;
    }
  }
  &--large {
    @include media(">tablet") {
      max-width: 320px;
    }
  }

  &__label {
    margin-left: 2px;
    @extend %body-14-bold;
    margin-bottom: 16px;
    color: $dark-grey;
  }

  &__body {
    display: flex;
    align-items: flex-end;

    @include media(">tablet") {
      justify-content: space-between;
    }
  }

  &__value {
    @extend %title-120;

    @include media(">tablet") {
      margin-bottom: -1px;
    }
  }
}
