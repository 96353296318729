.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z("overlay");
  pointer-events: none;
  opacity: 0;
  padding: 20px;
  display: grid;
  place-items: center;

  &__backdrop {
    background: rgba(0, 0, 16, 0.65);
    backdrop-filter: blur(8px);
    @extend %element-cover;
    opacity: 0;
    pointer-events: none;
  }

  &__content {
    z-index: z("modal");
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    &__body {
      background: $white;
      box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625),
        2px 16px 24px rgba(0, 0, 16, 0.0625);
      border-radius: 8px;
      overscroll-behavior: contain;

      @include media("<=tablet") {
        max-height: 480px;
        overflow: scroll;
      }
    }

    &__footer {
      margin-top: 16px;
      text-align: center;

      .c-button {
        border-radius: 48px;
      }

      @include media(">tablet") {
        display: none;
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      padding-bottom: 20px;
      padding-left: 32px;
      border-bottom: $border-dark;

      @include media("<=tablet") {
        padding: 16px;
        .c-button {
          display: none;
        }
      }
    }

    &__bottom {
      padding: 32px;
      @extend %body-14;
      color: $dark-grey;

      @include media("<=tablet") {
        padding: 16px;
      }
    }
  }

  &__title {
    @extend %title-64-pixel;
  }

  &.active {
    pointer-events: auto;
    opacity: 1;

    .c-modal__backdrop {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
