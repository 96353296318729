.feed {
  @extend %page;
  height: 100vh;

  &--active {
    @extend %page--active;
  }

  @include pagePadding;

  &__inner {
    position: relative;
    margin-top: 82px;

    @include media(">tablet") {
      display: flex;
      margin-top: 0;
    }

    h2 {
      position: sticky;
      top: 0;
    }
  }

  &__content {
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 24px 0;

    @include media(">tablet") {
      padding: 0;
    }

    .c-header__scroll {
      color: $white;
    }

    flex-direction: column;

    .c-section {
      .c-header.desktop {
        @include media("<=tablet") {
          display: none;
          visibility: hidden;
        }
      }

      .c-header.mobile {
        @include media(">tablet") {
          display: none;
          visibility: hidden;
        }
      }

      &-welcome-mobile {
        @include media(">tablet") {
          display: none;
          visibility: hidden;
        }

        @extend %body-14;
        color: $medium-black;
        text-align: center;
        margin: 48px auto 0;
        padding: 0 48px;
      }
    }

    footer {
      margin-top: 64px;

      @include media(">tablet") {
        margin-top: auto;
      }

      .desktop {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;

        @include media("<=tablet") {
          display: none;
          visibility: hidden;
        }

        .year-in-review {
          color: white;
          border-color: $white-strokes;
        }

        .share-page {
          color: white;
          border-color: $white-strokes;
          width: max-content;
          height: fit-content;
        }
      }

      .mobile {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media(">tablet") {
          display: none;
          visibility: hidden;
        }

        .share-prompt {
          @extend %body-14;
          color: $medium-black;
          margin-bottom: 24px;
          text-align: center;
        }

        .share-page {
          margin-bottom: 64px;
        }
      }

      .curated-by-wrapper {
        @include media(">tablet") {
          .c-button {
            width: 100%;
            justify-content: space-between;
          }
        }

        @include media("<=tablet") {
          width: 100%;
          padding: 0 52px;
        }
      }

      .curated-by {
        padding: 16px;
        @extend %captions-12;
        text-align: center;
        border: 1px solid $white-strokes;
        border-radius: 12px;
        margin-bottom: 4px;
        color: $medium-white;

        @include media("<=tablet") {
          color: $medium-black;
          border-color: $dark-stroke;
          margin-bottom: 64px;
          width: 100%;
        }

        &-icon {
          margin: 8px 0;
          width: 128px;
          height: 48px;
        }
      }

      .back-to-top {
        border-top: 1px solid $dark-stroke;
        padding: 24px 0;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
