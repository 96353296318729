.mobile-playlists{
  border-bottom: 1px solid $dark-stroke;

  @include media(">tablet") {
    display: none;
    visibility: hidden;
  }
}

//Had to move it out of the style above so that the .is-expanded & can work
.mobile-playlist{
  padding: 24px 16px 22px 20px;
  width: 100%;
  border-top: 1px solid $dark-stroke;
  scroll-margin-top: 100px;
  scroll-snap-margin-top: 100px;

  &.is-expanded{
    background: $grey;
    border: none;
  }

  &-main-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title{
    @extend %body-16-bold;
    margin-bottom: 4px;
    color: $black;

    &--large-margin{
      margin-bottom: 8px;
    }
  }

  &-owner{
    @extend %body-14-lh-1;
    color: $medium-black;
    margin-bottom: 12px;
  }

  &-footer{
    display: flex;
    align-items: center;
    color: $medium-black;

    &-tag {
      padding: 8px;
      @extend %body-14-lh-1;
      background-color: $grey;
      border-radius: 4px;
      color: $medium-black;

      &:first-of-type{
        margin-right: 4px;
      }

      .is-expanded &{
        background: $low-white;
      }
    }

    .c-button{
      margin-left: 8px;

      .is-expanded &{
        display: none;
      }
    }
  }

  &-memoji{
    width: 88px;
    height: 88px;
  }

  &-image{
    width: 64px;
    height: 64px;
  }

  &-embed{
    display: none;
    margin-top: 20px;

    .is-expanded &{
      display: block;
    }
  }

  &-controls{
    width: 100%;
    @include flex-gap(0, 12px);
    margin-top: 16px;
    display: none;

    .c-button{
      box-shadow: none;
    }

    [data-share-playlist]{
      flex-shrink: 0;
    }

    &-about{
      flex-grow: 1;
      justify-content: space-between;
    }

    .is-expanded &{
      display: flex;
    }
  }

  &-collapse{
    width: 100%;
    justify-content: space-between;
    color: $black;
    display: none;
    margin-top: 12px;

    &--without-margin{
      margin-top: 0;
    }

    .is-expanded &{
      display: flex;
    }
  }
}