.c-header {
  width: 100%;
  color: $dark-grey;
  position: relative;

  @include media(">tablet") {
    padding-top: 20px;
    padding-bottom: 24px;
  }

  &__inner {
    height: 40px;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: flex-end;

    @include media("<=tablet") {
      flex-direction: column-reverse;
      align-items: center;
      height: unset;
      margin: 0;
    }
  }

  &__title {
    padding: 9px 12px;
    border: $border-dark;
    border-radius: 8px;
    @extend %title-14;
    color: $dark-grey;
    display: inline-block;

    &--light{
      color: $white;
      border-color: $white-strokes;
    }
  }

  &__scroll {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 11px;
    @extend %title-14;

    @include media("<=tablet") {
      display: none;
    }
  }

  &__right {
    display: flex;

    @include media("<=tablet") {
      padding-bottom: 24px;
      margin-bottom: 24px;
      width: 100%;
      border-bottom: $border-dark;
      justify-content: center;
    }
  }
}
