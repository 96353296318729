.notfound {
  @extend %page;

  &--active {
    @extend %page--active;
  }

  @include pagePadding;

  &__inner {
    position: relative;
    margin-top: 82px;

    @include media(">tablet") {
      display: flex;
      margin-top: 0;
    }
  }

  &__butterflies {
  }

  &__content {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    footer {
      margin-top: auto;
    }
  }

  &__body {
    margin-top: 116px;
    margin-bottom: 120px;

    @include media("<=tablet") {
      margin-top: 48px;
    }

    &__inner {
      max-width: 240px;
      margin: 0 auto;
      text-align: center;
    }
  }

  &__emojis {
    @extend %emoji-24;
    margin-bottom: 16px;
  }

  &__desc {
    @extend %body-14;
    margin-bottom: 24px;
  }

  &__title {
    @extend %title-172-pixel;
    margin-bottom: 16px;
  }
}
