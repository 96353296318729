.mobile-playlist-info{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: z("mobile-playlist-info");
  padding: 20px;
  display: none;

  @include media(">tablet") {
    display: none;
    visibility: hidden;
  }


  &.is-visible{
    display: flex;
  }

  &__overlay{
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: $medium-black;
    backdrop-filter: blur(8px);
  }

  &-popup{
    background: $white;
    border: 1px solid $dark-stroke;
    box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625), 2px 16px 24px rgba(0, 0, 16, 0.0625);
    border-radius: 12px;
    margin-bottom: 16px;
    position: relative;
    overflow: auto;

    &__header{
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      position: sticky;
      top: 0;
      background-color: white;

      &-text{
        @extend %captions-12;
        color: $dark-grey;
        text-transform: uppercase;
      }

      &-buttons{
        display: flex;
      }

      &-button{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $dark-stroke;
        border: none;
        cursor: pointer;

        & + &{
          margin-left: 4px;
        }
      }
    }

    &__body {
      padding: 12px 16px 20px;
      border-top: 1px solid $dark-stroke;

      &-text{
        @extend %body-14;
        font-weight: 400;
        color: $dark-grey;

        &-title{
          color: $black;
          text-transform: uppercase;
          @extend %captions-12;

          &::after{
            content: " "
          }
        }
      }

      &-creative{
        padding: 16px;
        border-radius: 12px;
        border: 1px solid $dark-stroke;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0;

        &-name{
          @extend %body-14-bold;
          color: $black;
          margin-bottom: 4px;
        }

        &-description{
          @extend %body-14;
          color: $medium-black;
        }

        &-external-link{
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: 1px solid $dark-stroke;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: flex-start;
          color: $medium-black;
          margin-left: auto;
        }
      }

      //work-window
      &-media-wrapper{
        position: relative;
        color: $white;
        cursor: pointer;
        margin-bottom: 16px;

        &-control-icon{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
          width: 160px;
          height: 160px;
        }

        &.is-active[data-play-state="paused"]:hover .play-icon{
          display: block;
        }

        &.is-active[data-play-state="playing"]:hover .pause-icon{
          display: block;
        }
      }

      &-media{
        border-radius: 8px;
        display: none;
        margin: 0 auto;
        max-width: 100%;

        .is-active &{
          display: block;
        }
      }
    }
  }

  &-close{
    position: relative;
    border-radius: 48px;
    box-shadow: none;
  }
}