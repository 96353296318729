$white: #ffffff;
$black: #000010;
$medium-black: rgba(0, 0, 16, 0.65);
$low-black: rgba(0, 0, 16, 0.35);
$low-white: rgba(255, 255, 255, 0.35);
$medium-white: rgba(255, 255, 255, 0.75);
$white-strokes: rgba(255, 255, 255, 0.25);
$dark-stroke: rgba(0, 0, 0, 0.05);
$grey: #f1f1f2;
$dark-grey: #74747c;
$blue: #0000ff;
$red: #ff0000;
$green: #00ff00;
$yellow: #ffff00;
$purple: #ff00ff;

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-custom: cubic-bezier(0.33, 0, 0.67, 1);
$ease-menu: cubic-bezier(0.63, 0, 0.4, 0.99);
$ease-hero-marquee: cubic-bezier(0.24, 0.6, 0.25, 1);

// fonts
$font-sans: "Suisse", sans-serif;
$font-serif: "Fraunces", serif;
$font-bitmap: "PP Neue", "Adjusted Arial Fallback", sans-serif;

$border-dark: 1px solid $dark-stroke;

$z-indexes: (
  "noscript",
  "preloader",
  "mobile-playlist-info",
  "transition",
  "modal",
  "overlay",
  "nav",
  "canvas",
  "menu",
  "content",
  "smoke",
  "4",
  "3",
  "2",
  "1"
);

:root {
  --topColor: #f1f1f2;
  --bottomColor: #f1f1f2;
}
