.c-monthcard {
  background: $white;
  box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625),
    2px 16px 24px rgba(0, 0, 16, 0.0625);
  border-radius: 12px;
  border: $border-dark;
  max-width: 512px;
  width: 100%;
  padding: 16px;
  padding-bottom: 12px;
  margin-bottom: 64px;

  @include cursor {
    &:hover {
      .c-monthcard__month {
        animation: blinkerHover 1s step-start;
      }
    }
  }

  @include media("<=1200px") {
    max-width: 450px;
  }

  @include media("<=tablet") {
    padding: 12px;
    width: 304px;
    margin-bottom: 0;
  }

  &__image {
    @include ratio(1, 1);
    margin-bottom: 12px;
    border-radius: 4px;
    @extend %ovh;

    @include media("<=tablet") {
      margin-bottom: 8px;
    }
  }

  &__gradient {
    border-radius: 4px;
    height: 16px;
    width: 100%;
    margin-bottom: 16px;

    @include media("<=tablet") {
      margin-bottom: 8px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__month {
    @extend %title-96-pixel;

    @include media("<=tablet") {
      display: none;
    }
  }

  &__right {
    @include media("<=tablet") {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__index {
    @extend %body-16-serif;
    margin-top: 4px;
    font-weight: 600;

    @include media(">tablet") {
      margin-bottom: 12px;
      text-align: right;
      margin-top: 0;
    }
  }

  &__emoji {
    @extend %emoji-24;
  }
}

.c-calendarcard {
  background: $white;
  box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625),
    2px 16px 24px rgba(0, 0, 16, 0.0625);
  border-radius: 12px;
  border: $border-dark;
  max-width: 512px;
  width: 100%;
  padding: 16px;
  color: $white;
  margin-bottom: 64px;

  @include cursor {
    &:hover {
      .c-calendarcard__month {
        animation: blinkerHover 1s step-start;
      }
    }
  }

  @include media("<=1200px") {
    max-width: 450px;
  }

  @include media("<=tablet") {
    padding: 12px;
    width: 304px;
    margin-bottom: 0;
  }

  &__inner {
    @include ratio(587, 480);
    box-shadow: inset 0px 0px 8px rgba(0, 0, 16, 0.25);
    @extend %ovh;
  }

  &__emoji {
    @extend %emoji-24;
  }

  &__index {
    @extend %body-16-serif;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__month {
    @extend %title-96-pixel;

    @include media("<=tablet") {
      display: none;
    }
  }

  &__body {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 32px;

    @include media("<=tablet") {
      padding: 24px;
    }
  }
}

.c-collabcard {
  max-width: 384px;
  width: 100%;
  --progress: 0;

  @include media("<=tablet") {
    max-width: unset;
    border-top: $border-dark;
    padding-top: 12px;
  }

  @include media(">tablet") {
    &__top,
    &__bottom {
      background: $grey;
      border-radius: 8px;
    }
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $dark-stroke;
    transform-origin: left;
    transform: scaleX(var(--progress));
    pointer-events: none;
    transition: transform 0.2s linear;
    @include media("<=tablet") {
      display: none;
    }
  }

  &__top {
    position: relative;
    overflow: hidden;
    @include media(">tablet") {
      margin-bottom: 8px;
    }
  }

  &__image {
    width: 100%;
    max-width: 280px;
    @include ratio(1, 1);
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      @extend %img;
    }

    &__con {
      margin: 12px;
      margin-top: 0;
      @include ratio(1, 1);

      @include media("<=tablet") {
        max-width: 240px;
        margin: 32px auto;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;

    @include media(">tablet") {
      padding: 16px;
    }

    &__left {
      @include media("<=tablet") {
        text-align: center;
        width: 100%;
      }
    }

    &__right {
      @extend %desktop-tab;
    }
  }

  &__alias {
    @extend %title-24;
    margin-bottom: 8px;
  }

  &__name {
    @extend %body-14-bold;
    color: $dark-grey;
  }

  &__bottom {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    position: relative;
    overflow: hidden;

    @include media("<=tablet") {
      padding: 0 8px;
      border-top: $border-dark;
    }

    &__right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &__folio {
      padding-left: 16px;
      border-left: $border-dark;
      height: 100%;
      display: flex;
      align-items: center;

      @include media(">tablet") {
        display: none;
      }
    }
  }

  &__play {
    margin-right: 16px;
    flex-shrink: 0;
  }

  &__track {
    @extend %body-16-lh-1;

    &__current {
      max-width: 300px;
      overflow: hidden;

      @include media("<=tablet") {
        max-width: 240px;
      }
    }

    &__label {
      @extend %body-14-bold;
      margin-bottom: 8px;
      color: $dark-grey;
    }
  }

  &.playing {
    .c-pause {
      display: block;
    }

    .c-play {
      display: none;
    }
  }

  .c-pause {
    display: none;
  }

  &:not(.active) {
    .c-collabcard__progress {
      opacity: 0;
    }
  }
}
