.m-audio {
  max-width: 296px;
  width: 100%;
  position: fixed;
  top: 222px;
  right: 62px;
  z-index: z("2");
  --progress: 0;

  @include media("<=tablet") {
    display: none;
  }

  &__card {
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.0625),
      2px 16px 24px rgba(0, 0, 0, 0.0625);
    border-radius: 12px;
    margin-bottom: 4px;

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 16px;
      color: $dark-grey;
      transition: color 0.2s;
      width: 100%;

      &:hover {
        color: $black;
      }

      &__label {
        @extend %body-14-lh-1;
      }
    }

    &__index {
      display: flex;
      align-items: center;

      &__number {
        @extend %body-14-lh-1;
        margin-right: 4px;
      }

      &__icon {
        width: 16px;
        height: 16px;
        @extend %svg-full;
        transition: transform 0.2s ease-out;
      }
    }
  }

  &__header {
    @extend .c-dialog__header;

    &__dots {
      @extend .c-dialog__header__dots;
    }

    &__dot {
      @extend .c-dialog__header__dot;
    }
  }

  &__dropdown {
    overscroll-behavior: contain;
    overflow: scroll;
    background: rgba(241, 241, 242, 0.75);
    border-radius: 12px;

    &.desktop {
      max-height: 0;
      backdrop-filter: blur(32px);
      -webkit-backdrop-filter: blur(32px);
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.open {
    .m-audio {
      &__dropdown {
        border: 1px solid rgba(255, 255, 255, 0.25);
        max-height: 320px;
      }
      &__card__index__icon {
        transform: rotate(180deg);
      }
    }
  }

  &__deco {
    margin-top: 8px;
    padding-left: 8px;
    padding-right: 8px;

    &__inner {
      background: $grey;
      box-shadow: inset 0px 0px 8px rgba(0, 0, 16, 0.125);
      border-radius: 4px;
      height: 48px;
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;
    }

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: $dark-stroke;
      width: 100%;
      transform-origin: left;
      transform: scaleX(var(--progress));
      transition: transform 0.2s linear;
    }

    &__text {
      @extend %title-32-pixel;
      color: rgba(0, 0, 16, 0.35);
      white-space: nowrap;
      margin-top: 2px;

      &:not(:first-of-type) {
        padding: 0 12px;
      }
    }
  }

  &__current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: $border-dark;

    .c-button {
      flex-shrink: 0;
    }
  }

  &__song {
    white-space: nowrap;

    &__wrapper {
      width: 100%;
      overflow: hidden;
      max-width: 224px;
      position: relative;
      padding: 0 4px;

      &__con {
        display: grid;

        > div {
          grid-area: 1/1;
        }
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: linear-gradient(
          270deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        transform: rotate(180deg);
        z-index: z("1");
      }

      &::after {
        left: auto;
        right: 0;
        transform: rotate(0deg);
      }
    }
  }

  .c-pause {
    display: none;
  }

  &.playing {
    .c-play {
      display: none;
    }

    .c-pause {
      display: block;
    }
  }

  &:not(.active) {
    .m-audio__deco__progress {
      opacity: 0;
    }
  }

  &__track {
    display: flex;
    text-align: left;
    @extend %body-14-lh-1;
    width: 100%;
    color: $medium-black;
    padding: 8px;
    border-radius: 4px;
    transition: background 0.2s ease-out;

    @include cursor {
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    &__left {
      width: 24px;
      margin-right: 8px;
      flex-shrink: 0;

      &__rainbow {
        display: none;
      }
    }

    &__title {
      font-weight: 700;
      margin-bottom: 6px;
    }

    &__artist {
    }

    &__wrapper {
      padding: 8px;

      &:not(:first-of-type) {
        border-top: $border-dark;
      }

      &.active,
      &.first {
        &.m-audio__track,
        .m-audio__track {
          background: $white;

          &__left {
            &__rainbow {
              display: block;
            }

            &__index {
              display: none;
            }
          }
        }
      }
    }

    &:not([data-preview-url-inner]) {
      color: $low-black;
      cursor: not-allowed;

      &:hover {
        background: transparent;
      }
    }
  }
}

.mobile-audio {
  width: 100%;
  z-index: z("2");
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  --progress: 0;

  @include media(">=tablet") {
    display: none;
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    transition: opacity 0.35s ease-in-out;
    pointer-events: none;
  }

  &__header {
    height: 65px;
    background: $blue;
    box-shadow: 1px 4px 8px rgba(0, 0, 16, 0.0625),
      2px 16px 24px rgba(0, 0, 16, 0.0625);
    border: 1px solid rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    color: $white;
    padding-right: 16px;
    position: relative;

    &__circle {
      position: absolute;
      width: 32px;
      height: 32px;
      transform: rotate(266deg);

      circle {
        stroke-dasharray: 98px;
        // use progress to set stroke-dashoffset
        stroke-dashoffset: calc(98px - 98px * var(--progress));
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 64px;
      width: 100%;
      flex-shrink: 0;
      border-right: 1px solid $low-white;
      height: 100%;
      margin-right: 16px;
      position: relative;

      .c-button__icon {
        width: 20px;
        height: 20px;
      }
    }

    &__right {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__label {
      @extend %captions-12;
      margin-bottom: 6px;
      color: $medium-white;
    }

    &__icon {
      width: 32px;
      height: 32px;
      background: $low-white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: inline-block;
        transition: transform 0.2s ease-out;
        transform-origin: center;
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
        @extend %svg-full;
      }
    }
  }

  &__song {
    @extend %body-14-lh-1;
    white-space: nowrap;

    &:not(:first-of-type) {
      padding: 0 12px;
    }

    &__wrapper {
      width: 100%;
      overflow: hidden;
      max-width: 224px;
      position: relative;

      &__con {
        display: grid;

        > div {
          grid-area: 1/1;
        }
      }

      // &::before,
      // &::after {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 4px;
      //   height: 100%;
      //   background: linear-gradient(
      //     270deg,
      //     #ffffff 0%,
      //     rgba(255, 255, 255, 0) 100%
      //   );
      //   transform: rotate(180deg);
      //   z-index: z("1");
      // }

      // &::after {
      //   left: auto;
      //   right: 0;
      //   transform: rotate(0deg);
      // }
    }
  }

  &__content {
    width: 100%;
    z-index: z("3");
    transition: none;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% - 66px));
    transition: transform 350ms ease-out 0s;
    border-top: 1px solid $low-black;
    pointer-events: auto;
  }

  .m-audio__dropdown {
    background: $grey;
    max-height: 360px;
  }

  &.open {
    pointer-events: auto;

    .mobile-audio {
      &__backdrop {
        opacity: 1;
        pointer-events: auto;
      }

      &__content {
        transform: translateY(0);
      }

      &__header {
        &__icon {
          span {
            transform: rotate(0deg);
          }
        }
      }
    }
  }

  .c-pause {
    display: none;
  }

  &.playing {
    .c-play {
      display: none;
    }

    .c-pause {
      display: block;
    }
  }
}

.d-audio {
  --progress: 0;
  width: 100%;

  &__top {
    display: flex;
    justify-content: space-between;
    background: $grey;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 4px;
    position: relative;
    overflow: hidden;

    &__left {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__right {
      flex-shrink: 0;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    height: 48px;
    padding-left: 14px;
    padding-right: 16px;
    background: $grey;
    width: 100%;
  }

  &__play {
    width: 20px;
    height: 20px;
    color: $black;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__duration {
    @extend %body-14-bold;
  }

  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $dark-stroke;
    transform-origin: left;
    transform: scaleX(var(--progress));
    pointer-events: none;
    transition: transform 0.2s linear;
  }

  &__icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 12px;

    span {
      @extend %emoji-24;
      width: 24px;
      height: 24px;
    }
  }

  &__song {
    width: 100%;
    &__title {
      @extend %body-16-bold;
      margin-bottom: 4px;
      width: calc(100% - 24px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__artist {
      color: $dark-grey;
      @extend %body-14;
    }
  }

  &.playing {
    .c-pause {
      display: block;
    }

    .c-play {
      display: none;
    }
  }

  .c-pause {
    display: none;
  }

  &:not(.active) {
    .d-audio__progress {
      opacity: 0;
    }
  }
}
