.months {
  @extend %page;

  &--active {
    @extend %page--active;
  }
}

.month {
  @extend %page;
  @include pagePadding;

  &--active {
    @extend %page--active;
  }

  &__inner {
    position: relative;
    margin-top: 82px;

    @include media(">tablet") {
      display: flex;
      margin-top: 0;
    }
  }

  &__content {
    width: 100%;
    flex-direction: column;
    display: flex;

    footer {
      margin-top: auto;
    }
  }

  &__hero {
    &__inner {
      margin-top: 180px;
      margin-bottom: 240px;
      width: 100%;
      display: flex;
      justify-content: center;

      @include media("<=tablet") {
        margin-top: 48px;
        margin-bottom: 64px;
      }
    }

    &__title {
      @extend %title-160-serif;
      filter: blur(2px);

      @include media("<=tablet") {
        filter: blur(1px);
      }
    }
  }

  &__journal {
    &__inner {
      @include media(">tablet") {
        min-height: var(--height);
      }
    }

    .c-section__inner {
      max-width: 1328px;
      margin-left: auto;
      margin-right: auto;
    }

    &__images {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      @extend %ovh;
      @extend %element-cover;
    }

    &__dots {
      position: absolute;
      left: 50%;
      bottom: 16px;
      transform: translateX(-50%);
      z-index: z("2");
      display: flex;
      @include flex-gap(4px);

      .c-dot {
        width: 8px;
        height: 8px;
        background: $low-white;
        border-radius: 50%;

        &--active {
          background: $white;
        }
      }
    }

    &__media {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      &__gradient {
        position: absolute;
        bottom: 0;
        height: 72px;
        width: 100%;
        z-index: z("1");
        background: linear-gradient(
          180deg,
          rgba(116, 116, 124, 0) 0%,
          rgba(116, 116, 124, 0.5) 100%
        );
      }
    }
  }

  &__journal__card {
    width: 100%;
    padding: 12px;

    @include media("<=1050px") {
      padding: 0;
      padding-top: 12px;
      border-top: $border-dark;

      &:not(:last-of-type) {
        margin-bottom: 48px;
      }
    }

    &--square,
    &--square-carousel {
      .month__journal__images__con {
        @include ratio(1, 1);
      }
    }

    &--rectangle,
    &--rectangle-carousel {
      .month__journal__images__con {
        @include ratio(360, 480);
      }
    }

    @include media(">1050px") {
      top: var(--top-spacing);
      position: absolute;
      background: $grey;
      border-radius: 8px;

      &--left {
        left: var(--horizontal-spacing);
      }

      &--right {
        right: var(--horizontal-spacing);
      }

      &--center {
        left: 50%;
        transform: translateX(-50%);
      }

      &--square,
      &--square-carousel {
        max-width: 384px;
      }

      &--rectangle,
      &--rectangle-carousel {
        max-width: 504px;
      }
    }
    @include media("<=1250px") {
      &--left {
        left: 24px;
      }

      &--right {
        right: 24px;
      }
    }

    &__top {
      padding: 0 4px;
      margin-bottom: 16px;
    }

    &__label {
      @extend %body-14-bold;
      margin-bottom: 12px;
      color: $dark-grey;
    }

    &__title {
      @extend %title-24;
      margin-bottom: 12px;
    }

    &__desc {
      @extend %body-14;
      @include multiParagraphs;
      color: $dark-grey;
    }
  }

  &__credits {
    margin-bottom: 120px;

    @include media("<=tablet") {
      margin-bottom: 48px;
    }

    &__inner {
      margin-top: 48px;

      @include media(">tablet") {
        margin: 0 auto;
        margin-top: 64px;
        text-align: center;
        max-width: 560px;
      }
    }

    &__label {
      @extend %captions-12;
      color: $dark-grey;
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    &__desc {
      @extend %body-14;
      @include multiParagraphs;
    }
  }

  &__lyrics {
    margin-bottom: 240px;

    @include media("<=tablet") {
      margin-bottom: 64px;
    }

    &__inner {
      max-width: 1184px;
      margin-left: auto;
      margin-right: auto;

      @include media("<=tablet") {
        margin: 0;
        display: flex;
        flex-direction: column-reverse;
        border-top: $border-dark;
        padding-top: 48px;
        @include flex-gap(16px);
      }
    }

    &__title {
      @extend %title-172-pixel;
    }

    &__card {
      @include media(">tablet") {
        float: left;
        margin-right: 24px;
        margin-top: 7px;
      }
    }
  }
}
